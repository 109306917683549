<template>
  <span>
    <new-cross-account-task-dialog v-if="isCrossAccountMode" ref="newCrossAccountTaskDialogRef" />
    <new-task-dialog
      v-if="!apiChanging" ref="newTaskDialogRef"
      :api-key="apiKey"
      :is-staff-account="isStaffAccountMode"
    />
    <v-btn
      color="black" class="add-task"
      dark
      small
      fab
      @click="addTask"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </span>
</template>
<script>
import NewTaskDialog from '@/components/new_task_dialog/NewTaskDialog.vue'
import NewCrossAccountTaskDialog from '@/components/new_task_dialog/cross_account_task/NewCrossAccountTaskDialog.vue'

import { ref, watch, nextTick, computed } from 'vue'

const taskMode = {
  hq: 'hq',
  staff: 'staff',
  account: 'account'
}

export default {
  components: {
    'new-cross-account-task-dialog': NewCrossAccountTaskDialog,
    'new-task-dialog': NewTaskDialog
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    taskMode: {
      type: String,
      required: true,
      validator: (value) => Object.values(taskMode).includes(value)
    }
  },
  setup (props) {
    const newCrossAccountTaskDialogRef = ref(null)
    const newTaskDialogRef = ref(null)
    const apiChanging = ref(false)

    // Methods
    const addTask = () => {
      if (props.taskMode === taskMode.hq) {
        newCrossAccountTaskDialogRef.value.openDialog()
      } else {
        newTaskDialogRef.value.openDialog()
      }
    }

    // Computed
    const isCrossAccountMode = computed(() => props.taskMode === taskMode.hq)
    const isStaffAccountMode = computed(() => props.taskMode === taskMode.staff)

    watch(() => props.apiKey, () => {
      if (isCrossAccountMode.value === true) return
      apiChanging.value = true
      nextTick(() => (apiChanging.value = false))
    })

    return {
      newCrossAccountTaskDialogRef,
      newTaskDialogRef,
      apiChanging,
      isCrossAccountMode,
      isStaffAccountMode,
      addTask
    }
  }
}
</script>
<style lang="scss">
.add-task {
  position: fixed;
  z-index: 4;
  bottom: 86px;
  right: 10px;

  @media only screen and (max-width: 600px) {
    bottom: 80px;
  }
}

.rtl {
  .add-task {
    right: unset;
    left: 10px;
  }
}
</style>
