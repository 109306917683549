<template>
  <v-dialog
    v-model="dialog" persistent :fullscreen="$vuetify.breakpoint.xsOnly"
    width="540"
    content-class="rounded-xl"
    scrollable
  >
    <task-subtasks-list
      v-if="apiKey"
      ref="taskSubTaskListDialogRef" :api-key="apiKey"
      :allow-change-item-order="isCreateMode"
    />
    <v-card
      class="bg-color16 new-cross-account-task-dialog"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="white px-1">
        <v-row align="center" justify="start">
          <v-col cols="2" class="texts-start">
            <v-btn
              :disabled="isLoading"
              icon
              color="black"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center color5 t-600-18">
            {{ dialogTitle }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="scrollable px-2">
        <v-form ref="taskFormRef">
          <v-row
            align="start" justify="center" class="overflow-x-hidden"
          >
            <v-col cols="12" class="mt-4 pb-0">
              <v-textarea
                v-model="newOperation.description"
                background-color="white"
                class="description-field"
                outlined
                auto-grow
                rows="4"
                counter="240"
                :label="$t('schedule_new_op_dialog.description')"
              />
            </v-col>
            <v-col v-if="dialogMode !== null" cols="12">
              <new-cross-account-task-job-search
                ref="jobSelectRef"
                v-model="accountJobs"
                :api-key="apiKey"
                :show-add-job-group="showAddNewJob"
                :disabled="isLoading"
                :operation-id="operationId"
              />
            </v-col>
            <v-col cols="12">
              <task-frequency-field
                :frequency-model.sync="frequencyModel"
                :frequency-exect-date.sync="frequencyExectDate"
                :frequency-every-year.sync="frequencyYearDate"
                :end-date.sync="endDate"
                :disabled="!isJobSelected"
                :job-active-days="workingDays"
                single-every-year-date
                single-weekly-working-day
                @update-working-days="updateWorkingDays"
              />
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-btn
                text color="#0040FF" class="px-0"
                @click="toggleMoreDetails"
              >
                <v-icon>{{ moreDetailsOpen ? 'expand_less' : 'expand_more' }}</v-icon>
                {{ $t('show_more_details') }}
              </v-btn>
              <v-expand-transition>
                <v-row
                  v-show="moreDetailsOpen" align="start" justify="center"
                  no-gutters
                >
                  <v-col cols="12" class="mt-2">
                    <task-type-field v-model="taskType" @change="onChangeTaskType" />
                  </v-col>
                  <v-expand-transition>
                    <v-col v-if="showCheckList" cols="12" class="mt-3">
                      <task-subtasks-field
                        :subtasks-count="subtasksCount"
                        @click="openSubTaskDialog"
                      />
                    </v-col>
                  </v-expand-transition>
                  <v-col cols="12" class="mt-4">
                    <task-shift-part-field v-model="shiftPart" :disabled="shiftPartDisabled" />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <time-picker-dialog
                      v-model="startTime"
                      background-color="white"
                      outlined
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <task-color-select
                      v-model="newOperation.color"
                      @click:clear="clearColor"
                    />
                  </v-col>
                  <v-col v-if="isCreateMode" cols="12" class="mt-4">
                    <task-notification-field
                      :task-un-complete-disabled="!operationHasStartTime"
                      @change="taskNotificationChange"
                    />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <task-report-tag-field v-model="reportTag" />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <span class="t-700-16">{{ $t('schedule_new_op_dialog.guildance_images') }}</span>
                    &nbsp;<span lass="t-400-16">{{ $t('schedule_new_op_dialog.max_photo') }}</span>
                  </v-col>
                  <v-col cols="12" class="mt-2">
                    <task-guidence-images
                      ref="taskGuidenceImagesRef"
                      record-type="CrossAccountOperation"
                    />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <file-uploader
                      ref="taskFileRef"
                      class="file-input color5 t-600-18"
                      underline
                      color="transparent"
                      record-type="CrossAccountOperation"
                    />
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :loading="isLoading" :disabled="saveDisabled"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import NewCrossAccountTaskJobSearch from '@/components/new_task_dialog/cross_account_task/NewCrossAccountTaskJobSearch.vue'

import FileUploader from '@/components/shared/FileUploader.vue'
import TimePickerDialog from '@/components/shared/TimePickerDialog.vue'
import TaskFrequencyField from '@/components/new_task_dialog/task_frequency/TaskFrequencyField.vue'
import TaskTypeField from '@/components/new_task_dialog/TaskTypeField.vue'
import TaskSubtasksField from '@/components/new_task_dialog/TaskSubtasksField.vue'
import TaskSubtasksList from '@/components/new_task_dialog/task_subtasks_list/TaskSubtasksList.vue'
import TaskShiftPartField from '@/components/new_task_dialog/TaskShiftPartField.vue'
import TaskColorSelect from '@/components/new_task_dialog/TaskColorSelect.vue'
import TaskNotificationField from '@/components/new_task_dialog/TaskNotificationField.vue'
import TaskReportTagField from '@/components/new_task_dialog/TaskReportTagField.vue'
import TaskGuidenceImages from '@/components/new_task_dialog/TaskGuidenceImages.vue'

import { shiftPartsForSelect as OpModuleShiftParts, newOperationScheduleModel } from '@/store/modules/OperationSchedule.js'
import { operationFrequencyOptionsForSelect, FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import { newDeviceOperationModel } from '@/store/modules/DeviceOperation.js'
import useAuth from '@/composables/useAuth.js'

import { create as operationBatchCreateCrossAccount, update as operationBatchUpdateCrossAccount, itemListOperationSetter } from '@/api_client/CrossAccountOperation.js'

import { daysOfTheWeek, isValidISO8601Date, dayNameFromISO8601String } from '@/helpers/DateTime.js'

import { EventBus, NEW_CROSS_ACCOUNT_OPERATION } from '@/EventBus.js'
import { isObject, isString } from '@/helpers/Utils.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { isRtl, i18n } from '@/i18n.js'
import { computed, ref, nextTick } from 'vue'

const DialogMode = { create: 0, update: 1, copy: 2 }
const DefaultFrequnecy = { frequency: null, frequencyType: null }
const defaultTaskNotifications = () => ({ taskComplete: false, taskUncomplete: false })
const taskTypes = {
  regular: 'regular',
  image: 'image',
  subtasks: 'subtasks'
}

export default {
  components: {
    'new-cross-account-task-job-search': NewCrossAccountTaskJobSearch,
    'file-uploader': FileUploader,
    'time-picker-dialog': TimePickerDialog,
    'task-frequency-field': TaskFrequencyField,
    'task-type-field': TaskTypeField,
    'task-subtasks-field': TaskSubtasksField,
    'task-subtasks-list': TaskSubtasksList,
    'task-shift-part-field': TaskShiftPartField,
    'task-color-select': TaskColorSelect,
    'task-notification-field': TaskNotificationField,
    'task-report-tag-field': TaskReportTagField,
    'task-guidence-images': TaskGuidenceImages
  },
  setup () {
    const dialog = ref(false)
    const dialogMode = ref(null)
    const frequencyExectDate = ref(null)
    const frequencyYearDate = ref([])
    const operationId = ref(null)
    const apiKey = ref(null)
    const requireImage = ref(false)
    const startTime = ref(null)
    const endDate = ref(null)
    const reportTag = ref(null)
    const taskNotifications = ref(defaultTaskNotifications())
    const jobSelectRef = ref(null)
    const taskFormRef = ref(null)
    const taskFileRef = ref(null)
    const taskSubTaskListDialogRef = ref(null)

    const taskGuidenceImagesRef = ref(null)

    const newOperation = ref(newDeviceOperationModel())
    const isLoading = ref(false)
    const accountJobs = ref([])
    const frequencyModel = ref(DefaultFrequnecy)
    const shiftPart = ref(null)
    const shiftParts = ref(OpModuleShiftParts())
    const moreDetailsOpen = ref(false)
    const taskType = ref(taskTypes.regular)

    const frequencyOptions = operationFrequencyOptionsForSelect()
    const dayOfTheWeekObj = {}
    const activeDay = ref(null)
    const newSubTasks = ref([])
    let originalSubTasks = []

    const reserContent = () => {
      dialogMode.value = null
      frequencyExectDate.value = null
      frequencyYearDate.value = []
      frequencyModel.value = DefaultFrequnecy
      operationId.value = null
      apiKey.value = null
      requireImage.value = false
      startTime.value = null
      endDate.value = null
      reportTag.value = null
      taskNotifications.value = defaultTaskNotifications()
      if (jobSelectRef.value) {
        jobSelectRef.value.reset()
      }
      activeDay.value = null
      taskFormRef.value.reset()
      taskFileRef.value.reset()
      moreDetailsOpen.value = false
      taskType.value = taskTypes.regular
      taskGuidenceImagesRef.value.reset()
      newSubTasks.value = []
      originalSubTasks = []
    }

    const isScheduleValid = () => {
      if (isFrequencyTypeExectDate.value) {
        return isValidISO8601Date(frequencyExectDate.value)
      }
      if (isFrequencyTypeEveryYear.value) {
        return isValidISO8601Date(frequencyYearDate.value[0])
      }
      if (isFrequencyTypeEveryDay.value) return true
      return activeDay.value !== null
    }

    const onOpenDialog = (dialogModeVal) => {
      dialog.value = true
      dialogMode.value = dialogModeVal
    }

    const upsertListItemRequestParams = () => {
      const oldList = [...originalSubTasks]
      const newList = [...newSubTasks.value]
      oldList.forEach((oldItem) => {
        const newItemIndex = newList.findIndex((item) => item.id === oldItem.id)
        if (newItemIndex === -1) {
          oldItem.is_deleted = true
          return
        }
        const newItem = newList[newItemIndex]
        if (newItem.description !== oldItem.description || newItem.item_type !== oldItem.item_type) {
          oldItem.is_updated = true
          oldItem.description = newItem.description
          oldItem.item_type = newItem.item_type
        }
      })
      const newItemListParam = newList.filter((item) => !Number.isInteger(item.id))
      return {
        update_params: oldList,
        create_params: newItemListParam
      }
    }

    const saveOperationImages = async ({ apiKey, operationId, imageUploadSignatures }) =>
      taskGuidenceImagesRef.value.saveImages({ apiKey, operationId, imageUploadSignatures })

    const saveOperationFile = async ({ apiKey, operationId, fileUploadSignature }) =>
      taskFileRef.value.saveFile({ apiKey, operationId, fileUploadSignature })

    const saveAdditionalFiles = async ({ apiKey, operationId, imageUploadSignatures, fileUploadSignature }) =>
      Promise.all([
        saveOperationImages({ apiKey, operationId, imageUploadSignatures }),
        saveOperationFile({ apiKey, operationId, fileUploadSignature })
      ])

    const itemListChanged = () => {
      if (taskType.value !== taskTypes.subtasks) {
        newSubTasks.value = []
      }
      const newList = newSubTasks.value
      if (originalSubTasks.length !== newList.length) return true
      return newList.some((newItem, index) => {
        const oldItem = originalSubTasks[index] || {}
        return (newItem.id !== oldItem.id || newItem.description !== oldItem.description ||
          newItem.item_type !== oldItem.item_type)
      })
    }

    const saveListItem = async ({ apiKey, operationId }) => {
      if (!itemListChanged()) return
      const requestParams = upsertListItemRequestParams()
      return itemListOperationSetter(apiKey, operationId, requestParams)
    }

    const initTaskType = (task) => {
      if (task.require_image) {
        taskType.value = taskTypes.image
      } else if (Array.isArray(task.item_operation_list) && task.item_operation_list.length > 0) {
        taskType.value = taskTypes.subtasks
      } else {
        taskType.value = taskTypes.regular
      }
    }

    const initSubtasks = (task) => {
      if (taskType.value !== taskTypes.subtasks) return
      originalSubTasks = (task.item_operation_list || []).map((item) => ({
        id: item.id,
        item_type: item.item_type,
        description: item.description
      }))
      newSubTasks.value = originalSubTasks.map((subTask) => ({ ...subTask }))

      if (DialogMode.copy === dialogMode.value) {
        originalSubTasks = []
      }
    }

    const openSubTaskDialog = () => {
      const oldList = JSON.parse(JSON.stringify(newSubTasks.value))
      taskSubTaskListDialogRef.value.openDialog(oldList)
        .then(({ isSaved, newList }) => {
          if (!isSaved) return

          newSubTasks.value = []
          const newSubTasksVal = newSubTasks.value
          newList.forEach((item) => newSubTasksVal.push({ ...item }))
        })
    }

    const initFrequncyParams = (operationSchedule) => {
      const preDefinedFrequency = frequencyOptions.find((frequency) => frequency.frequencyType === operationSchedule.frequency_type &&
        frequency.frequency === operationSchedule.frequency)

      if (!isObject(preDefinedFrequency)) return

      frequencyModel.value = preDefinedFrequency
      if (frequencyModel.value.frequencyType === FrequencyTypes.ExectDate) {
        frequencyExectDate.value = operationSchedule.operation_next_date_raw
      }
      if (frequencyModel.value.frequencyType === FrequencyTypes.EveryYear) {
        frequencyYearDate.value[0] = operationSchedule.operation_next_date_raw
      }
    }

    const initUpdateModels = ({ task: taskVal, apiKeyVal }) => {
      const task = newOperationScheduleModel(taskVal)
      requireImage.value = task.require_image
      startTime.value = task.start_time
      endDate.value = task.end_date
      reportTag.value = task.report_tag
      if (isString(taskVal.shift_part)) {
        shiftPart.value = { name: taskVal.shift_part }
      }
      newOperation.value = task.getItemOperation()
      operationId.value = taskVal.operation_id
      apiKey.value = apiKeyVal
      if (dialogMode.value === DialogMode.update) {
        nextTick(() => {
          taskGuidenceImagesRef.value.setImages(task.guidence_images)
          taskFileRef.value.setFile(task.guidance_file)
        })
      }
      initFrequncyParams(task)
      initTaskType(taskVal)
      initSubtasks(taskVal)
      if (isFrequencyTypeEveryXWeek.value) {
        const dayName = dayNameFromISO8601String(taskVal.first_scheduled_date)
        if (dayName !== null) {
          const dayOfWeek = daysOfTheWeek().find((day) => day.name === dayName)
          activeDay.value = dayOfWeek.name
        }
      }
    }

    const notificationParams = () => {
      const notificationsVal = taskNotifications.value
      const result = {}
      if (!notificationsVal.taskComplete && !notificationsVal.taskUncomplete) return result
      if (notificationsVal.taskComplete) result.task_complete = true
      if (operationHasStartTime.value && notificationsVal.taskUncomplete === true) result.task_uncomplete = true
      return result
    }

    const operationScheduleParams = () => {
      const frequencyParams = frequencyModel.value || {}
      const paramsResult = {
        frequency_type: frequencyParams.frequencyType || null,
        frequency: frequencyParams.frequency || null,
        require_image: requireImage.value,
        start_time: startTime.value,
        report_tag: reportTag.value,
        shift_part: shiftPart.value.name
      }
      if (isFrequencyTypeExectDate.value) {
        paramsResult.frequency = frequencyExectDate.value
        paramsResult.end_date = endDate.value
      } else if (isFrequencyTypeEveryYear.value) {
        paramsResult.frequency = frequencyYearDate.value[0]
      } else if (!isFrequencyTypeEveryDay.value) {
        paramsResult.day = activeDay.value
      }
      return paramsResult
    }

    const getRequestParams = () => {
      const itemOperation = newOperation.value.serialize()
      const operationSchedule = operationScheduleParams()
      const apiKeys = accountJobs.value.map((account) => account.account_api_key)
      const requestParams = {
        operation_schedule: { ...itemOperation, ...operationSchedule },
        accounts_api_keys: [...new Set(apiKeys)],
        account_job_ids: accountJobs.value.map((job) => job.id)
      }
      const uploadImageCount = taskGuidenceImagesRef.value.uploadImageCount()
      if (Number.isInteger(uploadImageCount) && uploadImageCount > 0) {
        requestParams.with_images = uploadImageCount
      }
      const attachedFileName = taskFileRef.value.attachedFileName()
      if (isString(attachedFileName) === true) {
        requestParams.with_file = attachedFileName
      }
      if (isCreateMode.value) {
        requestParams.operation_schedule.notifications = notificationParams()
      }
      return requestParams
    }

    const updateOrCreateCrossAccountOperationPromise = () => {
      const requestParams = getRequestParams()
      if (dialogMode.value === DialogMode.create || dialogMode.value === DialogMode.copy) {
        const { apiKey } = useAuth()
        return operationBatchCreateCrossAccount(apiKey.value, requestParams)
      }
      return operationBatchUpdateCrossAccount(apiKey.value, operationId.value, requestParams)
    }

    // Methods
    const toggleMoreDetails = () => (moreDetailsOpen.value = !moreDetailsOpen.value)
    const clearColor = () => newOperation.value.clearColor()
    const onChangeTaskType = (value) => {
      requireImage.value = value === taskTypes.image
    }

    const taskNotificationChange = ({ taskComplete, taskUncomplete } = {}) => {
      taskNotifications.value.taskComplete = taskComplete
      taskNotifications.value.taskUncomplete = taskUncomplete
    }

    const openDialog = () => {
      onOpenDialog(DialogMode.create)
      newOperation.value = newDeviceOperationModel()
      const { apiKey: apiKeyVal } = useAuth()
      apiKey.value = apiKeyVal.value
      frequencyModel.value = DefaultFrequnecy
      shiftPart.value = shiftParts.value.find((shiftPart) => shiftPart.name === 'start')
    }

    const openForUpdate = (task, apiKeyVal) => {
      onOpenDialog(DialogMode.update)
      initUpdateModels({ task, apiKeyVal })
    }

    const openForCopy = (task, apiKeyVal) => {
      onOpenDialog(DialogMode.copy)
      initUpdateModels({ task, apiKeyVal })
    }

    const close = () => {
      dialog.value = false
      reserContent()
    }

    const updateWorkingDays = (newWorkingDays) => {
      if (newWorkingDays.length === 0) {
        activeDay.value = null
      } else {
        activeDay.value = newWorkingDays[0]
      }
    }

    const save = () => {
      isLoading.value = true
      const operationData = {}
      updateOrCreateCrossAccountOperationPromise()
        .then(async (response) => {
          const responseData = response.data || {}
          operationData.imageUploadSignatures = responseData.image_upload_signature
          delete responseData.image_upload_signature
          operationData.fileUploadSignature = responseData.file_upload_signature
          delete responseData.file_upload_signature

          // in case all operations deleted an empty response will return
          if (Object.keys(responseData).length === 0) return

          const apiKey = Object.keys(responseData)[0]
          operationData.apiKey = apiKey
          operationData.operationId = responseData[apiKey].operation_schedule.id
          return saveListItem(operationData)
        })
        .then(() => saveAdditionalFiles(operationData))
        .then(() => {
          EventBus.emit(NEW_CROSS_ACCOUNT_OPERATION)
          close()
        })
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    // Computed
    const dialogTitle = computed(() => {
      if (dialogMode.value === DialogMode.update) return i18n.t('schedule_new_op_dialog.update_title')
      if (dialogMode.value === DialogMode.copy) return i18n.t('schedule_new_op_dialog.copy_title')
      return i18n.t('schedule_new_op_dialog.title')
    })
    const isCreateMode = computed(() => dialogMode.value === DialogMode.create)
    const isJobSelected = computed(() => accountJobs.value.length > 0)
    const operationHasStartTime = computed(() => isString(startTime.value))
    const showAddNewJob = computed(() => !Number.isInteger(operationId.value))
    const showCheckList = computed(() => taskType.value === taskTypes.subtasks)
    const subtasksCount = computed(() => newSubTasks.value.length)
    const isNewSubtasksInvalid = computed(() => newSubTasks.value.length === 0 ||
    newSubTasks.value.some((subTask) => !isString(subTask.description) || (isString(subTask.description) && subTask.description.trim().length === 0)))
    const isOperationDesctiptionValid = computed(() => {
      const description = newOperation.value?.description
      if (!isString(description)) return true
      return description.trim().length <= 240
    })
    const isFrequencyTypeExectDate = computed(() => {
      if (!isObject(frequencyModel.value)) return false
      return frequencyModel.value.frequencyType === FrequencyTypes.ExectDate
    })
    const isFrequencyTypeEveryYear = computed(() => {
      if (!isObject(frequencyModel.value)) return false
      return frequencyModel.value.frequencyType === FrequencyTypes.EveryYear
    })

    const isFrequencyTypeEveryDay = computed(() => {
      if (!isObject(frequencyModel.value)) return false
      return frequencyModel.value.frequencyType === FrequencyTypes.EveryDay
    })

    const isFrequencyTypeEveryXWeek = computed(() =>
      isObject(frequencyModel.value) && frequencyModel.value.frequencyType === FrequencyTypes.EveryWeek)

    const saveDisabled = computed(() => {
      if (isLoading.value === true) return true
      if (!isJobSelected.value && dialogMode.value === DialogMode.create) return true
      if (!isJobSelected.value && dialogMode.value === DialogMode.update) return false
      if (!isScheduleValid()) return true
      if (!isOperationDesctiptionValid.value) return true
      if (taskType.value === taskTypes.subtasks && isNewSubtasksInvalid.value === true) return true
      return false
    })

    const jobsIntersectWorkingDays = computed(() => {
      const result = []
      accountJobs.value.forEach((accountJob) => {
        const accountJobWorkingDays = accountJob.working_days
        if (Array.isArray(accountJobWorkingDays)) {
          result.push(accountJobWorkingDays)
        }
      })
      if (result.length === 0) return []
      return result.reduce((a, b) => a.filter(c => b.includes(c)))
    })

    const workingDays = computed(() => {
      if (!isJobSelected.value || isFrequencyTypeExectDate.value) return []
      const result = []
      jobsIntersectWorkingDays.value.forEach((dayOfWork) => {
        const day = Object.assign({}, dayOfTheWeekObj[dayOfWork])
        if (activeDay.value === day.name) {
          day.isActive = true
        }
        result.push(day)
      })
      return result
    })
    const shiftPartDisabled = computed(() => isFrequencyTypeExectDate.value || isFrequencyTypeEveryYear.value)

    daysOfTheWeek().forEach((dayOfWeek) => (dayOfTheWeekObj[dayOfWeek.name] = dayOfWeek))
    return {
      dialog,
      dialogMode,
      isLoading,
      accountJobs,
      isJobSelected,
      frequencyExectDate,
      frequencyYearDate,
      operationId,
      apiKey,
      requireImage,
      startTime,
      showAddNewJob,
      endDate,
      reportTag,
      taskNotifications,
      activeDay,
      jobSelectRef,
      taskFormRef,
      taskFileRef,
      taskGuidenceImagesRef,
      taskSubTaskListDialogRef,
      moreDetailsOpen,
      showCheckList,
      subtasksCount,
      newOperation,
      isCreateMode,
      saveDisabled,
      operationHasStartTime,
      isFrequencyTypeEveryXWeek,
      workingDays,
      frequencyModel,
      shiftPart,
      shiftPartDisabled,
      taskType,
      isRtl,
      dialogTitle,
      openDialog,
      openForUpdate,
      openForCopy,
      openSubTaskDialog,
      taskNotificationChange,
      close,
      toggleMoreDetails,
      onChangeTaskType,
      clearColor,
      updateWorkingDays,
      save
    }
  }
}
</script>
<style lang="scss">
.new-cross-account-task-dialog {
  overflow-y: hidden;
  .v-text-field--outlined fieldset  {
    border-color: #E8E8E8 ;
  }
  .save-btn.v-btn--disabled {
    color: white !important
  }
  .description-field {
    .v-label {
      padding-right: 10px;
    }
  }
  .scrollable {
    overflow: auto;
    height: 100%;
    max-height: 80vh;
  }
  .file-input {
    .file-chip {
      padding-right: 0;
    }
    .v-chip__content {
      span {
        color: #212121;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
}
</style>
