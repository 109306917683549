<template>
  <page-filter-fixed
    :any-filter-selected="anyFilterSelected"
    @reset-filters="resetFilters"
    @apply-filters="applyFilters"
  >
    <v-row align="start" justify="start">
      <v-col cols="12">
        <v-select
          v-model="filters.taskMode"
          :items="taskModeOptions"
          :class="{ 'hq': taskModeCrossAccount, 'filter-select': !taskModeSingleAccount, 'single-mode': taskModeSingleAccount }"
          height="30"
          item-text="name"
          :hide-details="taskModeSingleAccount"
          :outlined="taskModeSingleAccount"
          item-value="value"
          color="black"
          :menu-props="{ offsetY: true, 'content-class': `color2 t-700-14 rounded-xl ${isRtl ? ' rtl' : ' ltr'}` }"
        />
      </v-col>
      <v-col cols="12" class="py-0">
        <v-divider />
      </v-col>
      <v-col cols="12" class="pb-0">
        <company-district-select
          v-model="filters.companyDistricts"
          class="filter-select"
          height="25px"
          show-select-count
          :api-key="apiKey"
          :outlined="false"
          :dense="false"
          :menu-props="{ offsetY: true }"
          :show-empty-area="isUserCompanyAdmin"
        />
      </v-col>
      <v-col cols="12" class="pb-0">
        <account-select
          v-if="taskModeSingleAccount"
          v-model="filters.accounts[0]"
          :api-key="apiKey"
          :district-ids="filters.companyDistricts"
          :outlined="false"
          :dense="false"
          :multiple="false"
          height="25px"
          class="filter-select"
          @change="onAccountsChange"
        />
        <account-select
          v-else
          v-model="filters.accounts"
          :api-key="apiKey"
          :district-ids="filters.companyDistricts"
          :outlined="false"
          :dense="false"
          height="25px"
          multiple
          class="filter-select"
        />
      </v-col>
      <v-col v-if="showDepartmentSelect" cols="12" class="pb-0">
        <company-account-department-search
          v-model="filters.accountDepartmentIds"
          :api-key="apiKey"
          :account-api-keys="filters.accounts"
          outer-class="filter-select"
          add-empty-department
          height="25px"
        />
      </v-col>
      <v-col v-if="!taskModeCrossAccount" cols="12" class="pb-0">
        <hq-task-job-search
          v-model="filters.accountJobs"
          :account-api-keys="filters.accounts"
          :api-key="apiKey"
          height="25px"
          class="filter-select"
        />
      </v-col>
      <v-col v-if="!taskModeCrossAccount" cols="12" class="pb-0">
        <v-select
          v-model="filters.status"
          clearable
          :items="statusOptions"
          height="25px"
          :label="$t('account_operations.status')"
          class="filter-select"
        >
          <template #item="{ item }">
            <v-row
              align="center" justify="start" no-gutters
              :class="{ 'rtl': isRtl }"
            >
              {{ item.text }}
            </v-row>
          </template>
        </v-select>
      </v-col>
      <v-col v-if="!taskModeCrossAccount" cols="12">
        <operation-report-tag-field
          v-model="filters.tags"
          multiple
          class="filter-select"
          height="25px"
          show-select-count
        />
      </v-col>
      <v-col cols="12" class="t-500-14 py-1">
        <v-checkbox
          v-model="filters.myTasks"
          class="mt-0"
          hide-details
          :label="$t('hq_tasks_page.created_by_me')"
        />
      </v-col>
      <v-col v-show="!taskModeCrossAccount" cols="12" class="t-500-14 py-0">
        <v-checkbox
          v-model="filters.alertedTasks"
          class="mt-0"
          hide-details
          :label="$t('hq_tasks_page.alerted_tasks')"
        />
      </v-col>
      <v-col v-show="!taskModeCrossAccount" cols="12" class="t-500-14 py-0">
        <v-checkbox
          v-model="filters.crossAccountTasks"
          class="mt-0"
          hide-details
          :label="$t('account_operations.cross_account')"
        />
      </v-col>
      <v-col v-if="taskModeCrossAccount" class="py-11">
        &nbsp;
      </v-col>
    </v-row>
  </page-filter-fixed>
</template>
<script>
import PageFilterFixed from '@/components/shared/PageFilterFixed.vue'
import CompanyDistrictSelect from '@/components/shared/CompanyDistrictSelect.vue'
import AccountSelect from '@/components/shared/AccountSelect.vue'
import OperationReportTagField from '@/components/new_task_dialog/OperationReportTagField.vue'
import CompanyAccountDepartmentSearch from '@/components/shared/CompanyAccountDepartmentSearch.vue'
import HqTaskJobSearch from '@/components/shared/HqTaskJobSearch.vue'

import useHQTasksFilter from '@/composables/useHQTasksFilter.js'
import useAuth from '@/composables/useAuth.js'

import { computed, watch } from 'vue'
import { i18n, isRtl } from '@/i18n.js'

const OP_STATUS_DONE = 'done'
const OP_STATUS_NOT_REPORTED = 'not_reported'

export default {
  components: {
    'company-district-select': CompanyDistrictSelect,
    'account-select': AccountSelect,
    'page-filter-fixed': PageFilterFixed,
    'operation-report-tag-field': OperationReportTagField,
    'hq-task-job-search': HqTaskJobSearch,
    'company-account-department-search': CompanyAccountDepartmentSearch
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { isUserCompanyAdmin } = useAuth()
    const taskModeOptions = [
      { name: i18n.t('hq_tasks_page.all_tasks'), value: 'accounts' },
      { name: i18n.t('hq_tasks_page.hq_tasks'), value: 'crossAccount' },
      { name: i18n.t('hq_tasks_page.single_account_mode'), value: 'singleAccount' }
    ]
    const statusOptions = [
      { text: i18n.t('account_operations.status_done'), value: OP_STATUS_DONE },
      { text: i18n.t('account_operations.status_not_reported'), value: OP_STATUS_NOT_REPORTED }]

    const { taskModes, filters, anyFilterSelected, resetFilters, applyFilters } = useHQTasksFilter(props.apiKey)

    // Computed
    const taskModeCrossAccount = computed(() => filters.value.taskMode === taskModes.crossAccount)
    const taskModeSingleAccount = computed(() => filters.value.taskMode === taskModes.singleAccount)
    const taskModeAllAccounts = computed(() => filters.value.taskMode === taskModes.accounts)
    const showDepartmentSelect = computed(() => (taskModeSingleAccount.value && filters.value.accounts.length === 1) || taskModeAllAccounts.value)
    watch(() => filters.value.taskMode, (newTaskMode, oldTaskMode) => {
      if (newTaskMode === taskModes.singleAccount && oldTaskMode !== taskModes.singleAccount) {
        const newApiKey = filters.value.accounts.length > 0 ? filters.value.accounts[0] : props.apiKey
        filters.value.accounts = [newApiKey]
      }
    })
    const onAccountsChange = () => {
      filters.value.accountJobs.splice(0, filters.value.accountJobs.length)
      filters.value.accountDepartmentIds.splice(0, filters.value.accountDepartmentIds.length)
    }

    return {
      taskModeOptions,
      filters,
      anyFilterSelected,
      statusOptions,
      taskModeCrossAccount,
      taskModeSingleAccount,
      isRtl,
      showDepartmentSelect,
      isUserCompanyAdmin,
      taskModeAllAccounts,
      resetFilters,
      applyFilters,
      onAccountsChange
    }
  }
}
</script>
<style lang="scss">
.hq-tasks-layout {
  .filter-select.hq {
    background-color: black;
    .v-select__selection, .v-input__icon > .v-icon {
      color: white !important;
    }
  }
  .single-mode {
    border-radius: 100px;;
  }
}
</style>
