<template>
  <v-row
    class="hq-tasks-layout full-height pps-0" align="start" justify="start"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <add-task-button :api-key="apiKey" task-mode="hq" />
    <v-col class="flex-grow-1 flex-shrink-0 main-part mt-10 mt-sm-0">
      <v-slide-y-transition>
        <v-col v-if="showStats" cols="12" class="mt-10">
          <hq-tasks-top-stats :api-key="apiKey" />
        </v-col>
      </v-slide-y-transition>
      <hq-tasks-cross-account-layout
        v-if="taskModeCrossAccount"
        :api-key="apiKey"
        :client-date="clientDate"
        class="mt-10"
      />
      <hq-tasks-account-list-layout
        v-else-if="taskModeAccounts"
        :api-key="apiKey"
        :client-date="clientDate"
        class="mt-10"
      />
      <tasks-layout
        v-else
        class="pps-4"
        :api-key="lastAppliedApiKeyForSingleAccount"
        :client-date="clientDate"
        :date-range-scope="dateRangeScope"
        :show-filters="false"
        @date-range-scope-changed="onDateRangeScopeChanged"
        @change-date="changeDate"
      />
    </v-col>
    <v-col v-if="!$vuetify.breakpoint.xsOnly" class="flex-grow-0 flex-shrink-1 full-height px-0 pb-0">
      <hq-tasks-filter :api-key="apiKey" />
    </v-col>
  </v-row>
</template>
<script>
import HqTasksTopStats from '@/components/hq_tasks/hq_tasks_top_stat/HqTasksTopStats.vue'
import HqTasksAccountListLayout from '@/components/hq_tasks/hq_tasks_accounts/HqTasksAccountListLayout.vue'
import HqTasksCrossAccountLayout from '@/components/hq_tasks/hq_tasks_cross_account/HqTasksCrossAccountLayout.vue'
import TasksLayout from '@/components/tasks/layout/TasksLayout.vue'
import AddTaskButton from '@/components/tasks/AddTaskButton.vue'
import HqTasksFilter from '@/components/hq_tasks/HqTasksFilter.vue'

import useHQTasksFilter from '@/composables/useHQTasksFilter.js'
import useAuth from '@/composables/useAuth.js'

import { isRtl } from '@/i18n'

import { useRoute } from 'vue-router/composables'

import Vuetify from '@/plugins/vuetify.js'

import { computed } from 'vue'

export default {
  components: {
    'hq-tasks-account-list-layout': HqTasksAccountListLayout,
    'hq-tasks-cross-account-layout': HqTasksCrossAccountLayout,
    'tasks-layout': TasksLayout,
    'hq-tasks-top-stats': HqTasksTopStats,
    'add-task-button': AddTaskButton,
    'hq-tasks-filter': HqTasksFilter
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    clientDate: {
      type: Date,
      required: true
    },
    dateRangeScope: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const route = useRoute()
    const routeParams = {
      apiKey: route.params.apiKey,
      taskMode: route.params.taskMode,
      clientDate: props.clientDate
    }
    const { loadCurrentUser, isUserCompanyRegionAdmin } = useAuth()
    const { taskModeCrossAccount, taskModeAccounts, taskModeSingleAccount, lastAppliedApiKeyForSingleAccount, loadFilters } = useHQTasksFilter(props.apiKey)

    // Methods
    const onDateRangeScopeChanged = (dateRangeScopeVal) => emit('date-range-scope-changed', dateRangeScopeVal)
    const changeDate = (date) => emit('change-date', date)

    // Computed

    const showStats = computed(() => !taskModeSingleAccount.value && Vuetify.framework.breakpoint.smAndUp)
    if (isUserCompanyRegionAdmin.value) {
      // Company region admins should be able to edit/delete cross account tasks they created
      loadCurrentUser()
        .then(() => loadFilters(routeParams))
    } else {
      loadFilters(routeParams)
    }

    return {
      isRtl,
      taskModeCrossAccount,
      taskModeAccounts,
      lastAppliedApiKeyForSingleAccount,
      showStats,
      onDateRangeScopeChanged,
      changeDate
    }
  }
}
</script>

<style lang="scss">
.hq-tasks-layout {
  overflow: hidden;
  height: 100%;
  .add-task {
    right: 270px;
  }
  .main-part {
    height: 80vh;
    overflow: auto;
  }
}
.rtl {
  .hq-tasks-layout {
    .add-task {
      right: unset;
      left: 270px;
    }
  }
}
</style>
