import Client from '@/api_client/client.js'

const index = (apiKey, params) => {
  const DRIVE_FILE_INEDX_PATH = `/accounts/${apiKey}/drive_files`
  return Client.put(DRIVE_FILE_INEDX_PATH, params)
}

const create = (apiKey, params) => {
  const DRIVE_FILE_CREATE_PATH = `/accounts/${apiKey}/drive_files`
  return Client.post(DRIVE_FILE_CREATE_PATH, params)
}

const update = ({ apiKey, params, id }) => {
  const DRIVE_FILE_UPDATE_PATH = `/accounts/${apiKey}/drive_files/${id}`
  return Client.put(DRIVE_FILE_UPDATE_PATH, params)
}

const destroy = (apiKey, fileId) => {
  const DRIVE_FILE_DELETE_PATH = `/accounts/${apiKey}/drive_files/${fileId}`
  return Client.delete(DRIVE_FILE_DELETE_PATH)
}

const downloadedByList = ({ apiKey, fileId, page, perPage }) => {
  const DRIVE_FILE_DOWNLOADED_BY_PATH = `/accounts/${apiKey}/drive_files/${fileId}/downloaded_by`
  return Client.get(DRIVE_FILE_DOWNLOADED_BY_PATH, { params: { page, per_page: perPage } })
}

export {
  index,
  create,
  update,
  destroy,
  downloadedByList
}
