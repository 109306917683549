<template>
  <v-select
    ref="jobSelect"
    :class="outerClassValue"
    :value="value"
    :items="activeSelectJobs"
    :loading="isLoading"
    :item-text="getSelectionText"
    item-value="id"
    :clearable="clearable"
    :single-line="singleLine"
    :multiple="multiple"
    :background-color="backgroundColor"
    :disabled="disabled"
    :no-data-text="$t('no_result')"
    :label="label"
    :menu-props="menuPropsWithClass"
    :outlined="outlined"
    return-object
    :hide-details="hideDetails"
    @click:clear="clear"
    @change="onJobSelected"
    @input="update"
  >
    <template #selection="{ item, index }">
      <div v-if="multiple && index === 0" class="v-select__selection v-select__selection--comma">
        {{ $t('job_selection_search.x_jobs_selected', { job_count: value.length }) }}
      </div>
      <div v-else-if="!multiple" class="font-weight-bold full-width texts-start">
        {{ getSelectionText(item) }}
      </div>
    </template>
    <template #item="{ item, attrs }">
      <v-row
        v-if="multiple" no-gutters justify="start"
        align="center"
        :class="{ 'rtl': isRtl }"
        class="job-select-item"
      >
        <v-col cols="10" class="font-weight-bold">
          {{ getSelectionText(item) }}
        </v-col>
        <v-col cols="2" class="texts-end">
          <v-icon v-if="attrs.inputValue">
            check_box
          </v-icon>
          <v-icon v-else>
            check_box_outline_blank
          </v-icon>
        </v-col>
      </v-row>
      <v-row
        v-else align="center" justify="start"
        no-gutters
      >
        {{ getSelectionText(item) }}
      </v-row>
    </template>
  </v-select>
</template>
<script>
import { loadFirstXJobsForDay, loadFirstXJobs, activeSelectJobs } from '@/components/job_selection_search/JobSelectionStore.js'

import { translateShift as OrgJobTranslateShift } from '@/store/modules/OrganizationJob.js'

import { isString, isObject } from '@/helpers/Utils.js'
import { isRtl } from '@/i18n.js'

import { ref, computed, watch } from 'vue'

export default {
  props: {
    value: {
      type: [Object, Array],
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    day: {
      type: String,
      required: false,
      default: null
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => { }
    },
    apiKey: {
      type: String,
      required: true
    },
    hideShift: {
      type: Boolean,
      required: false,
      default: false
    },
    enabledDepartmentIds: {
      type: Array,
      required: false,
      default: () => []
    },
    searchForOperation: {
      type: Boolean,
      required: false,
      default: false
    },
    returnObject: {
      type: Boolean,
      required: false,
      default: false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    backgroundColor: {
      type: String,
      required: false,
      default: undefined
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    outerClass: {
      type: String,
      required: false,
      default: ''
    },
    singleLine: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup (props, { emit }) {
    const isLoading = ref(false)
    const jobSelect = ref(null)

    const getJobId = (job) => Number.isInteger(job) ? job : job.id

    const loadJobsForDay = (dayParam) => {
      if (isString(dayParam) !== true) return

      isLoading.value = true
      loadFirstXJobsForDay({ apiKey: props.apiKey, day: dayParam })
        .finally(() => (isLoading.value = false))
    }

    const departmentIdsArray = () => Array.isArray(props.enabledDepartmentIds) ? props.enabledDepartmentIds.map((id) => id) : []
    // Methods
    const clear = () => emit('input')

    const update = (newValue) => {
      if (props.returnObject) {
        emit('input', newValue)
        return
      }
      if (props.multiple) {
        emit('input', newValue.map(getJobId))
      } else {
        emit('input', newValue)
      }
    }

    const onJobSelected = (newValue) => {
      emit('change', newValue)
      // https://gist.github.com/cemerson/3907428 - hide soft keyboard on select
      if (props.multiple) return

      jobSelect.value.blur()
    }

    const getSelectionText = (job) => {
      if (job.shiftName && props.hideShift !== true) {
        return `${job.name} - ${OrgJobTranslateShift(job.shiftName)}`
      } else {
        return job.name
      }
    }

    const reloadJobs = () => {
      if (props.day !== null) {
        loadJobsForDay(props.day)
        return
      }
      isLoading.value = true
      loadFirstXJobs({ apiKey: props.apiKey, departmentIds: departmentIdsArray(), searchForOperation: props.searchForOperation })
        .finally(() => (isLoading.value = false))
    }

    const isSelected = (jobId) => {
      const jobIndex = (props.value || []).findIndex((job) => getJobId(job) === jobId)
      return jobIndex !== -1
    }
    // Computed
    const outerClassValue = computed(() => {
      const defaultClass = 'job-autocomplete'
      return isString(props.outerClass) && props.outerClass.length > 0 ? `${defaultClass} ${props.outerClass}` : defaultClass
    })

    const menuPropsWithClass = computed(() => {
      const result = isObject(props.menuProps) ? Object.assign(props.menuProps, {}) : {}
      result.contentClass = isString(result.contentClass) ? result.contentClass : ''
      result.contentClass = `${result.contentClass} jobs-select`
      return result
    })
    // Watch

    watch(() => props.day, (currentDay) => loadJobsForDay(currentDay), { immediate: true })

    watch(() => props.enabledDepartmentIds, () => {
      if (props.day !== null) return
      isLoading.value = true
      loadFirstXJobs({ apiKey: props.apiKey, departmentIds: departmentIdsArray() })
        .finally(() => (isLoading.value = false))
    })

    watch(() => props.searchForOperation, () => reloadJobs())
    watch(() => props.apiKey, () => reloadJobs())

    if (isString(props.day) !== true) reloadJobs()

    return {
      activeSelectJobs,
      isLoading,
      menuPropsWithClass,
      jobSelect,
      isRtl,
      outerClassValue,
      clear,
      update,
      isSelected,
      onJobSelected,
      getSelectionText,
      reloadJobs
    }
  }
}
</script>
<style lang="scss">
.jobs-select {
  div.v-subheader {
    background-color: #E8E8E8;
    font-weight: 500;
    font-size: 16px;
    max-height: 40px;
  }
}

.job-select-item {
  .v-input--selection-controls {
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0;

    .v-input__slot {
      margin-bottom: 0;
    }

    .v-messages {
      display: none;
    }
  }
}

.job-autocomplete {
  .v-input__control {
    .v-input__slot:before {
      border-color: black !important;
    }
  }
}
</style>
