<template>
  <v-row align="start" justify="start" class="single-checklist-item">
    <v-col cols="12" class="row no-gutters align-start justify-start py-0">
      <v-col class="flex-grow-0 flex-shrink-1 ppe-2 pt-2">
        <v-btn
          v-if="showChangeOrder" text small
          icon class="black--text drag-handle"
        >
          <v-icon>drag_indicator</v-icon>
        </v-btn>
      </v-col>
      <v-col class="flex-grow-1 flex-shrink-0">
        <v-textarea
          :value="checkListItem.description"
          class="mt-0 pt-0"
          :class="{ 'title-textarea': isTypeTitle }"
          counter="500"
          auto-grow
          no-resize
          :color="isTypeTitle ? 'white' : 'black'"
          :background-color="isTypeTitle ? '#0040FF' : '#E8E8E8'"
          rows="1"
          clearable
          :solo="isTypeTitle"
          :flat="isTypeTitle"
          @input="onDescriptionInput"
        />
      </v-col>
    </v-col>
    <v-col cols="7" class="pt-0">
      <v-select
        class="required mt-0 pt-0 select-type"
        :items="typeOptions"
        background-color="#E8E8E8"
        :value="checkListItem.item_type"
        item-value="id"
        :menu-props="{ 'contentClass': isRtl ? 'rtl' : 'ltr' }"
        item-text="translatedName"
        single-line
        solo flat
        hide-details
        @input="onTypeInput"
        @change="onTypeInputChange"
      >
        <template #selection="{item}">
          <div class="color1 t-400-16">
            <div>{{ item.translatedName }}</div>
          </div>
        </template>
        <template #item="{ item }">
          <v-row align="start" justify="start" no-gutters>
            <v-col cols="10" class="color5 t-600-18 px-2">
              {{ item.translatedName }}
              <div v-if="item.id === 'feedback'" class="color3 t-500-16">
                {{ $t('item_type_select.feedback_optional') }}
              </div>
            </v-col>
            <v-col cols="2" class="texts-end ppe-1">
              <v-checkbox
                :input-value="item.id === checkListItem.item_type"
                off-icon="radio_button_unchecked" on-icon="radio_button_checked"
                hide-details class="mt-0 pt-0 d-inline-block"
              />
            </v-col>
          </v-row>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>
<script>

import { itemTypes } from '@/models/ItemOperationListItemTypes.js'
import { i18n, isRtl } from '@/i18n.js'

import { computed } from 'vue'
export default {
  props: {
    checkListItem: {
      type: Object,
      required: true
    },
    showChangeOrder: {
      type: Boolean,
      required: true
    }
  },
  setup (props, { emit }) {
    const typeOptions = [{ id: itemTypes.default, translatedName: i18n.t('item_type_select.single_checklist') },
      { id: itemTypes.image, translatedName: i18n.t('item_type_select.image') },
      { id: itemTypes.yes_no, translatedName: i18n.t('item_type_select.yes_no') },
      { id: itemTypes.title, translatedName: i18n.t('item_type_select.title') },
      { id: itemTypes.rating, translatedName: i18n.t('item_type_select.rating') },
      { id: itemTypes.feedback, translatedName: i18n.t('item_type_select.feedback') }
    ]

    // Methods
    const onTypeInput = (newValue) => emit('update:item-type', newValue)
    const onTypeInputChange = () => emit('change-item-type', props.checkListItem)
    const onDescriptionInput = (newValue) => emit('update:description', newValue)

    // Computed
    const isTypeTitle = computed(() => props.checkListItem.item_type === itemTypes.title)
    return {
      isRtl,
      typeOptions,
      isTypeTitle,
      onTypeInput,
      onTypeInputChange,
      onDescriptionInput
    }
  }
}
</script>
<style lang="scss">
.single-checklist-item {
  .select-type {
    margin-top: -15px !important;
    .v-select__slot {
      cursor: pointer;
    }
  }
  .title-textarea {
    border-radius: 8px;
    .v-text-field__slot {
      textarea {
        color: white;
        font-size: 16px;
        font-weight: 700;
      }
    }
    .v-input__icon.v-input__icon--clear {
      button {
        color: white !important;
      }
    }
  }
}
</style>
