<template>
  <v-row align="center" justify="start">
    <image-carousel-dialog ref="imageCarouselDialog" />
    <v-col
      v-for="(image, index) in images"
      :key="image.randId" cols="3" class="pps-0"
    >
      <single-image-uploader
        ref="imageUploader"
        width="90px"
        :url="image.url"
        @image-removed="onImageDelete(index)"
        @image-preview-click="onImagePreviewClick(index)"
      />
    </v-col>
    <v-col v-if="showNewImage" cols="3" class="pps-0">
      <single-image-uploader
        width="90px"
        @image-uploaded="onNewImageUploaded"
      />
    </v-col>
  </v-row>
</template>
<script>
import ImageCarouselDialog from '@/components/shared/ImageCarouselDialog.vue'
import SingleImageUploader from '@/components/shared/SingleImageUploader.vue'

import {
  addImages as CrossAccountOperationAddImages,
  removeImages as CrossAccountOperationRemoveImages
} from '@/api_client/CrossAccountOperation.js'

import {
  addImages as ItemOperationAddImages,
  removeImages as ItemOperationRemoveImages
} from '@/api_client/DeviceOperation.js'
import { uploadImage as uploadImageToRemoteServer } from '@/general_client/CloudinaryImageUploader.js'

import { isFile } from '@/helpers/Utils.js'

import { ref, computed, nextTick } from 'vue'

const recordTypes = { itemOperation: 'ItemOperation', crossAccountOpperation: 'CrossAccountOperation' }
const defaultImage = () => ({ isLoading: false, url: null, originalUploadedFile: null, hasImage: false, imageId: null, randId: Math.random().toString() })

const deleteImages = ({ apiKey, itemId, itemOperationId, operationId, imageIdsToDelete, recordType }) => {
  const params = { image_ids: imageIdsToDelete }
  if (recordType === recordTypes.itemOperation) {
    params.id = itemOperationId
    return ItemOperationRemoveImages({ apiKey, itemId, itemOperationId, params })
  } else {
    return CrossAccountOperationRemoveImages({ apiKey, operationId, params })
  }
}

const createNewImages = async ({ apiKey, itemId, itemOperationId, operationId, imageUploadSignatures, imageFiles, recordType }) => {
  const uploadImageToRemoteServerPromises = []
  imageFiles.forEach((imageFile, index) => {
    const imageSignature = imageUploadSignatures[index]
    uploadImageToRemoteServerPromises.push(uploadImageToRemoteServer(imageFile, imageSignature))
  })
  await Promise.all(uploadImageToRemoteServerPromises)

  const imagePublicIds = imageUploadSignatures.map((signature) => signature.public_id)
  const params = { public_ids: imagePublicIds }
  if (recordType === recordTypes.itemOperation) {
    params.id = itemOperationId
    return ItemOperationAddImages({ apiKey, itemId, itemOperationId, params })
  } else {
    return CrossAccountOperationAddImages({ apiKey, operationId, params })
  }
}
const MaxImages = 6
export default {
  components: {
    'image-carousel-dialog': ImageCarouselDialog,
    'single-image-uploader': SingleImageUploader
  },
  props: {
    recordType: {
      type: String,
      required: true,
      validator: function (recordType) {
        return Object.values(recordTypes).indexOf(recordType) !== -1
      }
    }
  },
  setup (props) {
    const imageCarouselDialog = ref(null)
    const imageUploader = ref(null)
    const addingImage = ref(false)
    const images = ref([])
    let imageIdsToDelete = []

    const reset = () => {
      imageIdsToDelete = []
      images.value.forEach((image) => {
        if (image.originalUploadedFile) {
          URL.revokeObjectURL(image.url)
          image.originalUploadedFile = null
        }
      })
      images.value = []
    }

    // Methods
    const onImageDelete = (index) => {
      const image = images.value[index]
      if (Number.isInteger(image.imageId)) {
        imageIdsToDelete.push(image.imageId)
      }
      images.value.splice(index, 1)
    }

    const onImagePreviewClick = (index) => {
      const imageUrls = images.value.map((image) => image.url)
      imageCarouselDialog.value.openDialog({ imageUrls, index })
    }

    const onNewImageUploaded = ({ image, event }) => {
      const file = event.target.files[0]
      if (!file) return
      addingImage.value = true
      const newImage = defaultImage()
      newImage.url = image.url
      newImage.hasImage = true
      newImage.originalUploadedFile = file
      images.value.push(newImage)
      nextTick(() => (addingImage.value = false))
    }

    const setImages = (imagesDate) => {
      reset()
      imageIdsToDelete = []
      const userImages = Array.isArray(imagesDate)
        ? imagesDate
        : []
      userImages.forEach((imageDate) => {
        const image = defaultImage()
        image.url = imageDate.url
        image.hasImage = true
        image.imageId = imageDate.id
        images.value.push(image)
      })
    }
    const imagesToUpload = () => images.value.filter((image) => image.hasImage && !Number.isInteger(image.imageId) && isFile(image.originalUploadedFile))
    const uploadImageCount = () => imagesToUpload().length

    const saveImages = async ({ apiKey, itemId, itemOperationId, operationId, imageUploadSignatures } = {}) => {
      const imageFiles = imagesToUpload().map((image) => image.originalUploadedFile)
      if (imageIdsToDelete.length > 0) {
        await deleteImages({ apiKey, itemId, itemOperationId, operationId, imageIdsToDelete, recordType: props.recordType })
      }
      if (imageFiles.length > 0) {
        await createNewImages({ apiKey, itemId, itemOperationId, operationId, imageUploadSignatures, imageFiles, recordType: props.recordType })
      }
    }

    // Computed
    const showNewImage = computed(() => !addingImage.value && images.value.length < MaxImages)
    return {
      imageCarouselDialog,
      imageUploader,
      images,
      showNewImage,
      onNewImageUploaded,
      onImageDelete,
      onImagePreviewClick,
      setImages,
      uploadImageCount,
      saveImages,
      reset
    }
  }
}
</script>
