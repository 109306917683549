<template>
  <v-row
    v-if="taskCountIsLoading" align="center" justify="center"
    class="fill-height my-3"
  >
    <v-progress-circular indeterminate color="primary" />
  </v-row>
  <div v-else class="shift-wrapper t-500-16 color5">
    <v-row
      v-for="shiftPart in shiftParts" :key="shiftPart.name" align="center"
      justify="space-between" class="shift-row mt-0 mb-0 pointer"
      :class="{'disabled': shiftPartDisabled(shiftPart.name)}"
      @click="onShiftClick(shiftPart.name)"
    >
      <v-col cols="10" class="texts-start">
        {{ shiftPart.translateName }}
        <span v-if="!shiftPartDisabled(shiftPart.name)" class="t-700-16">({{ taskCount(shiftPart.name) }})</span>
      </v-col>
      <v-col cols="2" class="texts-end">
        <v-icon class="rtl-rotate" color="#616365">
          chevron_right
        </v-icon>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import useTaskOrder from '@/composables/useTaskOrder.js'
import { shiftPartsForSelect } from '@/store/modules/OperationSchedule.js'

export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const { taskCountIsLoading, taskCountPerShiftPart } = useTaskOrder(props.apiKey)

    const shiftParts = shiftPartsForSelect()

    // Methods

    const onShiftClick = (shiftPartName) => {
      if (shiftPartDisabled(shiftPartName)) return

      emit('shift-part-selected', shiftPartName)
    }
    const taskCount = (shiftPartName) => taskCountPerShiftPart.value[shiftPartName] || 0
    const shiftPartDisabled = (shiftPartName) => taskCount(shiftPartName) === 0
    return {
      shiftParts,
      taskCountIsLoading,
      taskCountPerShiftPart,
      onShiftClick,
      taskCount,
      shiftPartDisabled
    }
  }
}
</script>
<style lang="scss">
.task-order-dialog {
  .shift-wrapper {
    background-color: white;
    border-radius: 12px;
    border: 1px solid #E8E8E8;
    .shift-row + .shift-row {
      border-top: 1px solid #E8E8E8;
    }
    .disabled {
      cursor: default;
      color: #9E9E9E;
    }
  }
}

</style>
