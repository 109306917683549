<template>
  <v-row align="start" justify="start" no-gutters>
    <hq-task-actions :api-key="apiKey" />
    <v-col v-if="isLoadingAccounts" cols="12">
      <v-skeleton-loader
        v-for="i in 3" :key="i" class="mt-3"
        type="list-item-three-line"
      />
    </v-col>
    <v-col v-else-if="isEmptyPage" cols="12">
      <hq-tasks-empty-page />
    </v-col>
    <v-col v-else cols="12">
      <v-row
        align="start" justify="start" no-gutters
        class="hq-task-account-list"
      >
        <v-col v-for="(account, index) in accountsList" :key="account.api_key" cols="12">
          <hq-tasks-account-list-single-account :api-key="apiKey" :account="account" />
          <v-divider v-if="accountsCount > index " />
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="isLoadingAccountsNextPage" cols="12">
      <v-skeleton-loader
        v-for="i in 3" :key="i" class="mt-3"
        type="list-item-three-line"
      />
    </v-col>
    <v-col v-if="hasMoreAccountsToLoad" v-intersect.once="onIntersect" cols="12" />
  </v-row>
</template>
<script>
import HqTasksAccountListSingleAccount from '@/components/hq_tasks/hq_tasks_accounts/HqTasksAccountListSingleAccount.vue'
import HqTaskActions from '@/components/hq_tasks/hq_tasks_accounts/HqTaskActions.vue'
import HqTasksEmptyPage from '@/components/hq_tasks/HqTasksEmptyPage.vue'

import useHQDailyTasks from '@/composables/useHQDailyTasks.js'

import { dateToISO8601String } from '@/helpers/DateTime.js'

import { watch, computed } from 'vue'
export default {
  comments: {
    'hq-tasks-account-list-single-account': HqTasksAccountListSingleAccount,
    'hq-task-actions': HqTaskActions,
    'hq-tasks-empty-page': HqTasksEmptyPage
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    clientDate: {
      type: Date,
      required: true
    }
  },
  setup (props) {
    const {
      accountsList, isLoadingAccounts, isLoadingAccountsNextPage, hasMoreAccountsToLoad, isEmptyPage, clientDate,
      loadAccountsNextPage, applyFilters, changeDate
    } = useHQDailyTasks(props.apiKey)

    const onIntersect = loadAccountsNextPage

    applyFilters()

    watch(() => props.clientDate, (newDate) => {
      if (dateToISO8601String(newDate) === dateToISO8601String(clientDate.value)) return

      changeDate(newDate)
    })

    const accountsCount = computed(() => accountsList.value.length - 1)

    return {
      accountsList,
      isLoadingAccounts,
      isLoadingAccountsNextPage,
      hasMoreAccountsToLoad,
      isEmptyPage,
      accountsCount,
      onIntersect
    }
  }
}
</script>
