<template>
  <v-dialog
    v-model="dialog" max-width="350"
    persistent scrollable
    content-class="rounded-xl"
  >
    <v-card class="message-info-dialog bg-color16" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="white px-1">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="2" class="texts-start">
            <v-btn
              icon
              color="black"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center color5 t-800-17">
            {{ $t('app_chat.seen_by_dialog.title') }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="py-2 px-0 scrollable">
        <v-row
          v-if="isLoading" align="center" justify="center"
          class="pt-4"
        >
          <v-progress-circular indeterminate color="primary" />
        </v-row>
        <v-row v-else align="center" justify="start">
          <v-col cols="12" class="text-center pt-4 bg-color20">
            <v-chip :ripple="false" color="white">
              {{ messageDateText }}
            </v-chip>
            <div class="mt-4">
              <chat-message-content :message="chatMessage" :show-options="false" class="bg-color21" />
            </div>
          </v-col>
          <v-col v-if="showReadByList" cols="12" class="pa-0">
            <div class="d-flex align-center justify-start bg-color22 py-2">
              <span class="px-2 color5 t-400-16">{{ $t('app_chat.seen_by_dialog.read_by') }}</span> <v-icon
                color="#0040FF"
              >
                done_all
              </v-icon>
            </div>
            <read-by-user-list :users="readByList" />
          </v-col>
          <v-col v-if="showPendingList" cols="12" class="pa-0">
            <div class="d-flex align-center justify-start bg-color22 py-2">
              <span class="px-2 color5 t-400-16">{{ $t('app_chat.seen_by_dialog.pending') }}</span>
            </div>
            <read-by-user-list :users="pendingReadByList" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import ChatMessageContent from '@/components/app_chat/chat_message/ChatMessageContent.vue'
import ReadByUserList from '@/components/app_chat/read_by_dialog/ReadByUserList.vue'

import useAccount from '@/composables/useAccount.js'

import { readBy as chatMessageClientReadBy } from '@/api_client/ChatMessage.js'

import { ISO8601dateToText, ISO8601dateTimeStringToISODate } from '@/helpers/DateTime.js'

import { isString } from '@/helpers/Utils.js'
import { isRtl } from '@/i18n.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed } from 'vue'

export default {
  components: {
    'chat-message-content': ChatMessageContent,
    'read-by-user-list': ReadByUserList
  },

  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    let chatGroupId = null
    const chatMessage = ref({})
    const dialog = ref(false)
    const isLoading = ref(false)
    const userList = ref([])
    const { accountCountryCode } = useAccount()
    const countryCode = accountCountryCode.value

    const resetValues = () => {
      chatGroupId = null
      isLoading.value = false
      chatMessage.value = {}
      userList.value = []
    }

    const loadReadBy = () => {
      const userListVal = userList.value
      return chatMessageClientReadBy(props.apiKey, { chatGroupId, chatMessageId: chatMessage.value.id })
        .then((response) => response.data.data.forEach((user) => userListVal.push(user)))
        .catch(errHandler)
    }
    // Methods
    const openDialog = ({ message, chatGroupId: groupId }) => {
      chatMessage.value = JSON.parse(JSON.stringify(message))
      chatGroupId = groupId
      dialog.value = true
      isLoading.value = true
      loadReadBy()
        .finally(() => (isLoading.value = false))
    }

    const close = () => {
      dialog.value = false
      resetValues()
    }

    // Computed

    const readByList = computed(() => userList.value.filter((user) => user.read === true))
    const pendingReadByList = computed(() => userList.value.filter((user) => user.read !== true))
    const showReadByList = computed(() => readByList.value.length > 0)
    const showPendingList = computed(() => pendingReadByList.value.length > 0)

    const messageDateText = computed(() => {
      const createdAt = chatMessage.value.created_at_date_raw
      if (!isString(createdAt)) return ''
      const isoDate = ISO8601dateTimeStringToISODate(createdAt)
      return ISO8601dateToText({ fromDate: isoDate, countryCode })
    })

    return {
      dialog,
      isLoading,
      userList,
      readByList,
      pendingReadByList,
      messageDateText,
      chatMessage,
      showReadByList,
      isRtl,
      showPendingList,
      openDialog,
      close
    }
  }
}
</script>
<style lang="scss">
.message-info-dialog {
  overflow-y: hidden;

  .scrollable {
    overflow: auto;
    height: 50vh;
    max-height: 70vh;
  }
}
</style>
