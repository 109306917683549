import mitt from 'mitt'
const emitter = mitt()

export const NEW_ACCOUNT_OPERATION = 'NEW_ACCOUNT_OPERATION'
export const NEW_CROSS_ACCOUNT_OPERATION = 'NEW_CROSS_ACCOUNT_OPERATION'
export const RESET_OPERATION_DIALOG_CONTENT = 'RESET_OPERATION_DIALOG_CONTENT'
export const USER_UPDATE = 'USER_UPDATE'
export const ACCOUNT_JOB_CREATE = 'ACCOUNT_JOB_CREATE'
export const ACCOUNT_JOB_UPDATE = 'ACCOUNT_JOB_UPDATE'
export const ACCOUNT_JOB_DELETE = 'ACCOUNT_JOB_DELETE'
export const ACCOUNT_JOB_TASK_ORDER_CHANGE_CLICK = 'ACCOUNT_JOB_TASK_ORDER_CHANGE_CLICK'

export const SHIFT_MARKED_COMPLETED = 'SHIFT_MARKED_COMPLETED'
export const USER_CONNECTED = 'USER_CONNECTED'
export const USER_DISCONNECTED = 'USER_DISCONNECTED'
export const NEW_CHAT_GROUP_CREATED = 'NEW_CHAT_GROUP_CREATED'
export const ACCOUNT_OPERATION_DELETED = 'ACCOUNT_OPERATION_DELETED'

export const TASK_DELETE_CLICK = 'TASK_DELETE_CLICK'
export const TASK_COPY_CLICK = 'TASK_COPY_CLICK'
export const TASK_SHARE_CLICK = 'TASK_SHARE_CLICK'
export const TASK_EDIT_CLICK = 'TASK_EDIT_CLICK'
export const TASK_NOTIFICATION_CLICK = 'TASK_NOTIFICATION_CLICK'
export const TASK_IMAGE_CLICK = 'TASK_IMAGE_CLICK'
export const TASK_GUIDANCE_IMAGE_CLICK = 'TASK_GUIDANCE_IMAGE_CLICK'
export const TASK_CHAT_CLICK = 'TASK_CHAT_CLICK'
export const TASK_TOGGLE_COMPLETE_CLICK = 'TASK_TOGGLE_COMPLETE_CLICK'
export const TASK_TOGGLE_COMPLETE_PREVENTED_CLICK = 'TASK_TOGGLE_COMPLETE_PREVENTED_CLICK'

export const ACTIVE_APP_CHAT_GROUP_CHANGED = 'ACTIVE_APP_CHAT_GROUP_CHANGED'
export const APP_CHAT_NEW_MESSAGE_SENT = 'APP_CHAT_NEW_MESSAGE_SENT'

export const HQ_TASKS_FILTER_CHANGED = 'HQ_TASKS_APPLY_FILTERS'
export const TASKS_FILTER_CHANGED = 'TASKS_APPLY_FILTERS'

// Tags events
export const UPDATE_TAGS_IF_NEEDED = 'UPDATE_TAGS_IF_NEEDED'

// Websocket events
export const APP_CHAT_USER_JOINED_GROUP = 'APP_CHAT_USER_JOINED_GROUP'
export const NEW_APP_CHET_MESSAGE_RECEIVED = 'NEW_APP_CHET_MESSAGE_RECEIVED'

export { emitter as EventBus }
