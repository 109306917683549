<template>
  <v-row class="single-provider my-0" align="start" justify="start">
    <v-col class="flex-grow-1 flex-shrink-0">
      <span class="color10 t-500-18">
        {{ provider.name }}
      </span>
      <div v-if="showContactName" class="color2 t-400-15">
        {{ provider.contact_name }}
      </div>
      <div v-if="showComment" class="color2 ">
        <div class="color2 t-700-17">
          {{ $t('comment_1') }}
        </div>
        <span class="t-400-16"> {{ provider.comment }} </span>
      </div>
    </v-col>
    <v-col v-if="!readOnly" class="flex-grow-0 flex-shrink-1">
      <v-menu close-on-content-click>
        <template #activator="{ on }">
          <v-icon color="black" v-on="on">
            more_vert
          </v-icon>
        </template>
        <v-list class="edit-operation">
          <v-list-item ripple @click="editProvider">
            <v-list-item-icon>
              <v-icon small>
                edit
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
          </v-list-item>
          <v-list-item ripple @click="deleteProvider">
            <v-list-item-icon>
              <v-icon small>
                delete
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col cols="12">
      <v-btn
        depressed rounded color="#0040FF"
        :disabled="!phoneEnabled"
        :href="`tel:${provider.phone}`"
        class="white--text mme-4"
        min-width="300px"
        min-height="48px"
      >
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" class="text-center">
            <v-icon>
              phone
            </v-icon>
            {{ $t('call') }}
          </v-col>
          <v-col v-if="phoneEnabled" cols="12" class="text-center">
            {{ provider.phone }}
          </v-col>
        </v-row>
      </v-btn>
      <v-btn
        outlined rounded color="#0040FF"
        :disabled="!emailEnabled"
        :href="`mailto:${provider.email}`"
        min-width="300px"
        min-height="48px"
      >
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" class="text-center">
            <v-icon>
              sms
            </v-icon>
            {{ $t('text') }}
          </v-col>
          <v-col v-if="emailEnabled" cols="12" class="text-center">
            {{ provider.email }}
          </v-col>
        </v-row>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { isString } from '@/helpers/Utils.js'

import { computed } from 'vue'
export default {
  props: {
    provider: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // Methods

    const editProvider = () => emit('edit-provider', { provider: props.provider })
    const deleteProvider = () => emit('delete-provider', { provider: props.provider })

    // Computed
    const showContactName = computed(() => isString(props.provider.contact_name) && props.provider.contact_name.length > 0)
    const showComment = computed(() => isString(props.provider.comment) && props.provider.comment.length > 0)
    const menuDisabled = computed(() => !props.provider.email && !props.provider.phone)
    const phoneEnabled = computed(() => isString(props.provider.phone) && props.provider.phone.length > 0)
    const emailEnabled = computed(() => isString(props.provider.email) && props.provider.email.length > 0)
    return {
      menuDisabled,
      showContactName,
      showComment,
      phoneEnabled,
      emailEnabled,
      editProvider,
      deleteProvider
    }
  }
}
</script>
<style lang="scss">
.single-provider {
  border-radius: 8px;
  background: white;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
}
</style>
