<template>
  <v-row align="start" justify="start" class="white rounded t-400-16 my-0">
    <v-col cols="12" class="color2 pb-0">
      {{ $t('schedule_new_op_dialog.task_type') }}
    </v-col>
    <v-col cols="12" class="color2 px-1">
      <v-radio-group
        class="mt-0" hide-details :value="value"
      >
        <v-row align="start" justify="start" class="ppe-2">
          <v-col cols="11" class="color5 pointer texts-start" @click="onRowClick('regular')">
            {{ $t('schedule_new_op_dialog.task_type_regular') }}
          </v-col>
          <v-col cols="1" class="texts-end" @click="onRowClick('regular')">
            <v-radio value="regular" />
          </v-col>
          <v-col cols="11" class="color5 pointer texts-start" @click="onRowClick('image')">
            {{ $t('schedule_new_op_dialog.task_type_image') }}
          </v-col>
          <v-col cols="1" class="texts-end" @click="onRowClick('image')">
            <v-radio value="image" />
          </v-col>
          <v-col cols="11" class="color5 pointer texts-start" @click="onRowClick('subtasks')">
            {{ $t('schedule_new_op_dialog.task_type_subtasks') }}
          </v-col>
          <v-col cols="1" class="texts-end" @click="onRowClick('subtasks')">
            <v-radio value="subtasks" />
          </v-col>
        </v-row>
      </v-radio-group>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  setup (_, { emit }) {
    // Methods
    const onInput = (value) => emit('input', value)
    const changeTaskType = (value) => emit('change', value)

    const onRowClick = (value) => {
      onInput(value)
      changeTaskType(value)
    }
    return {
      onInput,
      changeTaskType,
      onRowClick
    }
  }
}
</script>
