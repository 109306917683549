
import { indexForOrderTasks, updateTaskOrder, countPerShiftPart } from '@/api_client/JobOperationSchedule.js'

import { isString } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref } from 'vue'
let currentApiKey = null

const DEFAULT_PER_PAGE = 100
const DEFAULT_PAGE = 1

const defaultPagination = () => ({ page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE, total: 0 })

const isLoading = ref(false)
const isLoadingNextPage = ref(false)
const taskList = ref([])
const pagination = ref(defaultPagination())
const originalOrder = ref([])
const taskCountIsLoading = ref(false)
const taskCountPerShiftPart = ref({})
export default function useUserNotification (apiKey) {
  const resetValues = (resetTaskCount) => {
    isLoading.value = false
    isLoadingNextPage.value = false
    originalOrder.value = []
    pagination.value = defaultPagination()
    taskList.value = []
    taskCountIsLoading.value = false
    if (resetTaskCount) {
      taskCountPerShiftPart.value = {}
    }
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues(true)
    currentApiKey = apiKey
  }

  const setPaginationFromResponse = (meta) => {
    const paginationVal = pagination.value
    paginationVal.total = meta.total
    paginationVal.page = meta.page
    paginationVal.perPage = meta.per_page
  }

  // Methods

  const loadNextPage = () => {
    pagination.value.page++
    loadTasksPage()
  }

  const loadTasksPage = ({ accountJobId, shiftPartName }) => {
    if (pagination.value.page > DEFAULT_PAGE) {
      isLoadingNextPage.value = true
    } else {
      isLoading.value = true
    }
    const requestParams = {
      apiKey: currentApiKey,
      accountJobId,
      shiftPartName,
      page: pagination.value.page,
      perPage: pagination.value.per_page
    }
    indexForOrderTasks(requestParams)
      .then(({ data: { data, meta } }) => {
        setPaginationFromResponse(meta)
        taskList.value = taskList.value.concat(data)
        isLoading.value = false
        data.forEach((task) => originalOrder.value.push(task.operation_id))
      })
      .catch(errHandler)
      .finally(() => {
        isLoading.value = false
        isLoadingNextPage.value = false
      })
  }

  const updateTasksOrder = ({ accountJobId, shiftPartName }) => {
    isLoading.value = true
    const newOrder = taskList.value.map((task) => task.operation_id)
    return updateTaskOrder({ apiKey: currentApiKey, accountJobId, shiftPartName, taskOrder: newOrder })
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const loadTaskCountPerShiftPart = (accountJobId) => {
    taskCountIsLoading.value = true
    return countPerShiftPart({ apiKey: currentApiKey, accountJobId })
      .then(({ data: { data } }) => (taskCountPerShiftPart.value = data))
      .catch(errHandler)
      .finally(() => (taskCountIsLoading.value = false))
  }

  // Computed

  return {
    isLoading,
    isLoadingNextPage,
    taskList,
    pagination,
    originalOrder,
    taskCountIsLoading,
    taskCountPerShiftPart,
    resetValues,
    loadTasksPage,
    loadNextPage,
    updateTasksOrder,
    loadTaskCountPerShiftPart
  }
}
