import { getJobWorkingDays } from '@/store/modules/AccountJobWorkDays.js'
import { newOperationScheduleModel } from '@/store/modules/OperationSchedule.js'
import { operationFrequencyOptionsForSelect, FrequencyTypes } from '@/store/modules/OperationFrequency.js'

import {
  createWithItem as jobOpScheduleCreateWithItem,
  updateWithItem as jobOpScheduleUpdateWithItem
} from '@/api_client/JobOperationSchedule.js'
import {
  batchUpsert as itemOperationListItemClientBatchUpsert,
  updateItemsOrder as itemOperationListItemClientUpdateItemsOrder
} from '@/api_client/ItemOperationListItem.js'

import { daysOfTheWeek, isValidISO8601Date, dayNameFromISO8601String } from '@/helpers/DateTime.js'
import { isObject, isString } from '@/helpers/Utils.js'

import { newDeviceOperationModel } from '@/store/modules/DeviceOperation.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed } from 'vue'

const taskTypes = {
  regular: 'regular',
  image: 'image',
  subtasks: 'subtasks'
}

const generalItemId = 'general'

const defaultTaskNotifications = () => ({ taskComplete: false, taskUncomplete: false })

export default function useNewOperation (apiKey) {
  const isLoading = ref(false)
  const newOperation = ref(newDeviceOperationModel())
  const requireImage = ref(false)
  const currentJobSearchResult = ref(null)
  const dayOfTheWeekObj = ref({})
  const activeDays = ref([])
  const newSubTasks = ref([])
  const shiftPart = ref(null)
  const startTime = ref(null)
  const endDate = ref(null)
  const frequencyExectDate = ref(null)
  const frequencyYearDate = ref([])
  const frequencyModel = ref({ frequency: null, frequencyType: null })

  const operationScheduleId = ref(null)
  const reportTag = ref(null)
  const relatedItem = ref({})
  const taskNotifications = ref(defaultTaskNotifications())
  const taskType = ref(taskTypes.regular)

  let currentApiKey = apiKey
  let originalJobId = null
  let originalSubTasks = []
  let subTaskOrderChanged = false

  daysOfTheWeek().forEach((dayOfWeek) => (dayOfTheWeekObj.value[dayOfWeek.name] = dayOfWeek))

  const initTaskType = (task) => {
    if (task.require_image) {
      taskType.value = taskTypes.image
    } else if (Array.isArray(task.item_operation_list) && task.item_operation_list.length > 0) {
      taskType.value = taskTypes.subtasks
    } else {
      taskType.value = taskTypes.regular
    }
  }

  const initSubtasks = (task) => {
    if (taskType.value !== taskTypes.subtasks) return
    originalSubTasks = (task.item_operation_list || []).map((item) => ({
      id: item.id,
      item_type: item.item_type,
      description: item.description
    }))
    newSubTasks.value = originalSubTasks.map((subTask) => ({ ...subTask }))
  }

  const initFrequncyParams = (operationSchedule) => {
    const preDefinedFrequency = operationFrequencyOptionsForSelect().find((frequency) => frequency.frequencyType === operationSchedule.frequency_type &&
      frequency.frequency === operationSchedule.frequency)

    if (!isObject(preDefinedFrequency)) return

    frequencyModel.value = preDefinedFrequency
    if (frequencyModel.value.frequencyType === FrequencyTypes.ExectDate) {
      frequencyExectDate.value = operationSchedule.operation_next_date_raw
      endDate.value = operationSchedule.end_date
    }
    if (frequencyModel.value.frequencyType === FrequencyTypes.EveryYear) {
      frequencyYearDate.value[0] = operationSchedule.operation_next_date_raw
    }
  }

  const initUpdateOperation = ({ operation, shiftPartName, copyMode }) => {
    const operationSchedule = newOperationScheduleModel(operation)
    if (copyMode !== true) {
      operationScheduleId.value = operationSchedule.id
    }
    requireImage.value = operationSchedule.require_image
    newOperation.value = operationSchedule.getItemOperation()
    startTime.value = operationSchedule.start_time
    reportTag.value = operationSchedule.report_tag
    initFrequncyParams(operationSchedule)

    if (isFrequencyTypeEveryXWeek.value) {
      const dayName = dayNameFromISO8601String(operation.first_scheduled_date)
      const activeDay = activeDays.value.find((day) => day.name === dayName)
      if (isObject(activeDay)) activeDay.isActive = true
    }
    if (isString(shiftPartName)) {
      shiftPart.value = { name: shiftPartName }
    }
    if (Number.isInteger(operationSchedule.related_item_id)) {
      relatedItem.value = { id: operationSchedule.related_item_id }
    }
    return operationSchedule
  }

  const createOrUpdateItemOperationParams = ({ uploadImageCount, attachedFileName }) => {
    const itemOperation = newOperation.value.serialize()
    const requestParams = itemOperation
    if (Number.isInteger(uploadImageCount) && uploadImageCount > 0) {
      requestParams.with_images = uploadImageCount
    }
    if (isString(attachedFileName) === true) {
      requestParams.with_file = attachedFileName
    }
    return requestParams
  }

  const baseUpdateAndCreateParams = () => {
    const operationScheduleParams = operationFrequencyParams()
    operationScheduleParams.require_image = requireImage.value
    operationScheduleParams.start_time = startTime.value
    operationScheduleParams.end_date = endDate.value
    if (isObject(relatedItem.value) && Number.isInteger(relatedItem.value.id)) {
      operationScheduleParams.related_item_id = relatedItem.value.id
    }
    if (isString(reportTag.value)) {
      operationScheduleParams.report_tag = reportTag.value
    }
    if (isFrequencyTypeExectDate.value) return operationScheduleParams
    operationScheduleParams.shift_part = shiftPart.value.name
    return operationScheduleParams
  }

  const createOperationScheduleParams = () => {
    const operationScheduleParams = baseUpdateAndCreateParams()
    if (isFrequencyTypeExectDate.value) return operationScheduleParams

    if (isFrequencyTypeEveryDay.value) return operationScheduleParams
    if (isFrequencyTypeEveryYear.value === true) {
      operationScheduleParams.dates = frequencyYearDate.value
    } else {
      operationScheduleParams.days = activeDays.value.filter((dayOfWork) => dayOfWork.isActive === true)
        .map((dayOfWork) => dayOfWork.name)
    }
    return operationScheduleParams
  }

  const updateOperationScheduleParams = () => {
    const operationScheduleParams = baseUpdateAndCreateParams()
    if (Number.isInteger(originalJobId) && originalJobId !== currentJobSearchResult.value.id) {
      operationScheduleParams.account_job_id = currentJobSearchResult.value.id
    }
    if (isFrequencyTypeExectDate.value || isFrequencyTypeEveryDay.value) return operationScheduleParams

    if (isFrequencyTypeEveryYear.value === true) {
      operationScheduleParams.date = frequencyYearDate.value[0]
    } else {
      operationScheduleParams.day = activeDays.value.filter((dayOfWork) => dayOfWork.isActive === true)
        .map((dayOfWork) => dayOfWork.name)[0]
    }
    return operationScheduleParams
  }

  const update = ({ uploadImageCount, attachedFileName }) => {
    const operationScheduleParams = updateOperationScheduleParams()
    const itemOperationParams = createOrUpdateItemOperationParams({ uploadImageCount, attachedFileName })
    delete itemOperationParams.id
    const requestParams = Object.assign(operationScheduleParams, itemOperationParams)
    return jobOpScheduleUpdateWithItem(currentApiKey, originalJobId, operationScheduleId.value, requestParams)
  }

  const create = ({ uploadImageCount, attachedFileName }) => {
    const operationScheduleParams = createOperationScheduleParams()
    const itemOperationParams = createOrUpdateItemOperationParams({ uploadImageCount, attachedFileName })
    const requestParams = Object.assign(operationScheduleParams, itemOperationParams, notificationParams())
    return jobOpScheduleCreateWithItem(currentApiKey, currentJobSearchResult.value.id, requestParams)
  }

  const notificationParams = () => {
    const notificationsVal = taskNotifications.value
    const result = {}
    if (!notificationsVal.taskComplete && !notificationsVal.taskUncomplete) return result
    if (notificationsVal.taskComplete) result.task_complete = true
    if (operationHasStartTime.value && notificationsVal.taskUncomplete === true) result.task_uncomplete = true
    return { notifications: result }
  }
  // Methods
  const clearColor = () => newOperation.value?.clearColor && newOperation.value.clearColor()
  const resetDayOfWork = () => activeDays.value.splice(0, activeDays.value.length)
  const setSubTaskOrderChanged = (value) => (subTaskOrderChanged = value)

  const reset = () => {
    newOperation.value = newDeviceOperationModel()
    requireImage.value = false
    currentJobSearchResult.value = null
    originalJobId = null
    originalSubTasks = []
    subTaskOrderChanged = false
    newSubTasks.value = []
    frequencyYearDate.value.splice(0, frequencyYearDate.value.length)
    frequencyModel.value = { frequency: null, frequencyType: null }
    operationScheduleId.value = null
    relatedItem.value = {}
    startTime.value = ''
    reportTag.value = ''
    endDate.value = ''
    taskType.value = taskTypes.regular
    taskNotifications.value = defaultTaskNotifications()
    resetDayOfWork()
  }

  const changeApiKey = (apiKey) => (currentApiKey = apiKey)

  const setOperation = ({ operation, jobId, shiftPartName, copyMode = false }) => {
    isLoading.value = true
    reset()
    originalJobId = jobId
    currentJobSearchResult.value = ({ id: jobId })
    initTaskType(operation)
    initSubtasks(operation)
    return onJobSelect()
      .then(() => initUpdateOperation({ operation, jobId, shiftPartName, copyMode }))
      .finally(() => (isLoading.value = false))
  }

  const assignDaysOfWork = (jobDaysOfWork = []) => {
    resetDayOfWork()
    jobDaysOfWork.forEach((dayOfWork) => {
      const day = Object.assign({}, dayOfTheWeekObj.value[dayOfWork], { isActive: false })
      activeDays.value.push(day)
    })
  }

  const toggleActiveDay = ({ index, withReset = false }) => {
    const dayOfWork = activeDays.value[index]
    dayOfWork.isActive = !dayOfWork.isActive
    if (withReset !== true) return
    activeDays.value.forEach((day, dayIndex) => {
      if (dayIndex !== index) day.isActive = false
    })
  }

  const updateWorkingDays = (workingDaysName) => {
    activeDays.value.forEach((day) => (day.isActive = false))
    workingDaysName.forEach((dayName) => {
      const day = activeDays.value.find((day) => day.name === dayName)
      if (isObject(day)) day.isActive = true
    })
  }

  const onJobSelect = async () => {
    if (isJobSelected.value !== true) {
      resetDayOfWork()
      return
    }

    const currentJobId = currentJobSearchResult.value.id
    return getJobWorkingDays(currentApiKey, currentJobId)
      .then((jobDaysOfWork) => assignDaysOfWork(jobDaysOfWork))
      .catch(errHandler)
  }

  const operationFrequencyParams = () => {
    const frequencyParams = frequencyModel.value
    const frequencyParamsResult = {
      frequency_type: frequencyParams.frequencyType || null,
      frequency: frequencyParams.frequency || null
    }
    if (isFrequencyTypeExectDate.value) {
      frequencyParamsResult.frequency = frequencyExectDate.value
    }
    return frequencyParamsResult
  }

  const updateSubTasksParams = () => {
    const deleteParams = []
    const updateParams = []
    const createParams = []
    const result = { deleteParams, updateParams, createParams }

    if (taskType.value !== taskTypes.subtasks) {
      originalSubTasks.forEach((subTask) => deleteParams.push(subTask.id))
      return result
    }

    newSubTasks.value.forEach((subTask) => {
      if (Number.isInteger(subTask.id)) {
        const originalSubTask = originalSubTasks.find((row) => row.id === subTask.id) || {}
        const isSubTaskChanged = originalSubTask.description !== subTask.description || originalSubTask.item_type !== subTask.item_type
        if (isSubTaskChanged) {
          updateParams.push(subTask)
        }
      } else {
        createParams.push(subTask)
      }
    })

    const newSubTasksIdsSet = new Set()
    newSubTasks.value.forEach((subTask) => {
      if (Number.isInteger(subTask.id)) {
        newSubTasksIdsSet.add(subTask.id)
      }
    })
    originalSubTasks.forEach((subTask) => {
      if (!newSubTasksIdsSet.has(subTask.id)) {
        deleteParams.push(subTask.id)
      }
    })
    return result
  }

  const updateSubTasks = async (operationId) => {
    const { deleteParams, updateParams, createParams } = updateSubTasksParams()
    if (createParams.length === 0 && updateParams.length === 0 && deleteParams.length === 0) return

    return itemOperationListItemClientBatchUpsert(currentApiKey, generalItemId, operationId, {
      create_params: createParams,
      update_params: updateParams,
      delete_params: deleteParams
    })
  }

  const updateSubtaskOrder = (newItemListIds, operationId) => {
    if (Array.isArray(newItemListIds) && newItemListIds.length > 0) {
      newSubTasks.value.filter((item) => !Number.isInteger(item.id))
        .forEach((item, index) => (item.id = newItemListIds[index]))
    }
    const updateOrderParams = { ids_order: newSubTasks.value.map((item) => item.id) }

    return itemOperationListItemClientUpdateItemsOrder(currentApiKey, generalItemId, operationId, updateOrderParams)
  }

  const updateOrCreateSubTasks = async (operationId) => {
    if (isUpdateMode.value !== true) {
      if (newSubTasks.value.length === 0) return
      return itemOperationListItemClientBatchUpsert(currentApiKey, generalItemId, operationId, { create_params: newSubTasks.value })
    }

    const response = await updateSubTasks(operationId)

    if (!subTaskOrderChanged) return

    const newItemListIds = response?.data?.created_items
    updateSubtaskOrder(newItemListIds, operationId)
  }

  const saveOperationImages = async ({ imageUploadData, saveImageCall }) => {
    imageUploadData.itemId = generalItemId
    imageUploadData.apiKey = currentApiKey
    return saveImageCall(imageUploadData)
  }

  const saveOperationFile = async ({ fileUploadData, saveFileCall }) => {
    if (saveFileCall === null) return

    fileUploadData.itemId = generalItemId
    fileUploadData.apiKey = currentApiKey
    return saveFileCall(fileUploadData)
  }

  const saveAdditionalFiles = async ({ imageUploadData, saveImageCall, fileUploadData, saveFileCall }) => {
    return Promise.all([
      saveOperationImages({ imageUploadData, saveImageCall }),
      saveOperationFile({ fileUploadData, saveFileCall })
    ])
  }

  const save = async ({ uploadImageCount, saveImageCall, attachedFileName = null, saveFileCall = null }) => {
    isLoading.value = true
    const imageUploadData = {}
    const fileUploadData = {}
    const promise = isUpdateMode.value
      ? update({ uploadImageCount, attachedFileName })
      : create({ uploadImageCount, attachedFileName })
    return promise
      .then((response) => {
        const itemOperationId = isUpdateMode.value ? newOperation.value.id : response.data.data.id
        imageUploadData.itemOperationId = itemOperationId
        imageUploadData.imageUploadSignatures = response.data.image_upload_signature
        fileUploadData.itemOperationId = itemOperationId
        fileUploadData.fileUploadSignature = response.data.file_upload_signature

        return updateOrCreateSubTasks(itemOperationId)
      })
      .then(() => saveAdditionalFiles({ imageUploadData, saveImageCall, fileUploadData, saveFileCall }))
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  // Computed
  const isOperationValid = computed(() => (newOperation.value.operation_type || '').length > 0)
  const isUpdateMode = computed(() => Number.isInteger(operationScheduleId.value) && operationScheduleId.value > 0)
  const shiftPartDisabled = computed(() => isFrequencyTypeExectDate.value || isFrequencyTypeEveryYear.value)
  const operationHasStartTime = computed(() => isString(startTime.value) && startTime.value.length > 0)
  const isNewSubtasksInvalid = computed(() => newSubTasks.value.length === 0 || newSubTasks.value.some((subTask) => !isString(subTask.description) || (isString(subTask.description) && subTask.description.trim().length === 0)))
  const isScheduleValid = computed(() => {
    if (isFrequencyTypeEveryDay.value === true) return true
    if (isFrequencyTypeExectDate.value === true) {
      return isValidISO8601Date(frequencyExectDate.value)
    }
    if (isFrequencyTypeEveryYear.value === true) {
      if (frequencyYearDate.value.length === 0) return false
      return frequencyYearDate.value.every((date) => isValidISO8601Date(date))
    }
    return Object.values(activeDays.value).some((activeDay) => activeDay.isActive === true)
  })

  const isFrequencyTypeExectDate = computed(() => {
    if (!isObject(frequencyModel.value)) return false
    return frequencyModel.value.frequencyType === FrequencyTypes.ExectDate
  })

  const isFrequencyTypeEveryYear = computed(() => {
    if (!isObject(frequencyModel.value)) return false
    return frequencyModel.value.frequencyType === FrequencyTypes.EveryYear
  })

  const isFrequencyTypeEveryDay = computed(() => {
    if (!isObject(frequencyModel.value)) return false
    return frequencyModel.value.frequencyType === FrequencyTypes.EveryDay
  })

  const isFrequencyTypeEveryXWeek = computed(() =>
    isObject(frequencyModel.value) && frequencyModel.value.frequencyType === FrequencyTypes.EveryWeek)

  const isJobSelected = computed(() => {
    const jobId = (currentJobSearchResult.value || {}).id
    return Number.isInteger(jobId)
  })

  const isOperationDesctiptionValid = computed(() => {
    const description = newOperation.value?.description
    if (!isString(description)) return true
    return description.trim().length <= 240
  })

  const saveDisabled = computed(() => {
    if (isLoading.value === true) return true
    if (isJobSelected.value !== true) return true
    if (isOperationValid.value !== true) return true
    if (isScheduleValid.value !== true) return true
    if (isOperationDesctiptionValid.value !== true) return true
    if (taskType.value === taskTypes.subtasks && isNewSubtasksInvalid.value === true) return true
    return false
  })

  return {
    newOperation,
    isLoading,
    currentJobSearchResult,
    activeDays,
    shiftPart,
    startTime,
    endDate,
    reportTag,
    newSubTasks,
    relatedItem,
    frequencyExectDate,
    frequencyYearDate,
    frequencyModel,
    shiftPartDisabled,
    isFrequencyTypeExectDate,
    requireImage,
    saveDisabled,
    taskNotifications,
    operationHasStartTime,
    isFrequencyTypeEveryXWeek,
    isJobSelected,
    taskType,
    taskTypes,
    onJobSelect,
    save,
    reset,
    toggleActiveDay,
    clearColor,
    setOperation,
    changeApiKey,
    updateWorkingDays,
    setSubTaskOrderChanged
  }
}
