<template>
  <v-row
    align="start" justify="start" no-gutters
    class="single-hq-task pa-0"
    :style="{ 'background-color': isExpanded ? operationColor : 'white' }"
  >
    <v-col class="flex-grow-0 flex-shrink-1 align-self-stretch px-3 py-2 ppe-2">
      <div class="color-line" :style="{ 'background-color': isExpanded ? 'inherit' : operationColor }">
        &nbsp;
      </div>
    </v-col>
    <v-col class="pointer flex-grow-1 flex-shrink-0 px-3 pt-1" @click="isExpanded = !isExpanded">
      <div class="color5 t-700-16 d-flex align-center">
        {{ task.operation_description }}
        <v-chip
          v-if="showReportTag" outlined class="pps-0 mms-2"
        >
          <v-avatar
            class="mme-2"
            :color="reportTagColor"
            size="24"
          >
            <v-icon small color="white" class="material-icons-outlined">
              sell
            </v-icon>
          </v-avatar>
          <span class="color20 t-400-14">{{ reportTagName }}</span>
        </v-chip>
      </div>
      <div class="d-flex align-center my-1">
        <v-icon color="#212121">
          schedule
        </v-icon>
        <span class="pps-1 color5 t-400-14">
          {{ frequencyText }}
        </span>
        <span v-if="showStartTime" class="color1 t-700-13 pps-2">
          {{ task.start_time }}
        </span>
        <span class="pps-1 color5 t-400-14">
          ({{ jobShiftPart }})
        </span>
      </div>
      <div v-if="showCreator" class="my-1 t-400-14 color20">
        <v-icon color="#111111" class="material-icons-outlined">
          person
        </v-icon>
        {{ $t('from') }} {{ task.creator_data.first_name }} {{ task.creator_data.last_name }}
      </div>
      <div v-if="showEveryXWeeksStartPeriod" class="my-1">
        <v-icon color="#F5409B">
          calendar_today
        </v-icon>
        <span class="pps-1 color7 t-400-12">
          {{ startPeriodForEveryXWeeksFrequnecy }}
        </span>
      </div>
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1 ppe-3 align-self-stretch d-flex flex-column justify-center">
      <div class="d-flex align-center justify-center">
        <v-icon
          v-if="showGuidanceImages"
          color="#0000008a"
          class="pointer material-icons-outlined mme-2"
          @click="onGuidanceImageClick"
        >
          image
        </v-icon>
        <v-icon
          v-if="showGuidanceFile"
          class="mme-2 pointer"
          color="#0000008a"
          @click="onGuidanceFileClick"
        >
          attach_file
        </v-icon>
        <div class="t-700-16 d-inline-block text-no-wrap">
          <span class="color1">{{ task.total_tasks_reported }}</span> / <span class="color20">{{ task.total_tasks }}</span>
        </div>
        <span class="ppe-4">
          <v-icon class="expand-icon" :class="{ rotated: isExpanded }">
            keyboard_arrow_down
          </v-icon>
        </span>
        <single-cross-account-task-options
          v-if="showTaskOptions"
          @edit-click="onEdit" @copy-click="onCopy"
          @delete-click="onDelete"
        />
      </div>
    </v-col>
    <v-expand-transition>
      <v-col v-if="isExpanded" cols="12" class="mt-2">
        <single-cross-account-task-list
          :api-key="apiKey"
          :cross-account-operation-id="task.cross_account_operation_id"
        />
      </v-col>
    </v-expand-transition>
  </v-row>
</template>
<script>
import SingleCrossAccountTaskOptions from '@/components/hq_tasks/hq_tasks_cross_account/SingleCrossAccountTaskOptions.vue'
import SingleCrossAccountTaskList from '@/components/hq_tasks/hq_tasks_cross_account/SingleCrossAccountTaskList.vue'

import useAccount from '@/composables/useAccount.js'

import { defaultColor, newDefaultColor } from '@/store/modules/DeviceOperation.js'
import { isHexColor, isString, isObject } from '@/helpers/Utils.js'
import { tagColors, translateReportTag, translateShiftPartName } from '@/store/modules/OperationSchedule.js'
import { frequencyTextFromTask, FrequencyTypes, calculateCurrentStartPeriodForEveryXWeeksFrequency } from '@/store/modules/OperationFrequency.js'
import { ISO8601StringToDate, getClientDateFormatted, translateDayFromDate } from '@/helpers/DateTime.js'

import { EventBus, TASK_EDIT_CLICK, TASK_COPY_CLICK, TASK_DELETE_CLICK, TASK_GUIDANCE_IMAGE_CLICK } from '@/EventBus.js'

import { i18n } from '@/i18n.js'

import { ref, computed } from 'vue'
export default {
  components: {
    'single-cross-account-task-list': SingleCrossAccountTaskList,
    'single-cross-account-task-options': SingleCrossAccountTaskOptions
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    clientDate: {
      type: Date,
      required: true
    }
  },
  setup (props) {
    const isExpanded = ref(false)
    const { accountCountryCode } = useAccount()

    // Methods
    const onEdit = () => EventBus.emit(TASK_EDIT_CLICK, props.task)
    const onCopy = () => EventBus.emit(TASK_COPY_CLICK, props.task)
    const onDelete = () => EventBus.emit(TASK_DELETE_CLICK, props.task)
    const onGuidanceImageClick = () => EventBus.emit(TASK_GUIDANCE_IMAGE_CLICK, props.task)
    const onGuidanceFileClick = () => window.open(props.task.guidance_file, '_blank').focus()

    // Computed
    const showReportTag = computed(() => isString(props.task.report_tag))
    const reportTagColor = computed(() => tagColors[props.task.report_tag])
    const reportTagName = computed(() => translateReportTag(props.task.report_tag))
    const showStartTime = computed(() => isString(props.task.start_time))
    const showCreator = computed(() => isObject(props.task.creator_data))
    const jobShiftPart = computed(() => translateShiftPartName(props.task.shift_part))
    const showTaskOptions = computed(() => !props.task.hideOptions)

    const showGuidanceImages = computed(() => {
      const guidanceImages = props.task.guidance_images
      return Array.isArray(guidanceImages) && guidanceImages.length > 0
    })
    const showGuidanceFile = computed(() => {
      const guidanceFileUrl = props.task.guidance_file
      return isString(guidanceFileUrl) && guidanceFileUrl.length > 0
    })
    const operationColor = computed(() => {
      const color = props.task.operation_color
      if (color === defaultColor) return newDefaultColor

      if (isHexColor(color)) return color
      return newDefaultColor
    })

    const frequencyText = computed(() => {
      const task = props.task
      return frequencyTextFromTask({
        task,
        dateString: task.first_scheduled_date
      })
    })

    const showEveryXWeeksStartPeriod = computed(() => {
      const task = props.task
      if (task.frequency_type !== FrequencyTypes.EveryXWeek) return false
      const firstScheudled = ISO8601StringToDate(task.first_scheduled_date)
      const startPeriod = calculateCurrentStartPeriodForEveryXWeeksFrequency(task.frequency, firstScheudled, props.clientDate)
      return startPeriod.getTime() !== props.clientDate.getTime()
    })

    const startPeriodForEveryXWeeksFrequnecy = computed(() => {
      const task = props.task
      const firstScheudled = ISO8601StringToDate(task.first_scheduled_date)
      const startPeriod = calculateCurrentStartPeriodForEveryXWeeksFrequency(task.frequency, firstScheudled, props.clientDate)

      return i18n.t('operation_frequency.week_x_frequency_start_period', {
        date: getClientDateFormatted({ fromDate: startPeriod, countryCode: accountCountryCode.value }),
        day: translateDayFromDate(startPeriod)
      })
    })
    return {
      isExpanded,
      showReportTag,
      showCreator,
      jobShiftPart,
      showStartTime,
      showGuidanceImages,
      showGuidanceFile,
      operationColor,
      reportTagColor,
      reportTagName,
      frequencyText,
      showEveryXWeeksStartPeriod,
      startPeriodForEveryXWeeksFrequnecy,
      showTaskOptions,
      onEdit,
      onCopy,
      onDelete,
      onGuidanceImageClick,
      onGuidanceFileClick
    }
  }
}
</script>
<style lang="scss">
.single-hq-task {
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  padding: 8px 12px;
  .color-line {
    height: 100%;
    width: 6px;
    border-radius: 4px;
  }
  .expand-icon {
    transition: transform 0.3s ease;
    &.rotated {
      transform: rotate(180deg);
    }
  }
  .flex-1 {
    flex: 1;
  }
}
</style>
