<template>
  <v-dialog
    v-model="dialog" persistent :fullscreen="$vuetify.breakpoint.xsOnly"
    width="540"
    content-class="rounded-xl"
    scrollable
  >
    <task-subtasks-list ref="taskSubTaskListDialogRef" :api-key="apiKey" />
    <v-card
      class="bg-color16 new-task-dialog"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="white px-1">
        <v-row align="center" justify="start">
          <v-col cols="2" class="texts-start">
            <v-btn
              :disabled="isLoading"
              icon
              color="black"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center color5 t-600-18">
            {{ dialogTitle }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="scrollable px-2">
        <v-form ref="taskFormRef">
          <v-row
            align="start" justify="center"
            class="overflow-x-hidden"
          >
            <v-col cols="12" class="mt-4 pb-0">
              <v-textarea
                v-model="newOperation.description"
                background-color="white"
                class="description-field"
                outlined
                auto-grow
                rows="4"
                counter="240"
                :label="$t('schedule_new_op_dialog.description')"
              />
            </v-col>
            <v-col cols="12">
              <job-selection-search
                v-if="dialog"
                v-model="currentJobSearchResult"
                class="t-400-16 color2"
                outlined
                :single-line="false"
                :clearable="false"
                hide-details
                outer-class="required"
                background-color="white"
                :api-key="apiKey"
                :hide-shift="isStaffAccount"
                :label="$t('schedule_new_op_dialog.choose_job')"
                :menu-props="{ 'contentClass': isRtl ? 'rtl' : 'ltr' }"
                :search-for-operation="searchForOperation"
                @input="onJobSelect"
              />
            </v-col>
            <v-col cols="12">
              <task-frequency-field
                :frequency-model.sync="frequencyModel"
                :frequency-exect-date.sync="frequencyExectDate"
                :frequency-every-year.sync="frequencyYearDate"
                :end-date.sync="endDate"
                :disabled="!isJobSelected"
                :job-active-days="activeDays"
                :single-every-year-date="isUpdateMode"
                @update-working-days="updateWorkingDays"
              />
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-btn
                text color="#0040FF" class="px-0"
                @click="toggleMoreDetails"
              >
                <v-icon>{{ moreDetailsOpen ? 'expand_less' : 'expand_more' }}</v-icon>
                {{ $t('show_more_details') }}
              </v-btn>
              <v-expand-transition>
                <v-row
                  v-show="moreDetailsOpen" align="start" justify="center"
                  no-gutters
                >
                  <v-col cols="12" class="mt-2">
                    <task-type-field v-model="taskType" @change="onChangeTaskType" />
                  </v-col>
                  <v-expand-transition>
                    <v-col v-if="showCheckList" cols="12" class="mt-3">
                      <task-subtasks-field
                        :subtasks-count="subtasksCount"
                        @click="openSubTaskDialog"
                      />
                    </v-col>
                  </v-expand-transition>
                  <v-col v-if="!isStaffAccount" cols="12" class="mt-4">
                    <task-shift-part-field v-model="shiftPart" :disabled="shiftPartDisabled" />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <time-picker-dialog
                      v-model="startTime"
                      background-color="white"
                      outlined
                      hide-details
                    />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <task-color-select
                      v-model="newOperation.color"
                      @click:clear="clearColor"
                    />
                  </v-col>
                  <v-col v-if="!isUpdateMode" cols="12" class="mt-4">
                    <task-notification-field
                      :task-un-complete-disabled="!operationHasStartTime"
                      @change="taskNotificationChange"
                    />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <task-report-tag-field v-model="reportTag" />
                  </v-col>
                  <v-col v-if="!isStaffAccount" cols="12" class="mt-4">
                    <item-search
                      v-model="relatedItem"
                      :place-holder="$t('schedule_new_op_dialog.related_item')"
                      :api-key="apiKey"
                      show-add-item
                      show-delete-button
                      show-edit-button
                      :new-item-place-holder="$t('schedule_new_op_dialog.new_item')"
                      load-all
                      outlined
                      hide-details
                      background-color="white"
                    />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <span class="t-700-16">{{ $t('schedule_new_op_dialog.guildance_images') }}</span>
                    &nbsp;<span lass="t-400-16">{{ $t('schedule_new_op_dialog.max_photo') }}</span>
                  </v-col>
                  <v-col cols="12" class="mt-2">
                    <task-guidence-images
                      ref="taskGuidenceImagesRef"
                      record-type="ItemOperation"
                    />
                  </v-col>

                  <v-col cols="12" class="mt-4">
                    <file-uploader
                      ref="taskFileRef"
                      class="file-input color5 t-600-18"
                      underline
                      color="transparent"
                      record-type="ItemOperation"
                    />
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :loading="isLoading" :disabled="saveDisabled"
              @click="saveClick"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import JobSelectionSearch from '@/components/job_selection_search/JobSelectionSearch.vue'
import TimePickerDialog from '@/components/shared/TimePickerDialog.vue'
import TaskFrequencyField from '@/components/new_task_dialog/task_frequency/TaskFrequencyField.vue'
import TaskColorSelect from '@/components/new_task_dialog/TaskColorSelect.vue'
import TaskNotificationField from '@/components/new_task_dialog/TaskNotificationField.vue'
import FileUploader from '@/components/shared/FileUploader.vue'
import ItemSearch from '@/components/shared/item_search/ItemSearch.vue'
import TaskReportTagField from '@/components/new_task_dialog/TaskReportTagField.vue'
import TaskShiftPartField from '@/components/new_task_dialog/TaskShiftPartField.vue'
import TaskGuidenceImages from '@/components/new_task_dialog/TaskGuidenceImages.vue'
import TaskSubtasksList from '@/components/new_task_dialog/task_subtasks_list/TaskSubtasksList.vue'
import TaskSubtasksField from '@/components/new_task_dialog/TaskSubtasksField.vue'
import TaskTypeField from '@/components/new_task_dialog/TaskTypeField.vue'

import useNewOperation from '@/composables/useNewOperation.js'
import useAuth from '@/composables/useAuth.js'

import { isString } from '@/helpers/Utils.js'
import { shiftPartsForSelect as OpModuleShiftParts } from '@/store/modules/OperationSchedule.js'

import { EventBus, NEW_ACCOUNT_OPERATION } from '@/EventBus.js'

import { ref, computed, nextTick } from 'vue'

import { isRtl, i18n } from '@/i18n.js'
const DialogMode = { create: 1, update: 2, copy: 3 }

export default {
  components: {
    'job-selection-search': JobSelectionSearch,
    'file-uploader': FileUploader,
    'time-picker-dialog': TimePickerDialog,
    'item-search': ItemSearch,
    'task-frequency-field': TaskFrequencyField,
    'task-color-select': TaskColorSelect,
    'task-notification-field': TaskNotificationField,
    'task-report-tag-field': TaskReportTagField,
    'task-shift-part-field': TaskShiftPartField,
    'task-guidence-images': TaskGuidenceImages,
    'task-subtasks-list': TaskSubtasksList,
    'task-subtasks-field': TaskSubtasksField,
    'task-type-field': TaskTypeField

  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    isStaffAccount: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { isUserAccountManager } = useAuth()
    const dialog = ref(false)
    const shiftParts = ref(OpModuleShiftParts())
    const preExistingListItems = ref(false)
    const dialogMode = ref(DialogMode.create)
    const taskGuidenceImagesRef = ref(null)
    const taskFileRef = ref(null)
    const taskSubTaskListDialogRef = ref(null)
    const taskFormRef = ref(null)
    const moreDetailsOpen = ref(false)

    let operationSchedule = null
    let originalJobId = null
    const searchForOperation = ref(false)
    const {
      isLoading, newOperation, requireImage, shiftPart, startTime, endDate, frequencyModel,
      currentJobSearchResult, saveDisabled, activeDays, frequencyExectDate, frequencyYearDate,
      shiftPartDisabled, newSubTasks, relatedItem, reportTag, taskNotifications,
      operationHasStartTime, isJobSelected, taskType, taskTypes,
      onJobSelect, setOperation, updateWorkingDays,
      clearColor, save, reset, changeApiKey, setSubTaskOrderChanged
    } = useNewOperation(props.apiKey)

    // Methods
    const toggleMoreDetails = () => (moreDetailsOpen.value = !moreDetailsOpen.value)

    const onChangeTaskType = (value) => {
      requireImage.value = value === taskTypes.image
    }

    const openDialog = () => {
      dialog.value = true
      dialogMode.value = DialogMode.create
      operationSchedule = null
      reset()
      shiftPart.value = shiftParts.value.find((shiftPart) => shiftPart.name === 'start')
      if (isUserAccountManager.value === true || props.isStaffAccount === true) {
        searchForOperation.value = true
      }
    }

    const openForUpdate = async ({ operation }) => {
      dialog.value = true
      dialogMode.value = DialogMode.update
      originalJobId = operation.account_job_id
      if (props.isStaffAccount === true) {
        searchForOperation.value = true
      }
      if (isString(operation.account_api_key)) {
        changeApiKey(operation.account_api_key)
      }
      operationSchedule = await setOperation({
        operation,
        jobId: operation.account_job_id,
        shiftPartName: operation.shift_part
      })
      nextTick(() => {
        taskGuidenceImagesRef.value.setImages(operationSchedule.guidance_images)
        taskFileRef.value.setFile(operationSchedule.guidance_file)
      })
    }

    const openForCopy = async ({ operation }) => {
      dialog.value = true
      dialogMode.value = DialogMode.copy
      if (props.isStaffAccount === true) {
        searchForOperation.value = true
      }
      operationSchedule = await setOperation({
        operation,
        jobId: operation.account_job_id,
        shiftPartName: operation.shift_part,
        copyMode: true
      })
    }

    const taskNotificationChange = ({ taskComplete, taskUncomplete } = {}) => {
      taskNotifications.value.taskComplete = taskComplete
      taskNotifications.value.taskUncomplete = taskUncomplete
    }
    const openSubTaskDialog = () => {
      const oldList = JSON.parse(JSON.stringify(newSubTasks.value))
      taskSubTaskListDialogRef.value.openDialog(oldList)
        .then(({ isSaved, newList, orderChanged }) => {
          if (!isSaved) return

          newSubTasks.value = []
          const newSubTasksVal = newSubTasks.value
          newList.forEach((item) => newSubTasksVal.push({ ...item }))
          setSubTaskOrderChanged(orderChanged)
        })
    }

    const saveClick = () => {
      const uploadImageCount = taskGuidenceImagesRef.value.uploadImageCount()
      const saveImageCall = taskGuidenceImagesRef.value.saveImages
      const saveFileCall = taskFileRef.value.saveFile
      const attachedFileName = taskFileRef.value.attachedFileName()
      save({ uploadImageCount, saveImageCall, attachedFileName, saveFileCall })
        .then(() => {
          const jobId = Number.isInteger(originalJobId) ? originalJobId : currentJobSearchResult.value.id
          EventBus.emit(NEW_ACCOUNT_OPERATION, { jobId })
          close()
        })
    }

    const close = () => {
      dialog.value = false
      reset()
      preExistingListItems.value = false
      operationSchedule = null
      originalJobId = null
      moreDetailsOpen.value = false
      taskFormRef.value.reset()
      taskGuidenceImagesRef.value.reset()
      taskFileRef.value.reset()
      searchForOperation.value = false
    }

    // Computed
    const isUpdateMode = computed(() => dialogMode.value === DialogMode.update)
    const showCheckList = computed(() => taskType.value === taskTypes.subtasks)
    const subtasksCount = computed(() => newSubTasks.value.length)
    const dialogTitle = computed(() => {
      if (dialogMode.value === DialogMode.update) {
        return i18n.t('schedule_new_op_dialog.update_title')
      } else if (dialogMode.value === DialogMode.copy) {
        return i18n.t('schedule_new_op_dialog.copy_title')
      }
      return i18n.t('schedule_new_op_dialog.title')
    })

    return {
      isLoading,
      newOperation,
      searchForOperation,
      currentJobSearchResult,
      saveDisabled,
      taskType,
      shiftPart,
      startTime,
      endDate,
      shiftPartDisabled,
      showCheckList,
      frequencyExectDate,
      frequencyYearDate,
      activeDays,
      frequencyModel,
      dialog,
      isUpdateMode,
      taskGuidenceImagesRef,
      taskFileRef,
      moreDetailsOpen,
      taskSubTaskListDialogRef,
      taskFormRef,
      relatedItem,
      reportTag,
      isRtl,
      subtasksCount,
      dialogTitle,
      operationHasStartTime,
      isJobSelected,
      openDialog,
      clearColor,
      openSubTaskDialog,
      onJobSelect,
      updateWorkingDays,
      saveClick,
      close,
      openForUpdate,
      openForCopy,
      toggleMoreDetails,
      taskNotificationChange,
      onChangeTaskType
    }
  }
}
</script>
<style lang="scss">
.new-task-dialog {
  overflow-y: hidden;
  .v-text-field--outlined fieldset  {
    border-color: #E8E8E8 ;
  }
  .save-btn.v-btn--disabled {
    color: white !important
  }
  .description-field {
    .v-label {
      padding-right: 10px;
    }
  }
  .scrollable {
    overflow: auto;
    height: 100%;
    max-height: 80vh;
  }
  .file-input {
    .file-chip {
      padding-right: 0;
    }
    .v-chip__content {
      span {
        color: #212121;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
}
</style>
