<template>
  <v-dialog
    v-model="dialog" persistent
    width="540" scrollable
    content-class="rounded-xl"
  >
    <v-card
      class="bg-color16 task-order-dialog"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="white px-1">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="2" class="texts-start">
            <v-btn
              icon
              color="black"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center color5">
            <div class="t-700-18">
              {{ dialogTitle }}
            </div>
            <div classs="t-400-18">
              <span v-if="showJobDepartment">{{ jobDepartment }} <v-divider vertical class="job-divider mx-1" /></span> {{ dialogSubTitle }}
            </div>
          </v-col>
          <v-col v-if="showBackButton" cols="2" class="texts-end ">
            <v-btn
              text
              color="#007AFF"
              @click="onBackClick"
            >
              <span class="t-400-17">{{ $t('back') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="scrollable mt-6">
        <transition :name="anumationDirection" mode="out-in" appear>
          <shift-part-select-content
            v-if="showShiftPartSelect"
            :api-key="apiKey"
            @shift-part-selected="onShiftPartSelected"
          />
          <tasks-list-content
            v-else :api-key="apiKey"
            :account-job-id="accountJobId"
            :shift-part-name="selectedShiftPart"
          />
        </transition>
      </v-card-text>
      <v-card-actions v-if="!showShiftPartSelect">
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :disabled="saveDisabled"
              @click="saveClick"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import ShiftPartSelectContent from '@/components/shared/task_order_dialog/ShiftPartSelectContent.vue'
import TasksListContent from '@/components/shared/task_order_dialog/TasksListContent.vue'

import { translateShiftPartName } from '@/store/modules/OperationSchedule.js'
import useTaskOrder from '@/composables/useTaskOrder.js'

import { isString } from '@/helpers/Utils.js'
import { isRtl, i18n } from '@/i18n'

import { computed, ref } from 'vue'

const defaultStaffShiftPart = 'start'

export default {
  components: {
    'shift-part-select-content': ShiftPartSelectContent,
    'tasks-list-content': TasksListContent
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const dialog = ref(false)
    const selectedShiftPart = ref(null)
    const accountJobId = ref(null)
    const jobName = ref(null)
    const jobShift = ref(null)
    const jobDepartment = ref(null)
    const isStaffTask = ref(false)
    let resolveFunc = null

    const {
      isLoading, isLoadingNextPage, originalOrder, taskList,
      updateTasksOrder, resetValues, loadTaskCountPerShiftPart
    } = useTaskOrder(props.apiKey)

    // Methods
    const onShiftPartSelected = (shiftPart) => (selectedShiftPart.value = shiftPart)

    const openDialog = ({ jobId, isStaffTask: isStaffTaskVal, jobName: jobNameVal, jobShift: jobShiftVal, jobDepartment: jobDepartmentVal } = {}) => {
      dialog.value = true
      resetValues(true)
      isStaffTask.value = isStaffTaskVal
      accountJobId.value = jobId
      jobName.value = jobNameVal
      jobShift.value = jobShiftVal
      jobDepartment.value = jobDepartmentVal
      if (isStaffTask.value) {
        selectedShiftPart.value = defaultStaffShiftPart
      } else {
        loadTaskCountPerShiftPart(jobId)
      }
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const close = (isChanged) => {
      dialog.value = false
      selectedShiftPart.value = null
      accountJobId.value = null
      jobName.value = null
      jobShift.value = null
      isStaffTask.value = false
      resetValues(true)
      resolveFunc(isChanged === true)
    }

    const saveClick = () => {
      updateTasksOrder({ accountJobId: accountJobId.value, shiftPartName: selectedShiftPart.value })
        .then(() => close(true))
    }

    const onBackClick = () => {
      selectedShiftPart.value = null
      resetValues()
    }
    // Computed
    const showShiftPartSelect = computed(() => selectedShiftPart.value === null)
    const anumationDirection = computed(() => {
      if (isStaffTask.value) return ''
      if (isRtl.value) {
        return showShiftPartSelect.value ? 'slide' : 'slide-back'
      }
      return showShiftPartSelect.value ? 'slide-back' : 'slide'
    })

    const showBackButton = computed(() => !isStaffTask.value && !showShiftPartSelect.value)
    const saveDisabled = computed(() => {
      if (isLoading.value || isLoadingNextPage.value) return true
      const listUnChanged = JSON.stringify(originalOrder.value) === JSON.stringify(taskList.value.map((task) => task.operation_id))
      return listUnChanged
    })

    const dialogTitle = computed(() => {
      if (isStaffTask.value) return ''

      if (showShiftPartSelect.value) {
        return i18n.t('task_order_dialog.shift_title')
      }
      return translateShiftPartName(selectedShiftPart.value)
    })
    const dialogSubTitle = computed(() => {
      if (isStaffTask.value) {
        return jobName.value
      }
      return `${jobName.value} - ${jobShift.value}`
    })

    const showJobDepartment = computed(() => !isStaffTask.value &&
    isString(jobDepartment.value) && jobDepartment.value.length > 0)
    return {
      dialog,
      isRtl,
      showShiftPartSelect,
      accountJobId,
      selectedShiftPart,
      saveDisabled,
      dialogTitle,
      anumationDirection,
      dialogSubTitle,
      jobDepartment,
      showBackButton,
      showJobDepartment,
      onBackClick,
      openDialog,
      onShiftPartSelected,
      close,
      saveClick
    }
  }
}
</script>
<style lang="scss">
.task-order-dialog {
  overflow-y: hidden;
  .scrollable {
    overflow: auto;
    height: 100%;
    max-height: 80vh;
  }
  .job-divider {
    height: 20px;
    width: 1px;
    background-color: #C9C9C9;
  }
  .slide-enter-active,
  .slide-leave-active,
  .slide-back-enter-active,
  .slide-back-leave-active {
    transition: all 0.3s ease;
    width: 100%;
  }

  .slide-enter { transform: translateX(100%); }
  .slide-leave-to { transform: translateX(-100%); }

  .slide-back-enter { transform: translateX(-100%); }
  .slide-back-leave-to { transform: translateX(100%); }
}
</style>
