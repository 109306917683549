<template>
  <span @click="onClick">
    <v-avatar color="indigo" :size="size">
      <img v-if="showUserAvatarImage" :src="userAvatarUrl">
      <span v-else class="white--text text-h5">{{ userInitials }}</span>
    </v-avatar>
    <span v-if="showNameAndAvatar" class="mms-1 t-600-18">
      {{ user?.first_name }} {{ user?.last_name }}
    </span>
  </span>
</template>
<script>
import { computed } from 'vue'

import { isString } from '@/helpers/Utils.js'

export default {
  props: {
    size: {
      type: [Number, String],
      default: 66
    },
    showNameAndAvatar: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const showUserAvatarImage = computed(() => isString(props.user?.avatar_url))
    const userAvatarUrl = computed(() => props.user?.avatar_url)
    const userInitials = computed(() => {
      if (props.user === null) return ''
      return `${props.user.first_name.charAt(0)}${props.user.last_name.charAt(0)}`
    })

    // Methods
    const onClick = () => emit('click')

    return {
      showUserAvatarImage,
      userAvatarUrl,
      userInitials,
      onClick
    }
  }
}
</script>
