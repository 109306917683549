import useHQTasksFilter from '@/composables/useHQTasksFilter.js'

import { indexOperationsByDay, accountTotals } from '@/api_client/CompanyOperationSchedule.js'

import {
  isDateInTheFuture, isDateIsToday, dateToISO8601String, isDateInThePast
} from '@/helpers/DateTime.js'

import UseOperationSchedule from '@/composables/UseOperationSchedule.js'
import useAuth from '@/composables/useAuth.js'

import { isString, isObject } from '@/helpers/Utils.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed } from 'vue'

const DEFAULT_PER_PAGE = 70
const DEFAULT_PAGE = 1

const DEFAULT_PER_PAGE_FOR_ACCOUNT = 60

const defaultPagination = () => ({ page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE, total: 0 })
const defaultAccountListPagination = () => ({ page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE_FOR_ACCOUNT, total: 0 })
const singleAccountDefault = () => ({
  pagination: defaultPagination(),
  tasksList: [],
  isLoadingTasks: false,
  isLoadingTasksNextPage: false
})

const accountsPagination = ref(defaultAccountListPagination())
const accountsList = ref([])
const isLoadingAccounts = ref(false)
const isLoadingAccountsNextPage = ref(false)

let currentApiKey = null

export default function useHQDailyTasks (apiKey) {
  const { clientDate, taskModeCrossAccount, changeDate: changeDateImpl, buildFiltersForApi } = useHQTasksFilter(apiKey)

  const resetValues = () => {
    accountsList.value = []
    isLoadingAccounts.value = false
    isLoadingAccountsNextPage.value = false
    accountsPagination.value = defaultAccountListPagination()
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()

    currentApiKey = apiKey
  }

  const setPaginationFromResponse = ({ meta, paginationVal }) => {
    paginationVal.total = meta.total
    paginationVal.page = meta.page
    paginationVal.perPage = meta.per_page
  }

  const baseRequestParams = (paginationVal) => {
    const requestParams = {
      client_date: clientDateIso.value,
      client_date_is_today: isDateIsToday(clientDate.value),
      client_date_is_future: isDateInTheFuture(clientDate.value),
      page: paginationVal.page,
      per_page: paginationVal.per_page
    }
    return Object.assign(requestParams, buildFiltersForApi())
  }

  const findAccount = (accountApiKey) => accountsList.value.find((account) => account.api_key === accountApiKey)

  // Methods
  const changeDate = changeDateImpl
  const findTask = (taskId) => {
    for (const account of accountsList.value) {
      const tasksData = account.tasksData
      for (const task of tasksData.tasksList) {
        if (task.operationRef.operation_id === taskId) {
          return task
        }
      }
    }
  }

  const applyFilters = () => {
    if (taskModeCrossAccount.value) return

    loadAccounts({ reset: true })
  }

  const deleteTask = async (task) => {
    const isReported = task.isReported()
    await task.deleteOperation()

    const accountApiKey = task.operationRef.account_api_key
    const accountIndex = accountsList.value.findIndex((account) => account.api_key === accountApiKey)
    if (accountIndex === -1) return

    const account = accountsList.value[accountIndex]
    const taskId = task.operationRef.operation_id
    const taskIndex = account.tasksData.tasksList.findIndex((task) => task.operationRef.operation_id === taskId)
    if (taskIndex > -1) {
      account.tasksData.tasksList.splice(taskIndex, 1)
    }

    if (account.tasks_count === 1) {
      accountsList.value.splice(accountIndex, 1)
    } else if (account.tasks_count > 1) {
      account.tasks_count--
      if (isReported && account.finished_tasks_count > 0) {
        account.finished_tasks_count--
      }
    }
  }

  const loadAccounts = ({ reset = false } = {}) => {
    if (reset) {
      accountsPagination.value = defaultAccountListPagination()
      accountsList.value = []
    }
    if (isLoadingAccounts.value || isLoadingAccountsNextPage.value) return

    if (accountsPagination.value.page > 1) {
      isLoadingAccountsNextPage.value = true
    } else {
      isLoadingAccounts.value = true
    }

    const requestParams = baseRequestParams(accountsPagination.value)
    return accountTotals(apiKey, requestParams).then(({ data: { data, meta } }) => {
      setPaginationFromResponse({ meta, paginationVal: accountsPagination.value })
      data.forEach((account) => (account.tasksData = singleAccountDefault()))
      accountsList.value = accountsList.value.concat(data)
    }).catch(errHandler)
      .finally(() => {
        isLoadingAccounts.value = false
        isLoadingAccountsNextPage.value = false
      })
  }

  const loadAccountsNextPage = () => {
    if (hasMoreAccountsToLoad.value) {
      accountsPagination.value.page++
      loadAccounts()
    }
  }

  const reloadSingleAccountTasks = (accountApiKey) => {
    const account = findAccount(accountApiKey)
    if (!isObject(account)) return

    const accountTasksData = account.tasksData
    accountTasksData.tasksList = []
    accountTasksData.pagination = defaultPagination()
    loadSingleAccountTasks(accountApiKey)
  }

  const loadSingleAccountTasks = (accountApiKey) => {
    const account = findAccount(accountApiKey)
    if (!isObject(account)) return

    const accountTasksData = account.tasksData
    if (accountTasksData.isLoadingTasks || accountTasksData.isLoadingTasksNextPage) return

    const paginationVal = accountTasksData.pagination
    if (paginationVal.page > 1) {
      accountTasksData.isLoadingTasksNextPage = true
    } else {
      accountTasksData.isLoadingTasks = true
    }

    const { isUserCompanyRegionAdmin, isUserCompanyAdmin, currentUser } = useAuth()
    const validUserId = Number.isInteger(currentUser.value?.id)
    const requestParams = baseRequestParams(paginationVal)
    requestParams.api_keys = accountApiKey
    return indexOperationsByDay(accountApiKey, requestParams)
      .then(({ data: { data, meta } }) => {
        setPaginationFromResponse({ meta, paginationVal })
        const newTasks = data.map((task) => {
          task.reportDelay = 0
          task.account_name = account.name
          if (task.staff_operation) {
            if (isUserCompanyAdmin.value) {
              task.allowEditCrossAccount = true
            } else if (isUserCompanyRegionAdmin.value && validUserId && task.creator_data?.user_id === currentUser.value.id) {
              task.allowEditCrossAccount = true
            }
          }

          return new UseOperationSchedule(task)
        })
        const tasksList = accountTasksData.tasksList
        accountTasksData.tasksList = tasksList.concat(newTasks)
      })
      .catch(errHandler)
      .finally(() => {
        accountTasksData.isLoadingTasks = false
        accountTasksData.isLoadingTasksNextPage = false
      })
  }

  const loadSingleAccountTasksNextPage = (accountApiKey) => {
    const account = findAccount(accountApiKey)
    if (!isObject(account)) return
    const taskData = account.tasksData

    if (taskData.isLoadingTasks || taskData.isLoadingTasksNextPage) return
    if (taskData.tasksList.length >= account.tasks_count) return

    taskData.pagination.page++
    loadSingleAccountTasks(accountApiKey)
  }

  const afterTaskComplete = (task) => {
    const accountApiKey = task.operationRef.account_api_key
    const account = findAccount(accountApiKey)
    if (!isObject(account)) return

    if (account.tasks_count > account.finished_tasks_count) {
      account.finished_tasks_count++
    }
  }

  const afterTaskUnComplete = (task) => {
    const accountApiKey = task.operationRef.account_api_key
    const account = findAccount(accountApiKey)
    if (!isObject(account)) return

    if (account.finished_tasks_count > 0) {
      account.finished_tasks_count--
    }
  }

  // Computed
  const clientDateIso = computed(() => dateToISO8601String(clientDate.value))
  const isEmptyPage = computed(() => !isLoadingAccounts.value && !isLoadingAccountsNextPage.value && accountsList.value.length === 0)

  const hasMoreAccountsToLoad = computed(() => {
    if (isLoadingAccounts.value || isLoadingAccountsNextPage.value) return false
    return accountsPagination.value.total > accountsList.value.length
  })

  const tasksDisabled = computed(() => {
    if (dateToISO8601String(new Date()) === clientDateIso.value) return false

    if (isDateInThePast(clientDate.value)) return false
    return true
  })
  return {
    accountsList,
    tasksDisabled,
    clientDateIso,
    clientDate,
    isLoadingAccounts,
    isLoadingAccountsNextPage,
    isEmptyPage,
    hasMoreAccountsToLoad,
    applyFilters,
    loadAccounts,
    deleteTask,
    findTask,
    loadAccountsNextPage,
    changeDate,
    loadSingleAccountTasks,
    loadSingleAccountTasksNextPage,
    reloadSingleAccountTasks,
    afterTaskComplete,
    afterTaskUnComplete
  }
}
