
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { newCompanyProviderModel } from '@/store/modules/CompanyProvider.js'

import {
  show as showProviderClient,
  index as indexProviderClient,
  destroy as destroyProviderClient
} from '@/api_client/CompanyProvider.js'

import { isString, isObject } from '@/helpers/Utils.js'
import { ref } from 'vue'

const DEFAULT_PER_PAGE = 60
const DEFAULT_PAGE = 1
const defaultPgaination = () => ({ page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE, total: 0 })

const isLoading = ref(false)
const isLoadingNextPage = ref(false)
const pagination = ref(defaultPgaination())
const providerList = ref([])

let currentApiKey = null

export default function useCompanyProvider (apiKey) {
  const resetValues = () => {
    isLoading.value = false
    isLoadingNextPage.value = false
    pagination.value = defaultPgaination()
    providerList.value.splice(0, providerList.value.length)
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const loadProviders = (reset = false) => {
    if (reset === true) {
      resetValues()
    }
    if (pagination.value.page === 1) {
      isLoading.value = true
    } else {
      isLoadingNextPage.value = true
    }
    return indexProviderClient(currentApiKey, pagination.value)
      .then(({ data: { data, meta } }) => {
        const newProviders = data.map((provider) => (newCompanyProviderModel(provider)))
        providerList.value = providerList.value.concat(newProviders)
        pagination.value = Object.assign({}, pagination.value, meta)
      })
      .catch(errHandler)
      .finally(() => {
        isLoading.value = false
        isLoadingNextPage.value = false
      })
  }

  // methods
  const deleteProvider = (providerId) => {
    return destroyProviderClient(currentApiKey, providerId)
      .then(() => loadProviders(true))
      .catch(errHandler)
  }

  const providerSearchChange = (provider) => {
    if (!isObject(provider)) return

    const providerId = provider.id
    if (!Number.isInteger(providerId)) return
    isLoading.value = true
    showProviderClient(currentApiKey, providerId).then((response) => {
      providerList.value.splice(0, providerList.value.length)
      providerList.value.push(newCompanyProviderModel(response.data.data))
      pagination.value.page = 1
      pagination.value.total = 1
    })
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const clearSearch = () => {
    loadProviders()
  }

  const loadNextPage = () => {
    pagination.value.page = pagination.value.page + 1
    loadProviders()
  }

  return {
    isLoading,
    isLoadingNextPage,
    pagination,
    providerList,
    deleteProvider,
    providerSearchChange,
    clearSearch,
    loadProviders,
    loadNextPage
  }
}
