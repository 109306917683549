<template>
  <v-dialog
    v-model="dialog" persistent
    width="540" scrollable
    content-class="rounded-xl"
  >
    <v-card
      class="bg-color16 frequency-dialog"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="white px-1">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="3" class="texts-start">
            <v-btn
              icon
              color="black"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-center color5 t-600-18">
            {{ $t('operation_frequency.yearly') }}
          </v-col>
          <v-col cols="3" class="texts-end ppe-1 t-400-17 color22">
            <span class="pointer" @click="onReset">
              {{ $t('reset') }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="scrollable">
        <v-row align="start" justify="center">
          <v-col cols="10" class="text-center pb-0 mt-4">
            <v-date-picker
              :value="pickerSelectedValue"
              class="frequency-date-picker"
              :multiple="!singleDate" type="date" scrollable
              show-current :min="datesDialogMinDate"
              :locale="locale"
              @input="updateFrequencyDate"
            />
          </v-col>
          <v-col cols="10" class="pt-0 text-center">
            <v-chip
              v-for="date in selectedDatesUserFormat" :key="date.iso8601date"
              class="mms-1 mb-2"
              close
              @click:close="removeDate(date.iso8601date)"
            >
              <span class="mme-2">{{ date.userFormatDate }}</span>
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :disabled="saveDisabled"
              @click="saveClick"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import useAccount from '@/composables/useAccount.js'

import { dateToISO8601String, getClientDateFormatted, ISO8601StringToDate } from '@/helpers/DateTime.js'

import { isRtl, i18n } from '@/i18n.js'

import { ref, computed } from 'vue'
export default {
  props: {
    singleDate: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    let resolveFunc = null
    const { accountCountryCode } = useAccount()
    const dialog = ref(false)
    const selectedDates = ref([])
    const datesDialogMinDate = dateToISO8601String(new Date())

    // Methods
    const saveClick = () => close({ isSaved: true })
    const updateFrequencyDate = (value) => (selectedDates.value = props.singleDate ? [value] : value)

    const openDialog = (selectedDatesVal) => {
      dialog.value = true
      selectedDates.value = selectedDatesVal
      return new Promise((resolve) => {
        resolveFunc = resolve
      })
    }

    const onReset = () => close({ isSaved: true, isReset: true })
    const close = ({ isSaved = false, isReset = false } = {}) => {
      dialog.value = false
      if (!isSaved) return resolveFunc({ isSaved })
      if (isReset) return resolveFunc({ isSaved, isReset })

      resolveFunc({
        isSaved,
        selectedDates: selectedDates.value
      })
    }

    const removeDate = (date) => {
      selectedDates.value = selectedDates.value.filter(selectedDate => selectedDate !== date)
    }
    // Computed
    const pickerSelectedValue = computed(() => props.singleDate ? selectedDates.value[0] : selectedDates.value)

    const selectedDatesUserFormat = computed(() =>
      selectedDates.value.map(iso8601date => {
        const fromDate = ISO8601StringToDate(iso8601date)
        const userFormatDate = getClientDateFormatted({ fromDate, countryCode: accountCountryCode.value })
        return { iso8601date, userFormatDate }
      })
    )

    const saveDisabled = computed(() => selectedDates.value.length === 0)
    const locale = computed(() => i18n.locale)
    return {
      isRtl,
      dialog,
      saveDisabled,
      datesDialogMinDate,
      selectedDates,
      selectedDatesUserFormat,
      pickerSelectedValue,
      locale,
      removeDate,
      openDialog,
      close,
      onReset,
      updateFrequencyDate,
      saveClick
    }
  }
}
</script>
