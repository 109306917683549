import Client from '@/api_client/client.js'
const index = (apiKey) => {
  const COMPANY_DISTRICT_INDEX_PATH = `/accounts/${apiKey}/company_districts`
  return Client.get(COMPANY_DISTRICT_INDEX_PATH)
}

const create = (apiKey, districtName) => {
  const COMPANY_DISTRICT_CREATE_PATH = `/accounts/${apiKey}/company_districts`
  return Client.post(COMPANY_DISTRICT_CREATE_PATH, { company_district: { name: districtName } })
}

const update = (apiKey, districtId, districtName) => {
  const COMPANY_DISTRICT_UPDATE_PATH = `/accounts/${apiKey}/company_districts/${districtId}`
  return Client.put(COMPANY_DISTRICT_UPDATE_PATH, { company_district: { name: districtName } })
}

const destroy = (apiKey, districtId) => {
  const COMPANY_DISTRICT_DESTROY_PATH = `/accounts/${apiKey}/company_districts/${districtId}`
  return Client.delete(COMPANY_DISTRICT_DESTROY_PATH)
}

export {
  index,
  create,
  update,
  destroy
}
