
import {
  show as CompanyClientShow,
  update as CompanyClientUpdate,
  accountsData as CompanyClientAccountsData,
  removeCompanyImage as CompanyClientRemoveCompanyImage
} from '@/api_client/CompanyClient.js'

import { betterImageQuality, isString } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'

const IS_MULTIPLE_MEMBERSHIPS = 'IS_MULTIPLE_MEMBERSHIPS'

const companyNameRef = ref('')
const companyCustomImageRef = ref('')
const isMultipleMembershipsRef = ref(null)
const isMultipleAccountsRef = ref(null)
const staffAccountApiKeyRef = ref(null)

let isLoadedCompanyData = false
let isLoadedAccountsData = false
let currentApiKey = null

const companyDefaultImagePath = 'images/Company/company_default_image_web.jpg'
const companyDefaultImageDomain = 'https://assets.toperation.co'

export default function useCompany (apiKey) {
  currentApiKey = apiKey
  const setCompanyData = (companyData) => {
    companyNameRef.value = companyData.name
    companyCustomImageRef.value = companyData.custom_company_image
    isLoadedCompanyData = true
  }

  const setIsMultipleMemberships = (isMultipleMembershipsVal) => {
    isMultipleMembershipsRef.value = isMultipleMembershipsVal
    localStorage.setItem(IS_MULTIPLE_MEMBERSHIPS, isMultipleMembershipsVal)
  }

  const initIsMultipleMemberships = () => (isMultipleMembershipsRef.value = localStorage.getItem(IS_MULTIPLE_MEMBERSHIPS) === 'true')

  const setCompanyAccountData = (companyData) => {
    isLoadedAccountsData = true
    companyNameRef.value = companyData.company_name
    isMultipleAccountsRef.value = companyData.multiple_accounts
    staffAccountApiKeyRef.value = companyData.staff_account_api_key
    setIsMultipleMemberships(companyData.multiple_memberships)
  }

  // Methods

  const loadCompanyData = async () => {
    if (isLoadedCompanyData === true) return
    const response = await CompanyClientShow(currentApiKey)
    setCompanyData(response.data.data)
  }

  const updateCompanyData = async ({ name, withImage }) => {
    const response = await CompanyClientUpdate(currentApiKey, { name, with_image: withImage })
    setCompanyData(response.data.data)
    return response.data
  }

  const loadAccountData = async () => {
    if (isLoadedAccountsData === true) return
    const response = await CompanyClientAccountsData(currentApiKey)
    setCompanyAccountData(response.data)
  }

  const removeCompanyImage = async () => {
    await CompanyClientRemoveCompanyImage(currentApiKey)
    companyCustomImageRef.value = null
  }

  const companyName = computed(() => companyNameRef.value)
  const companyImage = computed(() => {
    if (isString(companyCustomImageRef.value) && companyCustomImageRef.value.length > 0) {
      return betterImageQuality(companyCustomImageRef.value)
    }
    return `${companyDefaultImageDomain}/${import.meta.env.MODE}/${companyDefaultImagePath}`
  })
  const customCompanyImage = computed(() => companyCustomImageRef.value)

  const isMultipleMemberships = computed(() => isMultipleMembershipsRef.value)
  const isMultipleAccounts = computed(() => isMultipleAccountsRef.value)
  const staffAccountApiKey = computed(() => staffAccountApiKeyRef.value)
  return {
    companyName,
    companyImage,
    customCompanyImage,
    isMultipleMemberships,
    isMultipleAccounts,
    staffAccountApiKey,
    setIsMultipleMemberships,
    initIsMultipleMemberships,
    loadCompanyData,
    updateCompanyData,
    loadAccountData,
    removeCompanyImage
  }
}
