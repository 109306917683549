<template>
  <v-select
    :value="value"
    :items="tags"
    :label="$t('schedule_new_op_dialog.tags')"
    clearable
    outlined
    hide-details
    background-color="white"
    @input="emitInput"
  >
    <template #item="{ item }">
      <v-row
        align="center" justify="space-between"
        :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
        no-gutters
      >
        <v-col cols="10">
          {{ item.text }}
        </v-col>
        <v-col cols="2" class="texts-end">
          <v-checkbox
            :input-value="item.value === value"
            off-icon="radio_button_unchecked" on-icon="radio_button_checked"
            hide-details class="mt-0 pt-0 d-inline-block"
          />
        </v-col>
      </v-row>
    </template>
  </v-select>
</template>
<script>
import { translateReportTag } from '@/store/modules/ReportTag.js'
import { isRtl } from '@/i18n'
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: null
    }
  },
  setup (_, { emit }) {
    const tags = [
      { text: translateReportTag('report'), value: 'report' },
      { text: translateReportTag('monitor'), value: 'monitor' },
      { text: translateReportTag('procedure'), value: 'procedure' }
    ]

    // Methods
    const emitInput = (value) => emit('input', value)

    return {
      tags,
      isRtl,
      emitInput
    }
  }
}
</script>
