<template>
  <v-dialog
    v-model="dialog" width="400" persistent
    scrollable
    content-class="rounded-xl"
  >
    <v-card
      class="overflow-y-hidden popup-card bg-color17"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="white px-1">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="2" class="texts-start">
            <v-btn
              icon
              color="black"
              @click="close(false)"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center color5 t-600-18">
            {{ dialogTitle }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="py-0 mt-4">
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="departmentName"
              class="required"
              outlined
              background-color="white"
              counter="80"
              :label="$t('name')"
            />
          </v-col>
          <v-col cols="12" class="my-3">
            <single-image-uploader ref="accountImageRef" width="80px" />
          </v-col>
        </v-row>
        <v-snackbar v-model="errorSnackBar" :timeout="3000">
          <div class="text-center">
            {{ $t('account_department_select.error_name_taken') }}
          </div>
        </v-snackbar>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :loading="isLoading"
              :disabled="isSaveDisabled"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import SingleImageUploader from '@/components/shared/SingleImageUploader.vue'

import {
  create as accountDepartmentCreate, update as accountDepartmentUpdate,
  addImage as accountDepartmentAddImage, removeImage as accountDepartmentRemoveImage
} from '@/api_client/AccountDepartment.js'

import { uploadImage as uploadImageToRemoteServer } from '@/general_client/CloudinaryImageUploader.js'

import { isString } from '@/helpers/Utils.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { i18n, isRtl } from '@/i18n.js'

import { ref, computed, nextTick } from 'vue'

const DialogMode = { create: 0, update: 1 }
let departmentId = null

export default {
  components: {
    'single-image-uploader': SingleImageUploader
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const isLoading = ref(false)
    const dialog = ref(false)
    const departmentName = ref(null)
    const dialogMode = ref(null)
    const errorSnackBar = ref(false)
    const accountImageRef = ref(null)
    let resolveFunc = null
    let newDepartmentId = null

    const onOpenDialog = ({ name = null, mode, imageUrl } = {}) => {
      dialog.value = true
      isLoading.value = false
      departmentName.value = name
      dialogMode.value = mode
      newDepartmentId = null
      if (dialogMode.value === DialogMode.update && isString(imageUrl) && imageUrl.length > 0) {
        nextTick(() => accountImageRef.value.setImageUrl(imageUrl))
      }
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const updaloadImage = async (imageUploadData, id) => {
      await uploadImageToRemoteServer(accountImageRef.value.getImageFile(), imageUploadData)

      return accountDepartmentAddImage({ apiKey: props.apiKey, imageName: imageUploadData.public_id, id })
    }

    // Methods
    const openDialog = () => onOpenDialog({ mode: DialogMode.create })

    const openForUpdate = (department) => {
      departmentId = department.id
      return onOpenDialog({ name: department.name, mode: DialogMode.update, imageUrl: department.image_url })
    }

    const close = (isCreated) => {
      dialog.value = false
      departmentId = null
      accountImageRef.value.reset()
      resolveFunc({ isCreated, departmentId: newDepartmentId })
    }

    const save = () => {
      isLoading.value = true
      let promise = null
      const requestParams = saveRequestParams()
      if (dialogMode.value === DialogMode.create) {
        promise = accountDepartmentCreate(requestParams)
      } else {
        promise = accountDepartmentUpdate(requestParams)
      }
      promise
        .then(async (response) => {
          if (dialogMode.value === DialogMode.create) {
            newDepartmentId = response.data.department_id
          }
          if (accountImageRef.value.shouldAddImage() === true) {
            const responseData = response.data
            const imageUploadData = response.data.image_upload_data
            const id = dialogMode.value === DialogMode.create ? responseData.department_id : departmentId
            await updaloadImage(imageUploadData, id)
          } else if (accountImageRef.value.shouldRemoveImage() === true) {
            await accountDepartmentRemoveImage({ apiKey: props.apiKey, id: departmentId })
          }
        })
        .then(() => close(true))
        .catch((err) => {
          const errorsArray = err?.response?.data?.errors
          if (Array.isArray(errorsArray) && errorsArray.some((err) => err === 'name_taken')) {
            errorSnackBar.value = true
          }
          errHandler(err)
        })
        .finally(() => (isLoading.value = false))
    }

    const saveRequestParams = () => {
      const params = { apiKey: props.apiKey, name: departmentName.value }
      if (dialogMode.value === DialogMode.update) {
        params.id = departmentId
      }
      if (accountImageRef.value.shouldAddImage()) {
        params.withImage = true
      }
      return params
    }

    // Computed

    const dialogTitle = computed(() => dialogMode.value === DialogMode.create ? i18n.t('account_department_select.create') : i18n.t('account_department_select.update'))
    const isSaveDisabled = computed(() => {
      const nameVal = departmentName.value
      if (isString(nameVal) !== true) return true
      return !(nameVal.trim().length > 0 && nameVal.trim().length <= 80)
    })
    return {
      isLoading,
      dialogTitle,
      dialog,
      departmentName,
      isSaveDisabled,
      errorSnackBar,
      accountImageRef,
      isRtl,
      openDialog,
      openForUpdate,
      close,
      save
    }
  }
}
</script>
