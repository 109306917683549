<template>
  <v-row v-if="isTypeTitle" align="start" justify="start">
    <v-col cols="12">
      <v-chip color="#0040FF" class="white--text t-800-17 rounded-lg">
        {{ listItem.description }}
      </v-chip>
    </v-col>
  </v-row>
  <v-row v-else align="start" justify="start">
    <v-col cols="1">
      <v-checkbox
        v-if="isTypeDefault"
        :input-value="listItem.bool_value" :disabled="disabled" class="mt-0 pt-0"
        on-icon="check_circle" off-icon="radio_button_unchecked" color="#0040FF"
        hide-details
        @change="changeCheckBox"
      />
    </v-col>
    <v-col cols="11">
      <div class="t-500-16">
        <span class="color5">{{ listItem.description }}</span>
        <span v-if="isTypeFeedback" class="color3 mms-1">{{ $t('optional') }}</span>
      </div>
      <div v-if="isTypeRating" class="mt-1">
        <v-rating
          class="d-inline-block"
          :value="listItem.int_value" hover
          :readonly="disabled" length="5"
          color="#0040FF" @input="changeRating"
        />
        <span v-if="!disabled" class="color1 t-400-16 pointer text-decoration-underline" @click="cancelRating">
          {{ $t('reset') }}
        </span>
      </div>
      <div v-else-if="isTypeYesNo" class="mt-2">
        <v-btn
          depressed
          :disabled="disabled"
          active-class="white--text"
          :outlined="listItem.int_value !== 1"
          :input-value="listItem.int_value === 1"
          :color="listItem.int_value === 1 ? '#0040FF': 'black'"
          @click="changeYesNo(1)"
        >
          {{ $t('yes') }}
        </v-btn>
        <v-btn
          class="mms-2"
          depressed
          :disabled="disabled"
          :outlined="listItem.int_value !== 0"
          active-class="white--text"
          :input-value="listItem.int_value === 0"
          :color="listItem.int_value === 0 ? '#0040FF': 'black'"
          @click="changeYesNo(0)"
        >
          {{ $t('no') }}
        </v-btn>
        <span v-if="!disabled" class="color1 mms-3 t-400-16 pointer text-decoration-underline" @click="cancelRating">
          {{ $t('reset') }}
        </span>
      </div>
      <div v-else-if="isTypeImage" class="my-2">
        <v-row
          align="start" justify="start" no-gutters
        >
          <v-col
            v-for="(image, index) in imageList" :key="image.id"
            class="flex-grow-0 flex-shrink-1 px-1"
          >
            <single-image-uploader
              width="85px"
              :disabled="disabled"
              :url="image.url"
              capture
              @image-uploaded="onImageUploaded"
              @image-removed="onImageDelete(index)"
              @image-preview-click="onImagePreviewClick(index)"
            />
          </v-col>
          <v-col v-if="showNewImage" class="flex-grow-0 flex-shrink-1 px-1">
            <single-image-uploader
              ref="imageUploader"
              width="85px"
              capture
              :loading="listItem.imageIsUploading"
              @image-uploaded="onImageUploaded"
            />
          </v-col>
        </v-row>
      </div>
      <div v-if="showCreationDate" class="color11 t-500-13">
        <span v-if="showReporter">{{ $t('account_operations.done_by', {first_name: listItem.reporter_first_name, last_name: listItem.reporter_last_name}) }}, </span>{{ listItem.reportDate }} {{ listItem.reportTime }}
      </div>
      <v-textarea
        :class="{'mt-2': isTypeYesNo || isTypeFeedback || isTypeImage}"
        class="mx-0 mb-0 pt-0" :counter="stringValueMaxLength" :value="listItem.string_value"
        rows="1" auto-grow :disabled="disabled"
        :placeholder="$t('reported_item_op_list.comment')"
        @change="changeComment"
      />
    </v-col>
  </v-row>
</template>
<script>
import SingleImageUploader from '@/components/shared/SingleImageUploader.vue'
import { itemTypes, stringValueMaxLength } from '@/models/ItemOperationListItemTypes.js'

import { isString } from '@/helpers/Utils.js'

import { ref, computed, watch } from 'vue'

const MaxImages = 5
export default {
  components: {
    'single-image-uploader': SingleImageUploader
  },
  props: {
    listItem: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, { emit }) {
    const imageUploader = ref(null)

    // Methods
    const changeComment = (value) => emit('change-field', { value, item: props.listItem, field: 'string_value' })
    const changeCheckBox = (value) => emit('change-field', { value, item: props.listItem, field: 'bool_value' })
    const changeRating = (value) => emit('change-field', { value, item: props.listItem, field: 'int_value' })
    const cancelRating = () => changeRating(null)
    const changeYesNo = (value) => emit('change-field', { value, item: props.listItem, field: 'int_value' })
    const onImageUploaded = ({ event }) => emit('upload-image', { event, item: props.listItem })
    const onImageDelete = (index) => emit('delete-image', { index, item: props.listItem })
    const onImagePreviewClick = (index) => emit('image-click', { index, item: props.listItem })

    // Computed
    const isTypeTitle = computed(() => props.listItem.item_type === itemTypes.title)
    const isTypeImage = computed(() => props.listItem.item_type === itemTypes.image)
    const isTypeDefault = computed(() => props.listItem.item_type === itemTypes.default)
    const isTypeRating = computed(() => props.listItem.item_type === itemTypes.rating)
    const isTypeYesNo = computed(() => props.listItem.item_type === itemTypes.yes_no)
    const isTypeFeedback = computed(() => props.listItem.item_type === itemTypes.feedback)
    const showCreationDate = computed(() => isString(props.listItem.reportTime) && isString(props.listItem.reportDate))
    const showReporter = computed(() => isString(props.listItem.reporter_first_name) && isString(props.listItem.reporter_last_name))
    const imageList = computed(() => props.listItem.images || [])
    const showNewImage = computed(() => props.readOnly !== true && imageList.value.length < MaxImages)

    if (isTypeImage.value) {
      watch(() => imageList.value, () => imageUploader.value && imageUploader.value.reset())
    }

    return {
      stringValueMaxLength,
      isTypeTitle,
      isTypeImage,
      isTypeDefault,
      isTypeRating,
      isTypeYesNo,
      isTypeFeedback,
      showCreationDate,
      showReporter,
      imageList,
      showNewImage,
      imageUploader,
      changeCheckBox,
      changeComment,
      changeRating,
      cancelRating,
      changeYesNo,
      onImageUploaded,
      onImageDelete,
      onImagePreviewClick
    }
  }
}
</script>
