<template>
  <span>
    <new-item-category
      v-if="userCanCreateItemCategory"
      ref="newItemCategoryDialog"
      :api-key="apiKey"
    />
    <v-autocomplete
      :value="value"
      :items="selectEntries"
      :loading="selectIsLoading"
      :search-input.sync="search"
      clearable
      class="item-category-search"
      color="black"
      dense
      outlined
      return-object
      item-text="name"
      :no-data-text="$t('no_result')"
      :disabled="disabled"
      item-value="id"
      :menu-props="menuProps"
      :placeholder="placeHolder"
      @change="emitChange"
      @click:clear="clear"
      @input="update"
    >
      <template
        v-if="userCanCreateItemCategory"
        #prepend-item
      >
        <v-list-item :class="{ rtl: isRtl }" @click="addNewItemCategory">
          <v-list-item-content>
            <v-list-item-title class="color20 t-700-14">
              <v-icon
                color="#111"
                class="mx-2"
              >add_circle_outline
              </v-icon>
              {{ $t('item_category_search.add_item_category') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2" />
      </template>
      <template #item="{ item }">
        <v-row
          :class="{ rtl: isRtl }"
          align="center"
          justify="space-between"
        >
          <span class="font-weight-bold px-2"> {{ item.name }}</span>
        </v-row>
      </template>
      <template #selection="{ item }">
        <div :class="{ rtl: isRtl }">
          <span class="font-weight-bold"> {{ item.name }}</span>
        </div>
      </template>
    </v-autocomplete>
  </span>
</template>
<script>
import NewItemCategory from '@/components/shared/NewItemCategory.vue'
import useItemCategorySearchStore from '@/components/shared/item_category_search/useItemCategorySearchStore.js'

import useAuth from '@/composables/useAuth.js'

import { search as itemCategoryClientSearch } from '@/api_client/ItemCategory.js'

import { isString } from '@/helpers/Utils.js'

import { isRtl, i18n } from '@/i18n.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, watch, computed } from 'vue'

export default {
  components: {
    'new-item-category': NewItemCategory
  },
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => { }
    },
    placeHolder: {
      type: String,
      default: function () {
        return i18n.t('item_category_search.placeholder')
      },
      required: false
    },
    apiKey: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup (props, { emit }) {
    const entries = ref([])
    const isSearchLoading = ref(false)
    const search = ref(null)
    const newItemCategoryDialog = ref(null)

    const {
      allItemCategories, isLoading, invalidateCache, loadAllItemCategories, changeApiKey
    } = useItemCategorySearchStore(props.apiKey)
    const { isAccountAdminOrAbove } = useAuth()

    const reloadAllItemCategories = () => {
      return loadAllItemCategories()
        .catch(errHandler)
    }

    // Methods
    const update = (newValue) => emit('input', newValue)
    const emitChange = (newValue) => emit('change', newValue)
    const clear = () => {
      update({})
      emit('click:clear')
    }

    const addNewItemCategory = () => {
      newItemCategoryDialog.value.openForCreate().then(({ updatedOrCreated }) => {
        if (updatedOrCreated !== true) return

        emit('new-category-created')
        refreshItems()
      })
    }

    const refreshItems = () => {
      invalidateCache()
      reloadAllItemCategories()
    }

    // Computed
    const selectIsLoading = computed(() => isSearchLoading.value || isLoading.value)
    const userCanCreateItemCategory = computed(() => isAccountAdminOrAbove.value)

    const selectEntries = computed(() => {
      let selectedRef = []
      if (isString(search.value) && search.value.length > 0) {
        selectedRef = entries.value
      } else {
        selectedRef = allItemCategories.value
      }
      return selectedRef
    })

    watch(() => search.value, (query) => {
      if (isSearchLoading.value || query === null) return
      if (!query || (query || '').trim().length < 1) {
        entries.value.splice(0, entries.value.length)
        return
      }

      isSearchLoading.value = true
      itemCategoryClientSearch(props.apiKey, { term: query })
        .then(res => {
          const results = res.data.data
          const entriesVal = entries.value
          results.forEach(item => entriesVal.push(item))
        })
        .catch(errHandler)
        .finally(() => (isSearchLoading.value = false))
    })

    watch(() => props.apiKey, (newValue) => {
      if (!isString(newValue)) return
      changeApiKey(newValue)
      refreshItems()
    })

    reloadAllItemCategories()
    return {
      entries,
      isSearchLoading,
      search,
      selectIsLoading,
      selectEntries,
      userCanCreateItemCategory,
      isRtl,
      newItemCategoryDialog,
      update,
      emitChange,
      clear,
      refreshItems,
      addNewItemCategory
    }
  }
}
</script>
<style lang="scss">
.item-category-search.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0);
}

.item-category-search {
  .v-text-field__details {
    display: none;
  }
  .v-input__slot:after, .v-input__slot:before {
      display: none;
  }
}

</style>
