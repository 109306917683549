<template>
  <v-dialog
    v-model="dialog" persistent
    width="540"
    content-class="rounded-xl"
    scrollable
  >
    <v-card
      class="bg-color16 weekly-frequency frequency-dialog"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="white px-1">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="3" class="texts-start">
            <v-btn
              icon
              color="black"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-center color5 t-600-18">
            {{ $t('operation_frequency.weekly') }}
          </v-col>
          <v-col cols="3" class="texts-end ppe-1 t-400-17 color22">
            <span class="pointer" @click="onReset">
              {{ $t('reset') }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pt-4 scrollable">
        <v-row align="start" justify="center">
          <v-col cols="9" class="t-700-18 color20">
            {{ $t('operation_frequency.every_few_weeks') }}
          </v-col>
          <v-col cols="9" class="t-700-14">
            <span
              v-for="weekNumber in weeklyOptions"
              :key="weekNumber"
              v-ripple ripple class="chip-select mms-2"
              :class="{ 'selected': selectedWeek === weekNumber }"
              @click="onWeeklyClick(weekNumber)"
            >
              {{ weekNumber }}
            </span>
          </v-col>
          <v-col cols="9" class="t-700-18 color20">
            {{ $t('operation_frequency.what_day') }}
          </v-col>
          <v-col cols="9" class="t-700-14">
            <span
              v-for="workingDay in jobWorkingDays"
              :key="workingDay.name"
              v-ripple ripple class="chip-select mms-2"
              :class="{ 'selected': isWorkingDaysSelected(workingDay.name) }"
              @click="onWorkDayClick(workingDay.name)"
            >
              {{ workingDay.translatedName }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :disabled="saveDisabled"
              @click="saveClick"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { isRtl } from '@/i18n.js'

import { ref, computed } from 'vue'

export default {
  props: {
    jobWorkingDays: {
      type: Array,
      required: false,
      default: () => []
    },
    singleWeeklyWorkingDay: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const dialog = ref(false)
    let resolveFunc = null
    const selectedWeek = ref(null)
    const selectedWorkingDays = ref([])
    const weeklyOptions = Array.from({ length: 12 }, (_, index) => index + 1)

    // Methods
    const isWorkingDaysSelected = (workingDayName) => selectedWorkingDays.value.includes(workingDayName)
    const onReset = () => close({ isSaved: true, isReset: true })

    const openDialog = ({ selectedWeek: selectedWeekVal = null, selectedWorkingDays: selectedWorkingDaysVal = [] } = {}) => {
      dialog.value = true
      selectedWeek.value = selectedWeekVal
      selectedWorkingDays.value = selectedWorkingDaysVal
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const close = ({ isSaved = false, isReset = false } = {}) => {
      dialog.value = false
      if (!isSaved) return resolveFunc({ isSaved })
      if (isReset) return resolveFunc({ isSaved, isReset })

      resolveFunc({
        isSaved,
        selectedWeek: selectedWeek.value,
        selectedWorkingDays: selectedWorkingDays.value
      })
    }

    const onWeeklyClick = (weekNumber) => {
      if (selectedWeek.value === weekNumber) {
        selectedWeek.value = null
      } else {
        selectedWeek.value = weekNumber
      }
    }

    const onWorkDayClick = (workingDayName) => {
      const index = selectedWorkingDays.value.indexOf(workingDayName)
      if (props.singleWeeklyWorkingDay) {
        selectedWorkingDays.value.splice(0, selectedWorkingDays.value.length)
        if (index === -1) {
          selectedWorkingDays.value.push(workingDayName)
        }
        return
      }

      if (index === -1) {
        selectedWorkingDays.value.push(workingDayName)
      } else {
        selectedWorkingDays.value.splice(index, 1)
      }
    }

    const saveClick = () => close({ isSaved: true })

    // Computed

    const saveDisabled = computed(() => {
      const saveEnabled = Number.isInteger(selectedWeek.value) && selectedWorkingDays.value.length > 0
      return !saveEnabled
    })
    return {
      dialog,
      isRtl,
      weeklyOptions,
      selectedWeek,
      saveDisabled,
      onWorkDayClick,
      openDialog,
      close,
      onReset,
      onWeeklyClick,
      saveClick,
      isWorkingDaysSelected
    }
  }
}
</script>
<style lang="scss">
.weekly-frequency {
  overflow-y: hidden;
  min-height: 480px;
  .chip-select {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: calc(100% / 7 - 8px);
    aspect-ratio: 1 / 1;
    border: 1px solid #DADADA;
    margin-bottom: 8px;
    cursor: pointer;
    &.selected {
      background-color: #0040FF;
      border-color: none;
      color: white;
    }
  }
  .save-btn.v-btn--disabled {
    color: white !important
  }
}

</style>
