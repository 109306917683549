import { index as indexCrossAccountOperation, indexSingleCrossAccountOperationsByDay } from '@/api_client/CrossAccountOperationSchedule.js'
import { destroy as CrossAccountOperationDelete } from '@/api_client/CrossAccountOperation.js'
import useHQTasksFilter from '@/composables/useHQTasksFilter.js'
import useAccount from '@/composables/useAccount.js'
import useAuth from '@/composables/useAuth.js'
import UseOperationSchedule from '@/composables/UseOperationSchedule.js'

import {
  isDateInTheFuture, isDateIsToday, dateToISO8601String, isValidISO8601Date,
  getClientDateFormatted, ISO8601StringToDate, timeStringToUserFormat, isDateInThePast
} from '@/helpers/DateTime.js'

import { isString, isObject } from '@/helpers/Utils.js'

import Vue, { ref, computed } from 'vue'

const DEFAULT_PER_PAGE = 50
const DEFAULT_PAGE = 1

const defaultPagination = () => ({ page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE, total: 0 })
const pagination = ref(defaultPagination())
const crossAccountTasksList = ref([])
const isLoadingTasks = ref(false)
const isLoadingTasksNextPage = ref(false)

const tasksListPerCrossAccountTask = ref({})
let currentApiKey = null
let countryCode = null
let localeCode = null

export default function useHQCrossAccountDailyTasks (apiKey) {
  const { buildFiltersForApi, changeDate: changeDateImpl, clientDate } = useHQTasksFilter(apiKey)

  const resetValues = () => {
    pagination.value = defaultPagination()
    crossAccountTasksList.value = []
    isLoadingTasks.value = false
    isLoadingTasksNextPage.value = false
    tasksListPerCrossAccountTask.value = {}
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()

    const { accountCountryCode: accountCountryCodeRef, accountLocaleCode: accountLocaleCodeRef } = useAccount()

    currentApiKey = apiKey
    countryCode = accountCountryCodeRef.value
    localeCode = accountLocaleCodeRef.value
  }

  const baseRequestParams = () => {
    const requestParams = {
      client_date: clientDateIso.value,
      client_date_is_today: isDateIsToday(clientDate.value),
      client_date_is_future: isDateInTheFuture(clientDate.value),
      page: pagination.value.page,
      per_page: pagination.value.per_page
    }
    return Object.assign(requestParams, buildFiltersForApi())
  }

  const setPaginationFromResponse = (responseMeta) => {
    const paginationVal = pagination.value
    paginationVal.total = responseMeta.total
    paginationVal.page = responseMeta.page
    paginationVal.perPage = responseMeta.per_page
  }

  const addFormatsToCrossAccountTask = (task) => {
    task.isParentTask = true
    const endDate = task.end_date
    const startTime = task.start_time

    if (endDate && isValidISO8601Date(endDate)) {
      task.endDateUserFormat = getClientDateFormatted({ fromDate: ISO8601StringToDate(endDate), countryCode })
    }
    if (isString(startTime)) {
      task.startTimeUserFormat = timeStringToUserFormat({ timeString: startTime, localeCode })
    }
  }

  // Methods
  const changeDate = changeDateImpl

  const findCrossAccountTask = (crossAccountTaskId) =>
    crossAccountTasksList.value.find((task) => task.cross_account_operation_id === crossAccountTaskId)

  const findTask = (taskId) => {
    const caTasksVal = tasksListPerCrossAccountTask.value
    for (const crossAccountTaskId in caTasksVal) {
      const foundTask = (caTasksVal[crossAccountTaskId] || []).find((task) => task.operationRef.operation_id === taskId)
      if (foundTask) return foundTask
    }
  }

  const loadCrossAccountDailyOperations = () => {
    isLoadingTasks.value = true
    return indexCrossAccountOperation(currentApiKey, baseRequestParams())
      .then(({ data: { data, meta } }) => {
        const { isUserCompanyRegionAdmin, currentUser } = useAuth()
        const validUserId = Number.isInteger(currentUser.value?.id)
        setPaginationFromResponse(meta)
        data.forEach((task) => {
          if (isUserCompanyRegionAdmin.value && (!validUserId || task.creator_data?.user_id !== currentUser.value.id)) {
            task.hideOptions = true
          }
          addFormatsToCrossAccountTask(task)
        })
        crossAccountTasksList.value = crossAccountTasksList.value.concat(data)
      })
      .finally(() => (isLoadingTasks.value = false))
  }

  const loadNextPage = () => {
    if (hasMoreTasksToLoad.value) {
      pagination.value.page++
      loadCrossAccountDailyOperations()
    }
  }

  const deleteCrossAccountOperation = (crossAccountOperationId) => {
    isLoadingTasks.value = true
    return CrossAccountOperationDelete(currentApiKey, crossAccountOperationId)
      .then(() => {
        const crossAccountTasksListVal = crossAccountTasksList.value
        const index = crossAccountTasksListVal.findIndex(operation => operation.cross_account_operation_id === crossAccountOperationId)
        if (index > -1) {
          crossAccountTasksListVal.splice(index, 1)
        }
        if (Array.isArray(tasksListPerCrossAccountTask.value[crossAccountOperationId])) {
          Vue.delete(tasksListPerCrossAccountTask.value, crossAccountOperationId)
        }
      })
      .finally(() => (isLoadingTasks.value = false))
  }

  const loadSingleCrossAccountTasks = async (crossAccountTaskId) => {
    const tasksObject = tasksListPerCrossAccountTask.value[crossAccountTaskId]
    if (Array.isArray(tasksObject)) return tasksObject

    const { isUserCompanyRegionAdmin, currentUser } = useAuth()
    const validUserId = Number.isInteger(currentUser.value?.id)
    const requestParams = baseRequestParams()
    return indexSingleCrossAccountOperationsByDay(currentApiKey, crossAccountTaskId, requestParams)
      .then(({ data: { data } }) => {
        const result = data.map((task) => {
          task.crossAccountTaskId = crossAccountTaskId
          task.reportDelay = 0
          if (isUserCompanyRegionAdmin.value && (!validUserId || task.creator_data?.user_id !== currentUser.value.id)) {
            task.hideOptions = true
          }
          return new UseOperationSchedule(task)
        })
        Vue.set(tasksListPerCrossAccountTask.value, crossAccountTaskId, result)
        return result
      })
  }

  const onChildTaskDeleted = async (task) => {
    const isReported = task.isReported()
    await task.deleteOperation()

    const taskId = task.operationRef.operation_id
    const crossAccountTaskId = task.operationRef.crossAccountTaskId
    const taskIndex = (tasksListPerCrossAccountTask.value[crossAccountTaskId] || []).findIndex((row) => row.operationRef.operation_id === taskId)
    if (taskIndex === -1) return

    tasksListPerCrossAccountTask.value[crossAccountTaskId].splice(taskIndex, 1)
    const caoIndex = crossAccountTasksList.value.findIndex((operation) => operation.cross_account_operation_id === crossAccountTaskId)
    if (caoIndex === -1) return
    const crossAccountOperation = crossAccountTasksList.value[caoIndex]
    if (crossAccountOperation.total_tasks === 1) {
      crossAccountTasksList.value.splice(caoIndex, 1)
    } else {
      crossAccountOperation.total_tasks -= 1
      if (isReported && crossAccountOperation.total_tasks_reported > 0) {
        crossAccountOperation.total_tasks_reported -= 1
      }
    }
  }

  const changeTotalReportedOperations = ({ crossAccountTaskId, offset }) => {
    const crossAccountOperation = findCrossAccountTask(crossAccountTaskId)
    if (!isObject(crossAccountOperation)) return

    const newTotalTasksReported = crossAccountOperation.total_tasks_reported + offset
    const totalTasks = crossAccountOperation.total_tasks
    if (newTotalTasksReported < 0 || totalTasks < newTotalTasksReported) return
    crossAccountOperation.total_tasks_reported = newTotalTasksReported
  }

  const initPageData = () => {
    resetValues()
    return loadCrossAccountDailyOperations()
  }
  const applyFilters = initPageData

  // Computed
  const clientDateIso = computed(() => dateToISO8601String(clientDate.value))
  const isEmptyPage = computed(() => !isLoadingTasks.value && !isLoadingTasksNextPage.value && crossAccountTasksList.value.length === 0)
  const hasMoreTasksToLoad = computed(() => {
    if (isLoadingTasks.value || isLoadingTasksNextPage.value) return false
    return pagination.value.total > crossAccountTasksList.value.length
  })

  const tasksDisabled = computed(() => {
    if (dateToISO8601String(new Date()) === clientDateIso.value) return false
    if (isDateInThePast(clientDate.value)) return false
    return true
  })

  return {
    pagination,
    crossAccountTasksList,
    isLoadingTasks,
    isLoadingTasksNextPage,
    hasMoreTasksToLoad,
    clientDateIso,
    tasksDisabled,
    isEmptyPage,
    loadCrossAccountDailyOperations,
    initPageData,
    loadNextPage,
    deleteCrossAccountOperation,
    loadSingleCrossAccountTasks,
    onChildTaskDeleted,
    findTask,
    findCrossAccountTask,
    changeTotalReportedOperations,
    changeDate,
    applyFilters
  }
}
