<template>
  <div>
    <v-chip
      v-if="showFileAsTextInput" :color="color" close
      class="file-chip file-attached" @click:close="resetExistingFile"
    >
      <v-icon color="green" @click="openPreSetFile">
        {{ isVideoFile ? 'videocam' : 'attach_file' }}
      </v-icon>
      <span class="mx-2" :class="{ 'text-decoration-underline': underline }">{{ placeholderValue }}</span>
    </v-chip>
    <v-chip
      v-else :color="color" :close="isFileAttached"
      class="file-chip" :class="{ 'file-attached': isFileAttached }"
      @click:close="removeFile"
    >
      <v-file-input
        v-model="attachedFile" class="file-input" hide-input
        :accept="isVideoFile ? 'video/mp4,video/x-m4v,video/*' : '*/*'" :prepend-icon="isVideoFile ? 'videocam' : 'attach_file'" :rules="sizeValidationRules"
      />
      <span class="mx-2" :class="{ 'text-decoration-underline': underline }">{{ placeholderValue }}</span>
    </v-chip>
    <div v-if="showSizeLimitError" class="error--text">
      {{ $t('schedule_new_op_dialog.max_file_size') }}
    </div>
  </div>
</template>
<script>
import { recordTypes, removeFileApiCall, addFileApiCall } from '@/classes/FileUploaderApiCalls.js'
import { isFile, isString, isObject } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'

import { i18n } from '@/i18n.js'

const MAX_FILE_SIZE = 1024 * 1024 * 10 // 10MB
const MAX_VIDEO_FILE_SIZE = 1024 * 1024 * 80 // 10MB

export default {
  props: {
    recordType: {
      type: String,
      required: true,
      validator: function (recordType) {
        return Object.values(recordTypes).indexOf(recordType) !== -1
      }
    },
    placeholder: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    underline: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const attachedFile = ref(null)
    const preSetFileUrl = ref(null)
    const hadFile = ref(false)
    const clearedExistingFile = ref(false)

    // Methods
    const resetExistingFile = () => (clearedExistingFile.value = true)
    const shouldDeleteExistingFile = () => hadFile.value === true && clearedExistingFile.value === true && isFileAttached.value === false
    const openPreSetFile = () => window.open(preSetFileUrl.value, '_blank').focus()
    const removeFile = () => (attachedFile.value = null)
    const sizeValidationRules = [
      (value) => {
        if (typeof value === 'undefined' || value === null || (value.size > 0 && value.size < maxFileSize.value)) return true
        return i18n.t('schedule_new_op_dialog.max_file_size')
      }
    ]
    const reset = () => {
      attachedFile.value = null
      preSetFileUrl.value = null
      hadFile.value = false
      clearedExistingFile.value = false
    }

    const setFile = (fileUrl) => {
      if (isString(fileUrl) !== true) return

      preSetFileUrl.value = fileUrl
      hadFile.value = true
    }

    const attachedFileName = () => {
      if (!attachedFile.value) return null
      return attachedFile.value.name
    }

    const saveFile = async ({ apiKey, itemId, itemOperationId, operationId, fileUploadSignature, brokenItemId }) => {
      if (shouldDeleteExistingFile() === true) {
        return removeFileApiCall({ apiKey, itemId, operationId, itemOperationId, brokenItemId, recordType: props.recordType })
      }

      if (isFileAttached.value !== true || isObject(fileUploadSignature) !== true) return
      if (isString(fileUploadSignature.file_name) !== true || isString(fileUploadSignature.file_url) !== true) return

      return addFileApiCall({
        apiKey,
        itemId,
        operationId,
        itemOperationId,
        fileUploadSignature,
        brokenItemId,
        recordType: props.recordType,
        attachedFile: attachedFile.value
      })
    }

    const hasChanges = () => shouldDeleteExistingFile() === true || isFileAttached.value === true

    // Comuted
    const isFileAttached = computed(() => isFile(attachedFile.value))

    const isVideoFile = computed(() => props.recordType === recordTypes.brokenItemVideo)
    const maxFileSize = computed(() => isVideoFile.value ? MAX_VIDEO_FILE_SIZE : MAX_FILE_SIZE)
    const showFileAsTextInput = computed(() => hadFile.value && clearedExistingFile.value !== true)
    const placeholderValue = computed(() => {
      if (props.placeholder !== null && props.placeholder.length > 0) return props.placeholder
      return i18n.t('file_attach')
    })

    const showSizeLimitError = computed(() => {
      if (isFileAttached.value !== true) return false
      return attachedFile.value.size > maxFileSize.value
    })

    return {
      attachedFile,
      isFileAttached,
      showFileAsTextInput,
      sizeValidationRules,
      placeholderValue,
      showSizeLimitError,
      isVideoFile,
      hasChanges,
      saveFile,
      setFile,
      attachedFileName,
      reset,
      resetExistingFile,
      openPreSetFile,
      removeFile
    }
  }
}
</script>
<style lang="scss">
.file-chip {
  padding-right: 36px;

  .file-input {
    margin-top: 0;
    padding-top: 0;
  }

  &.file-attached {
    padding-right: 16px;

    .file-input {
      .v-input__icon--prepend {
        button.v-icon {
          color: #4CAF50;
        }
      }
    }
  }
}
</style>
