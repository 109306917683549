<template>
  <v-dialog
    v-model="dialog" max-width="350"
    persistent
    content-class="rounded-xl"
  >
    <v-card
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
      class="bg-color17 overflow-y-hidden popup-card"
    >
      <v-card-title class="white px-1">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="2" class="texts-start">
            <v-btn
              icon
              color="black"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center color5 t-600-18">
            {{ dialogTitle }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="py-4">
        <v-row align="start" justify="space-around" no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="districtName"
              background-color="white"
              class="required"
              outlined
              counter="80"
              :label="$t('create_district_dialog.placeholder')"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :loading="isLoading"
              :disabled="isSaveDisabled"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import useAuth from '@/composables/useAuth.js'

import { isString } from '@/helpers/Utils.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { createCompanyDistrict, updateCompanyDistrict } from '@/models/CompanyDistrict.js'
import { isRtl, i18n } from '@/i18n'
import { computed, ref } from 'vue'

const dialogModes = {
  create: 1,
  edit: 2
}
export default {
  setup () {
    const dialog = ref(false)
    const isLoading = ref(false)
    const districtName = ref(null)
    const dialogMode = ref(false)
    const districtIdForUpdate = ref(null)
    let resolveFunc = null

    // Methods

    const openDialog = () => {
      dialog.value = true
      dialogMode.value = dialogModes.create
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const openForUpdate = (district) => {
      dialog.value = true
      dialogMode.value = dialogModes.edit
      districtIdForUpdate.value = district.id
      districtName.value = district.name
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const close = (isSaved) => {
      dialog.value = false
      isLoading.value = false
      districtName.value = null
      districtIdForUpdate.value = null
      dialogMode.value = null
      resolveFunc(isSaved === true)
    }

    const createOrUpdate = () => {
      const { apiKey } = useAuth()
      if (dialogMode.value === dialogModes.create) {
        return createCompanyDistrict({ apiKey: apiKey.value, districtName: districtName.value })
      }
      return updateCompanyDistrict({ apiKey: apiKey.value, districtId: districtIdForUpdate.value, districtName: districtName.value })
    }

    const save = () => {
      isLoading.value = true
      createOrUpdate()
        .catch(errHandler)
        .finally(() => {
          isLoading.value = false
          close(true)
        })
    }

    // Computed
    const dialogTitle = computed(() => dialogMode.value === dialogModes.create
      ? i18n.t('create_district_dialog.title')
      : i18n.t('create_district_dialog.update_title'))
    const isSaveDisabled = computed(() => {
      if (!isString(districtName.value)) return true

      const length = districtName.value.trim().length
      const isValidName = length > 0 && length <= 80
      return !isValidName
    })
    return {
      dialog,
      isLoading,
      dialogTitle,
      districtName,
      isSaveDisabled,
      isRtl,
      openForUpdate,
      openDialog,
      close,
      save
    }
  }
}
</script>
