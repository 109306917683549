<template>
  <v-dialog
    v-model="dialog" width="510" persistent
    scrollable
    content-class="rounded-xl"
  >
    <v-card
      class="overflow-y-hidden popup-card bg-color17"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="white px-1">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="2" class="texts-start">
            <v-btn
              icon
              color="black"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center color5 t-600-18">
            {{ $t('account_edit_dialog.title') }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-2">
        <v-row
          v-if="isAccountLoading" align="center" justify="center"
          class="py-4"
        >
          <v-progress-circular indeterminate color="primary" />
        </v-row>
        <v-form v-else ref="accountEditForm">
          <v-container>
            <v-row align="center" justify="center" no-gutters>
              <v-col v-if="showAccountSelect" cols="11">
                <page-account-select
                  :reload-on-change="false"
                  @change="onAccountChange"
                />
              </v-col>
              <v-col cols="11">
                <v-text-field
                  v-model="account.name"
                  outlined
                  background-color="white"
                  class="required"
                  counter="120"
                  :label="$t('account_name')"
                />
              </v-col>
              <v-col cols="11">
                <district-select
                  v-model="account.districtId"
                  :api-key="apiKey"
                  :allow-change="isUserCompanyAdmin"
                  :disabled="!isUserCompanyAdmin"
                  @district-deleted="onDistrictDelete"
                />
              </v-col>
              <v-col cols="11" class="mt-5">
                <single-image-uploader ref="accountImageRef" width="80px" />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :loading="isLoading"
              :disabled="isSaveDisabled"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import PageAccountSelect from '@/components/shared/PageAccountSelect.vue'
import DistrictSelect from '@/components/company_stores/DistrictSelect.vue'
import SingleImageUploader from '@/components/shared/SingleImageUploader.vue'

import { loadAllAccounts } from '@/components/company_stores/AccountSelectionStore.js'

import {
  show as AccountClientShow,
  update as AccountClientUpdate,
  addStoreImage as AccountClientAddStoreImage,
  removeStoreImage as AccountClientRemoveStoreImage
} from '@/api_client/AccountClient.js'

import useAccount from '@/composables/useAccount.js'
import useAuth from '@/composables/useAuth.js'

import { isString } from '@/helpers/Utils.js'

import { uploadImage as uploadImageToRemoteServer } from '@/general_client/CloudinaryImageUploader.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { isRtl } from '@/i18n'

import { ref, computed, nextTick } from 'vue'

function emptyAccount () {
  return { name: null, districtName: null, districtId: null, customStoreImage: null }
}

export default {
  components: {
    'page-account-select': PageAccountSelect,
    'district-select': DistrictSelect,
    'single-image-uploader': SingleImageUploader
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    showAccountSelect: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { isUserCompanyAdmin } = useAuth()
    const dialog = ref(false)
    const isLoading = ref(false)
    const isAccountLoading = ref(false)
    const account = ref(emptyAccount())
    const accountName = ref(null)
    const distictName = ref(null)
    const accountEditForm = ref(null)
    const accountImageRef = ref(null)

    let oldDistrictId = null
    let oldName = null
    let accountApiKey = null
    let resolveFunc = null

    const isAccountNameValid = () => {
      const accountName = account.value.name
      if (!isString(accountName)) return false

      const length = accountName.trim().length
      return length > 0 && length <= 120
    }

    const updaloadImage = async (imageUploadData) => {
      await uploadImageToRemoteServer(accountImageRef.value.getImageFile(), imageUploadData)
      return AccountClientAddStoreImage(accountApiKey, imageUploadData.public_id)
    }

    const removeStoreImage = () => AccountClientRemoveStoreImage(accountApiKey)

    const accountUpdateParams = () => {
      const requestParams = {}
      if (oldName !== account.value.name) {
        requestParams.name = account.value.name
      }
      if (oldDistrictId !== account.value.districtId && isUserCompanyAdmin.value) {
        requestParams.company_district_id = account.value.districtId
        if (!Number.isInteger(requestParams.company_district_id)) {
          requestParams.company_district_id = null
        }
      }
      if (accountImageRef.value.shouldAddImage()) {
        requestParams.with_image = true
      }
      return requestParams
    }

    const saveAccountChanges = async () => {
      const updateParams = accountUpdateParams()
      const closeResult = { isUpdupdated: false }
      const shouldRemoveImageFromAccount = accountImageRef.value.shouldRemoveImage()
      const updateParamsEmpty = Object.keys(updateParams).length === 0
      if (updateParamsEmpty === true && shouldRemoveImageFromAccount !== true) {
        return closeResult
      }

      isLoading.value = true
      closeResult.isUpdupdated = true
      if (!updateParamsEmpty) {
        const districtUpdated = 'company_district_id' in updateParams
        closeResult.districtUpdated = districtUpdated
        const response = await AccountClientUpdate(accountApiKey, updateParams)
        const responseData = response.data.data
        if (accountImageRef.value.shouldAddImage()) {
          const imageUploadData = responseData.image_upload_data
          await updaloadImage(imageUploadData)
        }
      } else if (shouldRemoveImageFromAccount) {
        await removeStoreImage()
      }
      return closeResult
    }

    const setAccount = (accountVal) => {
      accountApiKey = accountVal.api_key
      account.value.name = accountVal.name
      account.value.districtName = accountVal.district_name
      account.value.districtId = accountVal.company_district_id
      account.value.customStoreImage = accountVal.custom_store_image
      oldName = accountVal.name
      oldDistrictId = accountVal.company_district_id
      if (isString(account.value.customStoreImage) && account.value.customStoreImage.length > 0) {
        nextTick(() => accountImageRef.value.setImageUrl(account.value.customStoreImage))
      }
    }

    // Methods
    const onAccountChange = (selectedAccount) => {
      setAccount(JSON.parse(JSON.stringify(selectedAccount)))
    }
    const onDistrictDelete = () => loadAllAccounts(props.apiKey, true)

    const save = () => {
      saveAccountChanges()
        .then((closeResult) => close(closeResult))
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    const openDialog = (accountVal) => {
      dialog.value = true
      setAccount(accountVal)
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const openDialogWithAccountLoading = (apiKey) => {
      isAccountLoading.value = true
      AccountClientShow(apiKey)
        .then((response) => {
          const result = openDialog(response.data.data)
          isAccountLoading.value = false
          return result
        })
        .catch((err) => {
          isAccountLoading.value = false
          errHandler(err)
        })
    }

    const close = ({ isUpdupdated = false, districtUpdated = false } = {}) => {
      dialog.value = false
      isLoading.value = false
      account.value = emptyAccount()
      oldName = null
      oldDistrictId = null
      accountApiKey = null
      accountEditForm.value.reset()
      accountImageRef.value.reset()

      const { loadAllAccounts: loadAllAccountsForSelect } = useAccount()
      loadAllAccountsForSelect(true)

      resolveFunc({ isUpdupdated, districtUpdated })
    }

    // Computed
    const isSaveDisabled = computed(() => {
      if (isLoading.value === true) return true
      if (!isAccountNameValid()) return true
      return false
    })

    return {
      isUserCompanyAdmin,
      dialog,
      isRtl,
      isLoading,
      isAccountLoading,
      account,
      accountName,
      distictName,
      isSaveDisabled,
      accountEditForm,
      accountImageRef,
      onAccountChange,
      onDistrictDelete,
      openDialogWithAccountLoading,
      openDialog,
      close,
      save
    }
  }
}
</script>
