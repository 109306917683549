<template>
  <v-dialog
    v-model="dialog" max-width="550" persistent
    content-class="rounded-xl"
  >
    <v-card class="chat-dialog" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="text-center pb-5 px-0">
        <v-row align="start" justify="start">
          <v-col cols="3" class="texts-start">
            <v-btn
              icon :disabled="dialogCloseDisabled"
              :loading="isDialogCloseLoading"
              @click="close"
            >
              <v-icon>
                close
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-center color5 t-600-18">
            {{ $t('chat_dialog.title') }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-row
        v-if="isLoading" align="center" justify="center"
        class="py-10"
      >
        <v-progress-circular indeterminate color="primary" />
      </v-row>
      <v-card-text v-else class="pa-0">
        <v-row align="start" justify="start" no-gutters>
          <v-col cols="12" class="bg-color17 pa-4 row no-gutters align-center">
            <v-col cols="7">
              <span v-if="showDialogTitle" class="t-700-16">
                {{ dialogTitle }}
              </span>
            </v-col>
            <v-col cols="5">
              <translation-select v-model="targetLanguage" @change="onLnaguageChange" />
            </v-col>
          </v-col>
          <v-responsive ref="chatBox" class="overflow-y-auto fill-height px-6 chat-box" height="400">
            <chat-messages-list :message-list="chatMessages" @delete-message="deleteMessage" />
          </v-responsive>
          <v-col cols="12" class="pa-6 bg-color17">
            <v-row no-gutters align="center">
              <v-col class="flex-grow-1 flex-shrink-0">
                <v-textarea
                  v-model="currentMessage"
                  class="mt-0 pt-0 rounded-pill chat-textarea"
                  counter="350"
                  solo
                  hide-details
                  flat
                  :label="$t('chat_dialog.message_placeholder')"
                  no-resize
                  :disabled="messageIsSending"
                  rows="1"
                />
              </v-col>
              <v-col class="flex-grow-0 flex-shrink-1 pps-2">
                <v-btn
                  fab small elevation="0"
                  class="white--text chat-dialog-send-btn" color="#0040FF"
                  :loading="messageIsSending"
                  :disabled="sendMessageDisabled"
                  @click="sendMessage"
                >
                  <v-icon>send</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import TranslationSelect from '@/components/shared/TranslationSelect.vue'
import ChatMessagesList from '@/components/chat_dialog/ChatMessagesList.vue'

import {
  create as RecordMessageClientCreate,
  index as RecordMessageClientIndex,
  destroy as RecordMessageClientDestroy
} from '@/api_client/RecordMessage.js'

import { create as RecordMessageTrackClientCreate } from '@/api_client/RecordMessageTrack.js'

import useAuth from '@/composables/useAuth.js'

import { newRecordMessageModel } from '@/store/modules/RecordMessage.js'
import { isString, isObject } from '@/helpers/Utils.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { isRtl } from '@/i18n'

import { ref, nextTick, computed } from 'vue'
const RECORD_TYPES_THAT_NEED_RECORD_TRACK = new Set(['OperationSchedule', 'BrokenItem'])

const validRecordTypes = ['BrokenItem', 'OperationSchedule']

export default {
  components: {
    'chat-messages-list': ChatMessagesList,
    'translation-select': TranslationSelect
  },
  props: {
    recordType: {
      type: String,
      required: true,
      validator: function (value) {
        return validRecordTypes.indexOf(value) !== -1
      }
    }
  },
  setup (props, { emit }) {
    const dialog = ref(false)
    const isLoading = ref(false)
    const currentMessage = ref(null)
    const messageIsSending = ref(false)
    const recordId = ref(null)
    const chatMessages = ref([])
    const isDialogCloseLoading = ref(false)
    const chatBox = ref(null)
    const dialogTitle = ref('')
    const targetLanguage = ref(null)
    const { apiKey: ApiKeyRef } = useAuth()

    let apiKey = null
    let messageIdToScroll = null

    const isMessageExist = (messageId) => chatMessages.value.some((message) => message.id === messageId)
    const updateMessageCount = () => emit('update-message-count', { recordId: recordId.value, messageCount: chatMessages.value.length })

    const scrollToChatBottom = () => {
      const element = chatBox.value.$el
      element.scrollTop = element.scrollHeight
    }

    const scrollToChatMessage = (messageId) => {
      const element = chatBox.value.$el
      if (isObject(element) !== true) return

      const message = element.querySelector(`#message-${messageId}`)

      if (message === null) return scrollToChatBottom()

      message.scrollIntoView(true)
    }

    const setScrollPosition = () => {
      if (Number.isInteger(messageIdToScroll) && isMessageExist(messageIdToScroll)) {
        scrollToChatMessage(messageIdToScroll)
      } else {
        scrollToChatBottom()
      }
    }

    const loadStartupData = () => {
      if (!validRecord.value) return
      isLoading.value = true
      const { loadCurrentUser } = useAuth()
      const promises = [
        loadCurrentUser(),
        RecordMessageClientIndex(apiKey, props.recordType, recordId.value, { target_language: targetLanguage.value })
      ]
      Promise.all(promises)
        .then((responses) => (chatMessages.value = responses[1].data.data.map((message) => newRecordMessageModel(message))))
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
        .then(() => setScrollPosition())
    }

    const removeMessage = (messageId) => {
      const messageIndex = chatMessages.value.findIndex((message) => message.id === messageId)
      if (messageIndex === -1) return

      chatMessages.value.splice(messageIndex, 1)
      updateMessageCount()
    }

    const closeDialog = () => {
      dialog.value = false
      isLoading.value = false
      messageIsSending.value = false
      chatMessages.value = []
      dialogTitle.value = ''
      messageIdToScroll = null
      apiKey = null
    }

    const updateMessageReadAll = () => emit('mark-read-all', recordId.value)

    const closeWithRecordTrack = () => {
      isDialogCloseLoading.value = true
      RecordMessageTrackClientCreate({ apiKey, recordType: props.recordType, recordId: recordId.value })
        .catch(errHandler)
        .then(() => updateMessageReadAll())
        .finally(() => {
          closeDialog()
          isDialogCloseLoading.value = false
        })
    }

    const appendMessageToList = (message) => {
      currentMessage.value = null
      chatMessages.value.push(newRecordMessageModel(message))
      updateMessageCount()
      nextTick(scrollToChatBottom)
    }
    // Methods

    const openDialog = ({ recordId: recordIdVal, apiKey: apiKeyVal, messageIdToScroll: messageIdToScrollVal, dialogTitle: dialogTitleVal }) => {
      dialogTitle.value = dialogTitleVal
      recordId.value = recordIdVal

      apiKey = isString(apiKeyVal) && apiKeyVal.length > 0 ? apiKeyVal : ApiKeyRef.value
      loadStartupData()
      dialog.value = true
      messageIdToScroll = messageIdToScrollVal
      currentMessage.value = null
    }

    const sendMessage = () => {
      messageIsSending.value = true
      const requestParams = { record_message: { content: currentMessage.value } }
      RecordMessageClientCreate(apiKey, props.recordType, recordId.value, requestParams)
        .then((response) => appendMessageToList(response.data.data))
        .catch(errHandler)
        .finally(() => (messageIsSending.value = false))
    }

    const deleteMessage = (message) => {
      message.deleteInProgress = true
      const messageId = message.id
      RecordMessageClientDestroy(apiKey, props.recordType, recordId.value, messageId)
        .then(() => removeMessage(messageId))
        .catch(errHandler)
        .finally(() => (message.deleteInProgress = true))
    }

    const close = () => {
      if (RECORD_TYPES_THAT_NEED_RECORD_TRACK.has(props.recordType)) {
        closeWithRecordTrack()
      } else {
        closeDialog()
      }
    }

    const onLnaguageChange = (newTargetLanguage) => {
      if (newTargetLanguage === targetLanguage.value) return
      targetLanguage.value = newTargetLanguage
      loadStartupData()
    }

    // Computed
    const validRecord = computed(() => Number.isInteger(recordId.value))
    const dialogCloseDisabled = computed(() => isDialogCloseLoading.value === true || messageIsSending.value === true)
    const showDialogTitle = computed(() => isString(dialogTitle.value) && dialogTitle.value.trim().length > 0)
    const sendMessageDisabled = computed(() => {
      if (!validRecord.value) return
      if (!isString(currentMessage.value)) return true
      return currentMessage.value.trim().length === 0
    })

    return {
      dialog,
      isLoading,
      currentMessage,
      messageIsSending,
      chatMessages,
      isRtl,
      isDialogCloseLoading,
      chatBox,
      showDialogTitle,
      targetLanguage,
      dialogTitle,
      sendMessageDisabled,
      dialogCloseDisabled,
      openDialog,
      sendMessage,
      close,
      deleteMessage,
      onLnaguageChange
    }
  }
}
</script>
<style lang="scss">
.chat-dialog {
  .chat-box {
    position: relative;
    scroll-behavior: smooth;
    background-image: url(@/assets/chat_list_bg.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    .date-row {
      position: sticky;
      background-color: transparent;
      top: 0;
      z-index: 4;
      width: 120px;
      margin-bottom: 15px;
      margin-top: 15px;
      justify-content: center;
      color: #212121;
    }
  }
}

.rtl {
    .chat-dialog-send-btn {
      transform: rotate(180deg) !important;
    }
    .chat-textarea {
      .v-label {
        padding-right: 15px;
      }
    }
}
</style>
