<template>
  <div>
    <task-frequency-weekly-dialog
      ref="weeklyDialog"
      :job-working-days="jobActiveDays"
      :single-weekly-working-day="singleWeeklyWorkingDay"
    />
    <task-frequency-yearly-dialog
      ref="yearlyDialog"
      :single-date="singleEveryYearDate"
    />
    <task-frequency-exact-date-dialog ref="exactDateDialog" />
    <v-select
      :value="selectedFrequency"
      :items="selectOptions"
      outlined
      background-color="white"
      hide-details
      class="required"
      :disabled="disabled"
      :menu-props="{ closeOnClick: false, closeOnContentClick: false }"
      :label="$t('op_schedule_frequency_dialog.placeholder')"
    >
      <template #selection>
        <div class="text-center">
          <div>{{ selectedFrequencyText }}</div>
        </div>
      </template>
      <template #item="{ item }">
        <v-row
          :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
          align="center" justify="space-between" class="color5 t-400-16 px-2"
          @click="onChangeFrequency(item.value)"
        >
          <span>{{ item.text }}</span>
          <v-checkbox v-if="item.showCheckBox" :input-value="isDailyFrequencySelected" />
          <v-icon v-else class="ppe-2 rtl-rotate">
            chevron_right
          </v-icon>
        </v-row>
      </template>
    </v-select>
  </div>
</template>
<script>
import TaskFrequencyWeeklyDialog from '@/components/new_task_dialog/task_frequency/TaskFrequencyWeeklyDialog.vue'
import TaskFrequencyYearlyDialog from '@/components/new_task_dialog/task_frequency/TaskFrequencyYearlyDialog.vue'
import TaskFrequencyExactDateDialog from '@/components/new_task_dialog/task_frequency/TaskFrequencyExactDateDialog.vue'
import { FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import useAccount from '@/composables/useAccount.js'

import { getClientDateFormatted, ISO8601StringToDate, isValidISO8601Date } from '@/helpers/DateTime.js'

import { isObject, isString } from '@/helpers/Utils.js'

import { i18n, isRtl } from '@/i18n.js'
import { ref, computed } from 'vue'
export default {
  components: {
    'task-frequency-weekly-dialog': TaskFrequencyWeeklyDialog,
    'task-frequency-yearly-dialog': TaskFrequencyYearlyDialog,
    'task-frequency-exact-date-dialog': TaskFrequencyExactDateDialog
  },
  props: {
    frequencyModel: {
      type: Object,
      required: false,
      default: () => ({ frequency: null, frequencyType: null })
    },
    frequencyExectDate: {
      type: String,
      required: false,
      default: null
    },
    frequencyEveryYear: {
      type: Array,
      required: false,
      default: () => []
    },
    singleEveryYearDate: {
      type: Boolean,
      required: false,
      default: false
    },
    singleWeeklyWorkingDay: {
      type: Boolean,
      required: false,
      default: false
    },
    endDate: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    jobActiveDays: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const weeklyDialog = ref(null)
    const yearlyDialog = ref(null)
    const exactDateDialog = ref(null)
    const { accountCountryCode } = useAccount()

    const selectOptions = [
      { text: i18n.t('operation_frequency.daily'), value: FrequencyTypes.EveryDay, showCheckBox: true },
      { text: i18n.t('operation_frequency.weekly'), value: FrequencyTypes.EveryXWeek },
      { text: i18n.t('operation_frequency.yearly'), value: FrequencyTypes.EveryYear },
      { text: i18n.t('operation_frequency.exect_date_frequency'), value: FrequencyTypes.ExectDate }
    ]

    const updatefrequencyModel = ({ frequency = null, frequencyType = null } = {}) => emit('update:frequency-model', { frequency, frequencyType })

    const saveWeeklyFrequency = ({ isSaved, isReset = false, selectedWeek = null, selectedWorkingDays = [] }) => {
      if (!isSaved) return
      if (isReset) {
        updatefrequencyModel()
        emit('update-working-days', [])
        return
      }

      updatefrequencyModel({ frequency: selectedWeek, frequencyType: FrequencyTypes.EveryXWeek })
      emit('update-working-days', selectedWorkingDays)
    }

    const saveYearlyFrequency = ({ isSaved, isReset = false, selectedDates = [] }) => {
      if (!isSaved) return
      if (isReset) {
        updatefrequencyModel()
        emit('update:frequency-every-year', [])
        return
      }
      updatefrequencyModel({ frequencyType: FrequencyTypes.EveryYear })
      emit('update:frequency-every-year', selectedDates)
    }

    const saveExactDateFrequency = ({ isSaved, isReset = false, date, endDate }) => {
      if (!isSaved) return
      if (isReset) {
        updatefrequencyModel()
        emit('update:frequency-exect-date', null)
        emit('update:end-date', null)
        return
      }
      updatefrequencyModel({ frequencyType: FrequencyTypes.ExectDate })
      emit('update:frequency-exect-date', date)
      emit('update:end-date', endDate)
    }

    // Methods
    const onChangeFrequency = (newFrequency) => {
      if (newFrequency === FrequencyTypes.EveryDay) {
        return emit('update:frequencyModel', { frequency: null, frequencyType: FrequencyTypes.EveryDay })
      }
      if (newFrequency === FrequencyTypes.EveryXWeek) {
        const activeDays = props.jobActiveDays.filter(day => day.isActive).map(day => day.name)
        weeklyDialog.value.openDialog({ selectedWeek: props.frequencyModel.frequency, selectedWorkingDays: activeDays })
          .then(saveWeeklyFrequency)
      } else if (newFrequency === FrequencyTypes.EveryYear) {
        yearlyDialog.value.openDialog(props.frequencyEveryYear)
          .then(saveYearlyFrequency)
      } else if (newFrequency === FrequencyTypes.ExectDate) {
        exactDateDialog.value.openDialog({ date: props.frequencyExectDate, endDate: props.endDate })
          .then(saveExactDateFrequency)
      }
    }
    const selectedFrequency = computed(() =>
      isObject(props.frequencyModel) && isString(props.frequencyModel.frequencyType)
        ? props.frequencyModel.frequencyType
        : null)
    const isDailyFrequencySelected = computed(() => selectedFrequency.value === FrequencyTypes.EveryDay)

    const selectedFrequencyText = computed(() => {
      if (isDailyFrequencySelected.value) return i18n.t('operation_frequency.every_day')

      if (selectedFrequency.value === FrequencyTypes.EveryXWeek) {
        const activeDaysNames = props.jobActiveDays.filter((day) => day.isActive)
          .map((day) => day.translatedName).join(', ')
        if (props.frequencyModel.frequency === 1) {
          return i18n.t('operation_frequency.every_week_with_day', { dayName: activeDaysNames })
        }
        return i18n.t('operation_frequency.week_x_frequency_with_day', { frequency: props.frequencyModel.frequency, dayName: activeDaysNames })
      }
      if (selectedFrequency.value === FrequencyTypes.EveryYear) {
        const dateCount = props.frequencyEveryYear.length
        if (dateCount === 1) {
          const fromDate = ISO8601StringToDate(props.frequencyEveryYear[0])
          const userFormatDate = getClientDateFormatted({ fromDate, countryCode: accountCountryCode.value })
          return i18n.t('operation_frequency.yearly_single_date', { date: userFormatDate })
        }
        return i18n.t('operation_frequency.yearly_x_dates', { dateCount })
      }
      if (selectedFrequency.value === FrequencyTypes.ExectDate) {
        const result = i18n.t('operation_frequency.exect_date_frequency')
        if (!isValidISO8601Date(props.frequencyExectDate)) return result
        const fromDate = ISO8601StringToDate(props.frequencyExectDate)
        const exactDate = getClientDateFormatted({ fromDate, countryCode: accountCountryCode.value })
        const endDate = isValidISO8601Date(props.endDate)
          ? getClientDateFormatted({ fromDate: ISO8601StringToDate(props.endDate), countryCode: accountCountryCode.value })
          : ''
        const dateRange = endDate ? `${exactDate} - ${endDate}` : exactDate
        return `${i18n.t('operation_frequency.exect_date_frequency')} ${dateRange}`
      }
      return ''
    })
    return {
      isRtl,
      selectOptions,
      weeklyDialog,
      yearlyDialog,
      exactDateDialog,
      selectedFrequency,
      isDailyFrequencySelected,
      onChangeFrequency,
      selectedFrequencyText
    }
  }
}
</script>
<style lang="scss">
.frequency-dialog {
  overflow-y: hidden;
  .scrollable {
    overflow: auto;
    height: 100%;
    max-height: 80vh;
  }
  .frequency-date-picker {
    .v-picker__body {
      background-color: #F4F6F8 !important;
      .v-date-picker-header > button:first-of-type {
        rotate: 180deg;
      }
      .v-date-picker-header > button:last-of-type {
        rotate: 180deg;
      }
    }
    .v-picker__actions {
      background-color: #E8E8E8 !important;
    }
  }
}
</style>
