<template>
  <span>
    <confirm-delete ref="deleteDialog" />
    <create-company-district-dialog
      ref="createDistrictDialog"
    />
    <v-select
      :value="value"
      :items="companyDistricts"
      :loading="isDistrictsLoading"
      :disabled="disabled"
      outlined
      background-color="white"
      item-text="name"
      item-value="id"
      clearable
      single-line
      :no-data-text="$t('no_result')"
      :label="$t('area')"
      @change="onChange"
      @input="onInput"
      @click:clear="onClear"
    >
      <template
        v-if="allowChange"
        #prepend-item
      >
        <v-list-item :class="{ rtl: isRtl }" @click="addNewDistrict">
          <v-list-item-content>
            <v-list-item-title class="color9 t-500-16">
              <v-icon
                color="#0E4AFD"
                class="mx-2"
              >add_circle_outline
              </v-icon>
              <span class="text-decoration-underline">{{ $t('district_select.add_new') }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2" />
      </template>
      <template #item="{ item }">
        <v-row :class="{ rtl: isRtl }" align="center" justify="space-between">
          <span class="font-weight-bold px-2"> {{ item.name }}</span>
          <v-menu
            v-if="allowChange"
            offset-y
          >
            <template #activator="{ on }">
              <v-icon color="black" v-on="on">more_vert</v-icon>
            </template>
            <v-list>
              <v-list-item ripple @click="editItemCategory(item)">
                <v-list-item-icon>
                  <v-icon small>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
              </v-list-item>
              <v-list-item ripple @click="onDelete(item)">
                <v-list-item-icon>
                  <v-icon small>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </template>
    </v-select>
  </span>
</template>
<script>
import CreateCompanyDistrictDialog from '@/components/shared/CreateCompanyDistrictDialog.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'

import { getCompanyDistricts, loadCompanyDistricts, getIsLoading, destroyCompanyDistrict } from '@/models/CompanyDistrict.js'
import { isRtl, i18n } from '@/i18n.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { ref } from 'vue'
export default {
  components: {
    'confirm-delete': ConfirmDelete,
    'create-company-district-dialog': CreateCompanyDistrictDialog
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: false,
      default: null
    },
    allowChange: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, { emit }) {
    const deleteDialog = ref(null)
    const createDistrictDialog = ref(null)

    const onInput = (value) => emit('input', value)
    const onChange = (value) => emit('change', value)
    const onClear = () => emit('click:clear')

    // Methods
    const editItemCategory = (district) => createDistrictDialog.value.openForUpdate(district)
      .then((isSaved) => {
        if (!isSaved) return
        loadCompanyDistricts({ apiKey: props.apiKey })
          .catch(errHandler)
      })
      .catch(errHandler)

    const onDelete = (area) => {
      const title = i18n.t('district_select.delete_title')
      const content = i18n.t('district_select.delete_content')

      deleteDialog.value.open({ title, content })
        .then((shouldDelete) => {
          if (!shouldDelete) return
          destroyCompanyDistrict({ apiKey: props.apiKey, districtId: area.id })
            .then(() => emit('district-deleted'))
            .catch(errHandler)
            .finally(() => deleteDialog.value.close())
        })
    }
    const addNewDistrict = () => createDistrictDialog.value.openDialog()
      .then((isSaved) => {
        if (!isSaved) return
        loadCompanyDistricts({ apiKey: props.apiKey })
          .catch(errHandler)
      })
      .catch(errHandler)

    // Computed

    loadCompanyDistricts({ apiKey: props.apiKey })
      .catch(errHandler)

    return {
      deleteDialog,
      companyDistricts: getCompanyDistricts,
      isDistrictsLoading: getIsLoading,
      createDistrictDialog,
      isRtl,
      onDelete,
      onInput,
      onChange,
      onClear,
      addNewDistrict,
      editItemCategory
    }
  }
}
</script>
