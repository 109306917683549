<template>
  <v-row
    align="center" justify="space-between" class="task-subtasks pointer"
    no-gutters
    @click="onClick"
  >
    <v-col
      class="flex-grow-1 flex-shrink-0 pps-2 t-400-16 required"
    >
      <label>{{ $t('schedule_new_op_dialog.add_sub_task') }}</label>
      <div v-if="showCount" class="color1">
        {{ taskCountText }}
      </div>
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1 ppe-3">
      <v-icon class="rtl-rotate">
        chevron_right
      </v-icon>
    </v-col>
  </v-row>
</template>
<script>

import { i18n } from '@/i18n'

import { computed } from 'vue'
export default {
  props: {
    subtasksCount: {
      type: Number,
      required: false,
      default: 0
    }
  },
  setup (props, { emit }) {
    // Methods
    const onClick = () => emit('click')

    // Computed

    const showCount = computed(() => props.subtasksCount > 0)
    const taskCountText = computed(() => {
      if (!showCount.value) return ''
      return props.subtasksCount === 1
        ? i18n.t('one_task')
        : `${props.subtasksCount} ${i18n.t('tasks')}`
    })
    return {
      showCount,
      taskCountText,
      onClick
    }
  }
}
</script>
<style lang="scss">
  .task-subtasks {
    height: 59px;
    border: 1px solid #E8E8E8;
    border-radius: 2px;
    background-color: white;
    color: rgba(0, 0, 0, 0.7);
  }
</style>
