<template>
  <v-row
    align="start" justify="start" no-gutters
    class="drive-file"
  >
    <v-col class="flex-grow-1 flex-shrink-0">
      <drive-file-icon :file-name="file.name" />
      <span class="t-400-16 pps-1">
        {{ file.name }}
      </span>
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1">
      <v-menu close-on-content-click>
        <template #activator="{ on }">
          <v-icon color="black" v-on="on">
            more_vert
          </v-icon>
        </template>
        <v-list class="edit-operation">
          <v-list-item ripple @click="onInfo">
            <v-list-item-icon>
              <v-icon>info</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('info') }}</v-list-item-title>
          </v-list-item>
          <v-list-item ripple @click="onDownload">
            <v-list-item-icon>
              <v-icon>download</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('download') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="showDeleteOption" ripple @click="onDelete">
            <v-list-item-icon>
              <v-icon>delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col v-if="showRolesAndAccounts" cols="12">
      <div class="mt-2 file-roles">
        <drive-resource-roles :resource="file" />
      </div>
      <div class="mt-2">
        <drive-resource-accounts :resource="file" />
      </div>
    </v-col>
    <v-col cols="12" class="color2 t-500-13 mt-2">
      <span>
        {{ createdAtString }}
      </span>
      <span class="pps-1">
        {{ $t('drive.created_by', { firstName: file.creator?.first_name, lastName: file.creator?.last_name }) }}
      </span>
    </v-col>
  </v-row>
</template>
<script>
import DriveResourceRoles from '@/components/drive/shared/DriveResourceRoles.vue'
import DriveResourceAccounts from '@/components/drive/shared/DriveResourceAccounts.vue'
import DriveFileIcon from '@/components/drive/shared/DriveFileIcon.vue'

import { create as RecordMessageTrackClientCreate } from '@/api_client/RecordMessageTrack.js'

import useAuth from '@/composables/useAuth.js'
import { dateToLocalString } from '@/helpers/DateTime.js'

import { computed } from 'vue'

export default {
  components: {
    'drive-file-icon': DriveFileIcon,
    'drive-resource-roles': DriveResourceRoles,
    'drive-resource-accounts': DriveResourceAccounts
  },
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const { isUserCompanyAdmin, currentUser, apiKey } = useAuth()

    // Methods
    const onInfo = () => emit('info-file', props.file)
    const onDelete = () => emit('delete-file', props.file)
    const onDownload = async () => {
      Promise.allSettled([
        RecordMessageTrackClientCreate({ apiKey: apiKey.value, recordId: props.file.id, recordType: 'DriveFile' }),
        window.open(props.file.download_link, '_blank')
      ])
    }

    // Computed
    const createdAtString = computed(() => dateToLocalString(new Date(props.file.created_at)))
    const showRolesAndAccounts = computed(() => !Number.isInteger(props.file.drive_folder_id))
    const showDeleteOption = computed(() => {
      if (isUserCompanyAdmin.value) return true
      const currentUserId = currentUser.value?.id
      return Number.isInteger(currentUserId) && currentUserId === props.file.creator?.user_id
    })

    return {
      showDeleteOption,
      createdAtString,
      showRolesAndAccounts,
      onInfo,
      onDelete,
      onDownload
    }
  }
}
</script>
<style lang="scss">
.drive-file {
  background-color: white;
  height: 100%;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);

  .file-roles {
    margin-right: -4px;
    margin-left: -4px;
  }
}
</style>
