<template>
  <v-dialog
    v-model="dialog" width="540"
    content-class="rounded-xl"
    persistent
    scrollable
  >
    <v-card
      class="bg-color16 task-checklist-dialog"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="white px-1">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="3" class="texts-start">
            <v-btn
              icon
              color="black"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-center color5 t-600-18">
            {{ $t('item_op_list_dialog.title') }}
          </v-col>
          <v-col cols="3" class="texts-end ppe-1 t-400-17 color22">
            <span class="pointer" @click="onReset">
              {{ $t('reset') }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container class="pb-0 scrollable px-2 mt-8">
          <draggable
            v-model="itemOperationList"
            handle=".drag-handle"
            :disabled="itemsChangeOrderDisabled"
            animation="300"
            @change="onListOrderChanged"
          >
            <task-subtasks-list-single-item
              v-for="(itemOperationListItem, index) in itemOperationList"
              :key="`${itemOperationListItem.id}-${index}`"
              :show-change-order="allowChangeItemOrder"
              :check-list-item="itemOperationListItem"
              :item-type.sync="itemOperationListItem.item_type"
              :description.sync="itemOperationListItem.description"
              @change-item-type="onTypeChange"
              @update:description="onDescriptionChange"
            />
          </draggable>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :disabled="saveDisabled"
              @click="saveClick"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TaskSubtasksListSingleItem from '@/components/new_task_dialog/task_subtasks_list/TaskSubtasksListSingleItem.vue'
import vuedraggable from 'vuedraggable'

import { itemTypes } from '@/models/ItemOperationListItemTypes.js'
import { isString } from '@/helpers/Utils.js'

import { i18n, isRtl } from '@/i18n.js'

import { ref, computed } from 'vue'

export default {
  components: {
    'task-subtasks-list-single-item': TaskSubtasksListSingleItem,
    draggable: vuedraggable
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    allowChangeItemOrder: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    let resolveFunc = null
    let orderChanged = false

    const dialog = ref(false)
    const itemOperationList = ref([])

    const isNewUnSavedItem = (itemOperationList) => !Number.isInteger(itemOperationList.id)
    const itemOperationListValid = (itemOperationList) => (itemOperationList.description || '').trim().length > 0
    const onListOrderChanged = () => (orderChanged = true)

    // Methods
    const onTypeChange = (item) => {
      if (!isNewUnSavedItem(item) || item.item_type !== itemTypes.feedback) return
      if (isString(item.description) && item.description.trim().length > 0) return
      item.description = i18n.t('item_op_list_dialog.feedback_description')
    }
    const changeItemType = (item, newType) => {
      item.item_type = item.item_type === newType ? itemTypes.default : newType
    }

    const addEmptyItem = () => {
      const newItem = { description: '', item_type: itemTypes.default }
      itemOperationList.value.push(newItem)
    }

    const openDialog = (items = []) => {
      dialog.value = true
      itemOperationList.value = []
      if (Array.isArray(items) && items.length > 0) {
        const itemOperationListVal = itemOperationList.value
        items.forEach((item) => itemOperationListVal.push({ ...item }))
      }

      if (invalidItemsIndexes.value.length === 0) {
        addEmptyItem()
      }
      return new Promise((resolve) => {
        resolveFunc = resolve
      })
    }

    const close = (saveClicked) => {
      dialog.value = false
      const result = {
        isSaved: saveClicked === true,
        newList: JSON.parse(JSON.stringify(validItems.value)),
        orderChanged
      }
      return resolveFunc(result)
    }

    const deleteItem = (index) => itemOperationList.value.splice(index, 1)

    const saveClick = () => close(true)
    const onReset = () => {
      itemOperationList.value = []
      addEmptyItem()
    }

    const onDescriptionChange = () => {
      if (invalidItemsIndexes.value.length === 0) {
        addEmptyItem()
      } else if (invalidItemsIndexes.value.length > 1) {
        const invalidItemsIndexesDup = [...invalidItemsIndexes.value]
        invalidItemsIndexesDup.pop()
        invalidItemsIndexesDup.forEach((index) => {
          deleteItem(index)
        })
      }
    }

    // Computed
    const validItems = computed(() => itemOperationList.value.filter(itemOperationListValid))
    const invalidItemsIndexes = computed(() => itemOperationList.value.map((item, index) => (itemOperationListValid(item) ? -1 : index)).filter((index) => index !== -1))
    const itemsChangeOrderDisabled = computed(() => props.allowChangeItemOrder !== true)

    const saveDisabled = computed(() => {
      const allItemsAreTitles = validItems.value.every((item) => item.item_type === itemTypes.title || item.item_type === itemTypes.feedback)
      if (allItemsAreTitles === true && itemOperationList.value.length > 0) return true
      const invalidCount = invalidItemsIndexes.value.length
      if (invalidCount === 1 && itemOperationList.value.length === 1) return false
      const saveEnabled = invalidCount <= 1
      return !saveEnabled
    })

    return {
      dialog,
      itemOperationList,
      saveDisabled,
      itemsChangeOrderDisabled,
      itemTypes,
      isRtl,
      changeItemType,
      openDialog,
      close,
      onTypeChange,
      addEmptyItem,
      saveClick,
      onReset,
      onDescriptionChange,
      onListOrderChanged
    }
  }
}
</script>
<style lang="scss">
.task-checklist-dialog {
  overflow-y: hidden;
  .scrollable {
    overflow: auto;
    height: 100%;
    min-height: 200px;
    max-height: 60vh;
  }
}
</style>
