<template>
  <v-dialog
    v-model="dialog" width="510" persistent
    scrollable
    content-class="rounded-xl"
  >
    <v-card
      class="file-downloaded-by overflow-y-hidden popup-card bg-color17"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="white px-1">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="2" class="texts-start">
            <v-btn
              icon
              color="black"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center color5 t-600-18">
            {{ $t('drive.file_info') }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-2">
        <v-row align="center" justify="start" class="file-preview ma-4 pa-1">
          <v-col cols="12" class="pb-2">
            <drive-file-icon :file-name="file.name" />
            <span class="t-700-16">
              {{ file.name }}
            </span>
          </v-col>
          <v-col cols="12" class="pt-0 pb-2 color5 t-500-16">
            {{ createdAtString }}
          </v-col>
          <v-col cols="12" class="pt-0 pb-2 color5 t-500-16">
            {{ file.creator?.first_name }} {{ file.creator?.last_name }}
          </v-col>
        </v-row>
        <v-row align="center" justify="start">
          <v-col cols="12" class="color5 t-400-16 px-8">
            {{ $t('drive.downloaded_by') }}
          </v-col>
        </v-row>
        <v-row v-if="isLoading" align="center" justify="center">
          <v-col
            v-for="index in 3" :key="index" cols="12"
          >
            <v-skeleton-loader
              type="list-item-avatar"
            />
          </v-col>
        </v-row>
        <v-row v-else-if="isEmptyResults" align="center" justify="center">
          <v-col cols="12" class="color5 t-700-16 file-preview ma-4 pa-6 text-center">
            {{ $t('drive.no_downloads') }}
          </v-col>
        </v-row>
        <v-row
          v-else align="center" justify="start"
          class="file-preview ma-4 pa-1"
        >
          <v-col
            v-for="user in downloadedByUsers" :key="user.id" cols="12"
            class="pb-2"
          >
            <v-row align="center" justify="start">
              <v-col cols="12" class="texts-start">
                <user-avatar :user="user" size="28" />
                <span class="t-700-16 pps-1">
                  {{ user.first_name }} {{ user.last_name }}
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="showLoadMorePages" v-intersect="loadNextPage" />
          <v-col v-if="isLoadingNextPage" cols="12">
            <v-row align="center" justify="center">
              <v-col
                v-for="index in 3" :key="index" cols="12"
              >
                <v-skeleton-loader
                  type="list-item-avatar"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import UserAvatar from '@/components/shared/UserAvatar.vue'
import DriveFileIcon from '@/components/drive/shared/DriveFileIcon.vue'

import { dateToLocalString } from '@/helpers/DateTime.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import useAuth from '@/composables/useAuth.js'

import { downloadedByList } from '@/api_client/DriveFile.js'
import { isRtl } from '@/i18n'
import { ref, computed } from 'vue'

const DEFAULT_PER_PAGE = 100
const DEFAULT_PAGE = 1
const defaultPagingation = () => ({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE, total: 0 })
export default {
  components: {
    'user-avatar': UserAvatar,
    'drive-file-icon': DriveFileIcon
  },
  setup () {
    const dialog = ref(false)
    const file = ref({})
    const isLoading = ref(false)
    const createdAtString = ref('')
    const pagination = ref(defaultPagingation())
    const downloadedByUsers = ref([])
    const isLoadingNextPage = ref(false)

    const setPaginationFromResponse = (meta) => {
      const paginationVal = pagination.value
      paginationVal.total = meta.total
      paginationVal.page = meta.page
      paginationVal.perPage = meta.per_page
    }

    const loadDownloadedByUsers = () => {
      if (pagination.value.page === 1) {
        isLoading.value = true
      } else {
        isLoadingNextPage.value = true
      }
      const { apiKey } = useAuth()
      const requestParams = {
        apiKey: apiKey.value,
        fileId: file.value.id,
        page: pagination.value.page,
        perPage: pagination.value.perPage
      }

      downloadedByList(requestParams)
        .then(({ data: { data, meta } }) => {
          setPaginationFromResponse(meta)
          downloadedByUsers.value = downloadedByUsers.value.concat(data)
        })
        .catch(errHandler)
        .finally(() => {
          isLoading.value = false
          isLoadingNextPage.value = false
        })
    }
    // Methods

    const openDialog = (fileVal) => {
      file.value = fileVal
      createdAtString.value = dateToLocalString(new Date(file.value.created_at))
      dialog.value = true
      loadDownloadedByUsers()
    }

    const close = () => {
      dialog.value = false
      file.value = {}
      createdAtString.value = ''
      isLoading.value = false
      isLoadingNextPage.value = false
      downloadedByUsers.value = []
      pagination.value = defaultPagingation()
    }

    const isEmptyResults = computed(() => !isLoading.value && downloadedByUsers.value.length === 0)
    const showLoadMorePages = computed(() => {
      if (isLoading.value || isLoadingNextPage.value) return false
      return pagination.value.total > downloadedByUsers.value.length
    })

    const loadNextPage = () => {
      pagination.value.page++
      loadDownloadedByUsers()
    }

    return {
      dialog,
      file,
      createdAtString,
      isRtl,
      isLoading,
      isLoadingNextPage,
      showLoadMorePages,
      downloadedByUsers,
      isEmptyResults,
      close,
      openDialog,
      loadNextPage
    }
  }
}
</script>
<style lang="scss">
.file-downloaded-by{
  .file-preview{
    border-radius: 18px;
    background-color: white;
  }
}
</style>
