<template>
  <v-autocomplete
    :value="selectedAccount"
    :items="allAccounts"
    :loading="isLoading"
    class="page-account-select"
    item-text="name"
    item-value="id"
    outlined
    :menu-props="{ contentClass: isRtl ? 'rtl' : 'ltr' }"
    return-object
    @change="onAccountChange"
  >
    <template #item="{ item: account }">
      <v-row align="center" justify="start" no-gutters>
        <v-col class="flex-grow-0 flex-shrink-1">
          <v-avatar size="40">
            <v-img :src="account.custom_store_image || account.store_image" />
          </v-avatar>
        </v-col>
        <v-col class="flex-grow-1 flex-shrink-0 pps-2">
          <span class="t-700-16">
            {{ account.name }}
          </span>
          <div v-if="account.district_name" class="t-700-13">
            {{ account.district_name }}
          </div>
        </v-col>
      </v-row>
    </template>
    <template #selection="{ item: account }">
      <v-row
        align="center" justify="start" no-gutters
      >
        <v-col class="flex-grow-0 flex-shrink-1">
          <v-avatar size="40">
            <v-img :src="account.custom_store_image || account.store_image" />
          </v-avatar>
        </v-col>
        <v-col class="flex-grow-1 flex-shrink-0 pps-2 texts-start">
          <span class="t-700-16">
            {{ account.name }}
          </span>
          <div v-if="account.district_name" class="t-700-13">
            {{ account.district_name }}
          </div>
        </v-col>
      </v-row>
    </template>
  </v-autocomplete>
</template>
<script>
import useAccount from '@/composables/useAccount.js'
import useAuth from '@/composables/useAuth.js'
import { isObject } from '@/helpers/Utils'

import { isRtl } from '@/i18n'

import { ref } from 'vue'
export default {
  props: {
    reloadOnChange: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    const isLoading = ref(false)
    const selectedAccount = ref(null)
    const { allAccounts, loadAllAccounts, changeAccount } = useAccount()
    const { apiKey } = useAuth()
    loadAllAccounts().then(() => {
      selectedAccount.value = allAccounts.value.find((account) => account.api_key === apiKey.value)
    })
      .finally(() => (isLoading.value = false))

    // Methods

    const onAccountChange = async (account) => {
      if (!isObject(account)) return

      if (props.reloadOnChange) {
        await changeAccount(account)
        location.reload()
      } else {
        selectedAccount.value = account
        emit('change', account)
      }
    }
    return {
      selectedAccount,
      allAccounts,
      isLoading,
      isRtl,
      onAccountChange
    }
  }
}
</script>
<style lang="scss">
.page-account-select {
  .v-text-field__details {
    display: none;
  }
}
</style>
