import Client from '@/api_client/client.js'

const create = (apiKey, accountJobId, params) => {
  const OPERATION_SCHEDULE_CREATE_PATH = `/accounts/${apiKey}/account_jobs/${accountJobId}/operation_schedules`
  return Client.post(OPERATION_SCHEDULE_CREATE_PATH, { operation_schedule: params })
}

const createWithItem = (apiKey, accountJobId, params) => {
  const OPERATION_SCHEDULE_CREATE_PATH = `/accounts/${apiKey}/account_jobs/${accountJobId}/operation_schedules/create_with_item`
  return Client.post(OPERATION_SCHEDULE_CREATE_PATH, { operation_schedule: params })
}

const updateWithItem = (apiKey, accountJobId, opSchedId, params) => {
  const OPERATION_SCHEDULE_UPDATE_WITH_ITEM_PATH = `/accounts/${apiKey}/account_jobs/${accountJobId}/operation_schedules/${opSchedId}/update_with_item`
  return Client.put(OPERATION_SCHEDULE_UPDATE_WITH_ITEM_PATH, { operation_schedule: params })
}

const update = (apiKey, accountJobId, opSchedId, params) => {
  const OPERATION_SCHEDULE_UPDATE_PATH = `/accounts/${apiKey}/account_jobs/${accountJobId}/operation_schedules/${opSchedId}`
  return Client.put(OPERATION_SCHEDULE_UPDATE_PATH, { operation_schedule: params })
}

const destroy = (apiKey, accountJobId, opSchedId) => {
  const OPERATION_SCHEDULE_DELETE_PATH = `/accounts/${apiKey}/account_jobs/${accountJobId}/operation_schedules/${opSchedId}`
  return Client.delete(OPERATION_SCHEDULE_DELETE_PATH)
}

const copyOperation = ({ apiKey, accountJobId, destJobId, opSchedId, days = null }) => {
  const OPERATION_SCHEDULE_COPY_PATH = `/accounts/${apiKey}/account_jobs/${accountJobId}/operation_schedules/${opSchedId}/copy`
  return Client.post(OPERATION_SCHEDULE_COPY_PATH, { days, dest_job_id: destJobId })
}

const indexForOrderTasks = ({ apiKey, accountJobId, shiftPartName, page, perPage }) => {
  const ACCOUNT_JOB_TASK_INDEX_FOR_ORDER_UPDATE_PATH = `/accounts/${apiKey}/account_jobs/${accountJobId}/operation_schedules/index_for_order_update`
  return Client.get(ACCOUNT_JOB_TASK_INDEX_FOR_ORDER_UPDATE_PATH, { params: { account_job_id: accountJobId, shift_part_type: shiftPartName, page, per_page: perPage } })
}

const updateTaskOrder = ({ apiKey, accountJobId, shiftPartName, taskOrder }) => {
  const ACCOUNT_JOB_TASK_UPDATE_ORDER_PATH = `/accounts/${apiKey}/account_jobs/${accountJobId}/operation_schedules/update_task_order`
  return Client.put(ACCOUNT_JOB_TASK_UPDATE_ORDER_PATH, { account_job_id: accountJobId, shift_part_type: shiftPartName, task_order: taskOrder })
}

const countPerShiftPart = ({ apiKey, accountJobId }) => {
  const ACCOUNT_JOB_TASK_COUNT_PER_SHIFT_PART_PATH = `/accounts/${apiKey}/account_jobs/${accountJobId}/operation_schedules/count_per_shift_part`
  return Client.get(ACCOUNT_JOB_TASK_COUNT_PER_SHIFT_PART_PATH, { params: { account_job_id: accountJobId } })
}

export {
  create,
  createWithItem,
  updateWithItem,
  update,
  destroy,
  copyOperation,
  indexForOrderTasks,
  updateTaskOrder,
  countPerShiftPart
}
