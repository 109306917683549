<template>
  <tr class="texts-start">
    <td>
      <drive-file-icon :file-name="file.name" />
      <span class="pps-1 t-700-16">{{ file.name }}</span>
    </td>
    <td>
      <span class="t-500-16 color5">
        {{ createdAtString }}
      </span>
    </td>
    <td>
      <span class="t-500-16 color5">
        {{ file.creator?.first_name }} {{ file.creator?.last_name }}
      </span>
    </td>
    <td class="text-no-wrap texts-end">
      <v-btn
        v-if="showDeleteOption" ripple icon
        @click="onDelete"
      >
        <v-icon class="material-icons-outlined">
          delete
        </v-icon>
      </v-btn>
      <v-btn
        icon
        @click="onDownload"
      >
        <v-icon>download</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="onInfo"
      >
        <v-icon class="material-icons-outlined">
          info
        </v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script>
import DriveFileIcon from '@/components/drive/shared/DriveFileIcon.vue'
import { dateToLocalString } from '@/helpers/DateTime.js'

import { create as RecordMessageTrackClientCreate } from '@/api_client/RecordMessageTrack.js'
import useAuth from '@/composables/useAuth.js'

import { computed } from 'vue'
export default {
  components: {
    'drive-file-icon': DriveFileIcon
  },
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const { isUserCompanyAdmin, currentUser, apiKey } = useAuth()

    // Methods
    const onDelete = () => emit('delete-file', props.file)
    const onInfo = () => emit('info-file', props.file)
    const onDownload = async () => {
      Promise.allSettled([
        RecordMessageTrackClientCreate({ apiKey: apiKey.value, recordId: props.file.id, recordType: 'DriveFile' }),
        window.open(props.file.download_link, '_blank')
      ])
    }

    // Computed
    const createdAtString = computed(() => dateToLocalString(new Date(props.file.created_at)))

    const showDeleteOption = computed(() => {
      if (isUserCompanyAdmin.value) return true
      const currentUserId = currentUser.value?.id
      return Number.isInteger(currentUserId) && currentUserId === props.file.creator?.user_id
    })
    return {
      createdAtString,
      showDeleteOption,
      onDelete,
      onInfo,
      onDownload
    }
  }
}
</script>
